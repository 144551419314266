import { createReducer, createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    setError: ['message'],
    clearError: null,
})

export const ErrorTypes = Types
export default Creators

interface ErrorState {
    message: string | null
}

export const INITIAL_STATE = {
    message: null,
}

export const setError = (
    state: ErrorState,
    { message }: ErrorState,
): ErrorState => ({ ...state, message })
export const clearError = (state: ErrorState): ErrorState => ({
    ...state,
    message: null,
})

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_ERROR]: setError,
    [Types.CLEAR_ERROR]: clearError,
})
