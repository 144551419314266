import React, {useState} from 'react'
import {Provider} from 'react-redux'
import createStore from './Redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { Notification } from './Components/Notification'
import { MainRoutes } from './Utils/routes'
import themes from './Utils/themes'
import { HelmetProvider } from 'react-helmet-async'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager'

const { store } = createStore()

const App = (): JSX.Element => {
    const gtmParamsId = 'GTM-WCNWBKZ'

    const [chainGtmParams, setChainGtmParams]: [ISnippetsParams, any] = useState({
        id: null,
        injectScript: false,
    })

    const [gtmParams, setGtmParams]: [ISnippetsParams, any] = useState({
        id: null,
        injectScript: false,
    })

    const setGtmIds = (chainGtmId: string) => {
        if (! chainGtmParams.id && chainGtmId) {
            setChainGtmParams({...chainGtmParams, id: chainGtmId, injectScript: true})
        }
        if (! gtmParams.id) {
            setGtmParams({...gtmParams, id: gtmParamsId, injectScript: true})
        }
    }

    return (
        <Provider store={store}>
            <HelmetProvider>
                <GTMProvider state={gtmParams}>
                    <GTMProvider state={chainGtmParams}>
                        <Router>
                            <MainRoutes themes={themes} setGtmIds={setGtmIds}/>
                        </Router>
                    </GTMProvider>
                </GTMProvider>
                <Notification />
            </HelmetProvider>
        </Provider>
    )
}

export default App
