import { call } from '@redux-saga/core/effects'
import { ApiResponse } from 'apisauce'
import { AnyAction } from 'redux'
import { ApiType } from '../Services/api'

export function* createPayment(api: ApiType, action: AnyAction) {
    const { token, quick } = action
    const response: ApiResponse<any> = yield call(api.createPayment, { token, quick })
    if (response.ok) {
        const { data } = response
        top.location.href = data.href
    } else {
        top.location.href = '/quick-payment/thank-you/cancel?token=' + token
    }
}
