import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Radio, FormControlLabel } from '@mui/material'

const RadioWrapper: FC<FieldRenderProps<any>> = ({
    input: { checked, value, name, onChange, ...restInput },
    label,
    ...rest
}) => (
    <FormControlLabel
        control={
            <Radio
                {...rest}
                name={name}
                inputProps={restInput}
                onChange={onChange}
                checked={checked}
                value={value}
                color="primary"
            />
        }
        label={label}
    />
)

export default RadioWrapper
