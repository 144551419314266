import { CircularProgress,  Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import {DateTime} from 'luxon'
import React, {FC, useEffect, useMemo} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {InspectService, PriceAndTime} from '../../interfaces'
import { RootState } from '../../Redux'
import {useMobile, useAppThemeOptions} from '../../Utils/hooks'
import { CallbackArray } from '../../Utils/types'
import { PrimaryButton, SecondaryButton } from '../Button'
import { AffordablePrices } from './AffordablePrices'
import { DatePicker } from './DatePicker'
import { TimePicker } from './TimePicker'
import {getLocale} from '../../Utils/helpers'
import {ThemeType} from '../../Utils/themes'

const useStyles = makeStyles<ThemeType>()((theme: Theme, options: ThemeType) => ({
    contentChild: {
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    dialog: {
        borderRadius: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
        },
    },
    dialogContent: {
        minWidth: 320,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    campaignCodeButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    campaignCodeButton: {
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(2),
        },
    },
    nextButton: {
        borderRadius: 24,
        fontSize: theme.typography.pxToRem(16),
        marginTop: theme.spacing(2),
        width: 250,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            maxWidth: '100%',
        },
        '&:hover': {
            fontWeight: 'bold',
        },
    },
    cheapestPriceText: {
        alignSelf: 'center',
        marginBottom: theme.spacing(2),
        color: options.pricesTextColor ?? theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: theme.typography.h2.fontSize,
    },
    spaceBetween: {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    campaignInfoContainer: {
        marginTop: theme.spacing(1),
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    specialOfferContainer: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
        border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: 8,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    specialOfferHeading: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 'bold',
    },
    textField: {
        height: 40,
        marginTop: 16,
    },
    freeTimes: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 'bold',
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    date: {
        color: options.pricesTextColor ?? theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(16),
        textTransform: 'capitalize',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
    },
    cheapPricesButton: {
        position: 'absolute',
        right: 0,
        borderRadius: 24,
        backgroundColor: theme.palette.grey[100],
        boxSizing: 'border-box',
        border: `2px solid ${theme.palette.secondary.main}`,
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.secondary.main,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        width: 250,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            fontWeight: 'bold',
            color: '#fff',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
            marginBottom: 0,
            position: 'initial',
        },
    },
}))

interface DateStepProps {
    forceMobile?: boolean
    disableCheapest?: boolean
    disableCampaignCode?: boolean
    nextButtonText?: string
    date: DateTime | null
    bestPrices: any
    handleDateChange(date: DateTime): void
    getDateTimes(dateStart: DateTime, dateEnd, campaign: string | null, callback?: () => void): void
    dateTimes: any
    fetching: any
    handleTimeChange: any
    showDatePicker: any
    timeIsSet: any
    setInspectDate(date: DateTime | null, campaignCode: string, callback: () => void | CallbackArray): () => void
    setReservationCampaignCode?: (campaignCode: string, callback?: (() => void) | CallbackArray) => void
    setShowDatePicker: any
    goNext: any
    contentStyle: string
    canGoToIndex?: (num: number) => boolean | undefined
    onGetBestPrices(
        service: InspectService,
        campaign: string | null,
        dateStart: string | undefined,
        dateEnd: string | undefined,
        callback?: () => void,
    ): void
}

export const DateStep: FC<DateStepProps> = ({
    forceMobile,
    nextButtonText,
    disableCheapest,
    date,
    bestPrices,
    canGoToIndex,
    handleDateChange,
    getDateTimes,
    dateTimes,
    fetching,
    handleTimeChange,
    showDatePicker,
    timeIsSet,
    setInspectDate,
    setShowDatePicker,
    goNext,
    onGetBestPrices,
    contentStyle,
}) => {
    const locale = getLocale()
    const showCalendarPrices = useSelector((state: RootState) => state.ui.settings?.booking.options.showCalendarPrices)
    const showBestPrices = useSelector((state: RootState) => state.ui.settings?.booking.options.showBestPrices)
    const showOffer = useSelector((state: RootState) => state.ui.settings?.booking.options.showOffer)
    const service = useSelector((state: RootState) => state.reservation.service)
    const registerInfo = useSelector((state: RootState) => state.reservation.registerInfo ?? state.checkIn?.checkIn?.reservation?.tasks?.find(t => t.vehicle?.afterInspectionProductId)?.vehicle)
    const { t } = useTranslation()
    const themeOptions = useAppThemeOptions()
    const { classes } = useStyles(themeOptions)
    const showDatePickerCondition =
        disableCheapest ||
        showDatePicker ||
        !bestPrices?.prices?.length ||
        !showBestPrices
    const campaignCode = useSelector((state: RootState) => state.reservation.campaignCode)
    const isMobile = forceMobile || useMobile('md')
    const firstDate = bestPrices?.period?.start ? DateTime.fromISO(bestPrices.period.start) : null
    const weekStart = firstDate ? firstDate.startOf('week').startOf('day') : DateTime.local().plus({days: 1}).startOf('week').startOf('day')
    const weekEnd = weekStart.endOf('week').startOf('day')

    const dates = useMemo(() => {
        let day = weekStart
        const array: DateTime[] = []

        while (day <= weekEnd) {
            // Drop sundays out
            if (day.weekday !== 7) {
                array.push(day)
            }
            day = day.plus({ days: 1 })
        }
        return array
    }, [weekStart, weekEnd])

    const handleFindOtherDate = () => {
        const cb = () => setShowDatePicker(true)
        handleDateChange(date)
        getDateTimes(weekStart, weekEnd, campaignCode, cb)
    }

    useEffect(() => {
        if (! date && bestPrices) {
            const availableDate = bestPrices?.prices?.length > 0 ? bestPrices.prices.map(p => DateTime.fromISO(p.time, {setZone: true})).sort((a,b) => a.toMillis() - b.toMillis())[0] : null
            const startOfDay = availableDate && availableDate.isValid ? availableDate.startOf('day') : DateTime.local().startOf('day')
            const initialDate = startOfDay.weekday === 7 ? startOfDay.plus({days: 1}) : startOfDay

            handleDateChange(initialDate)

            if (showDatePickerCondition) {
                getDateTimes(initialDate, initialDate.endOf('week').startOf('day'), campaignCode)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, bestPrices, campaignCode, showDatePickerCondition])

    const fixedPrice = useMemo(() => {
        return registerInfo?.afterInspectionProductId === service?.id ? registerInfo?.afterInspectionPrice?.value : null
    }, [registerInfo, service])

    // Render date picker
    if (showDatePickerCondition) {
        return (
            <>
                <div className={contentStyle}>
                    <div className={classes.contentChild}>
                        <DatePicker
                            forceMobile={forceMobile}
                            prices={!showCalendarPrices ? null : bestPrices?.prices}
                            dates={dates}
                            value={date}
                            locale={locale}
                            onChange={(newDate) => handleDateChange(newDate)}
                            service={service}
                            fixedPrice={fixedPrice}
                            onGetBestPrices={(service, dateStart, dateEnd, callback) => {
                                handleDateChange(dateStart ? DateTime.fromISO(dateStart) : date)
                                onGetBestPrices(service, campaignCode, dateStart, dateEnd, callback)
                                getDateTimes(dateStart ? DateTime.fromISO(dateStart) : null, dateEnd ? DateTime.fromISO(dateEnd) : null, campaignCode)
                            }}
                        />
                    </div>
                    {date && isMobile && (
                        <div className={classes.flexContainer}>
                            <div className={classes.row}>
                                <div className={classes.contentChild}>
                                    {fetching.dateTimes ? <CircularProgress size={24}/> : <Typography className={classes.date}>
                                        {date.toFormat('cccc dd.MM', {locale})}
                                    </Typography>}
                                </div>
                            </div>
                        </div>
                    )}
                    <TimePicker
                        forceMobile={forceMobile}
                        clickDisabled={fetching.dateTimes}
                        showPrices={!!showCalendarPrices}
                        timesAndPrices={dateTimes}
                        listPrice={service?.listPrice}
                        fixedPrice={fixedPrice}
                        fetching={fetching.reservation || fetching.checkIn}
                        display={!!date}
                        date={date}
                        dates={dates}
                        onChange={handleTimeChange}
                        time={date}
                        first={{ hour: 8, minute: 0 }}
                        last={{ hour: 15, minute: 45 }}
                        timeIsSet={timeIsSet}
                        renderMiddleButton={() => (
                            <div>
                            </div>
                        )}
                        renderSelectButton={() => (
                            <PrimaryButton
                                id="next-time"
                                className={classes.nextButton}
                                onClick={setInspectDate(date, campaignCode, goNext)}
                                disabled={!timeIsSet || !canGoToIndex?.(4)}
                            >
                                {nextButtonText || t('common.next')}
                            </PrimaryButton>
                        )}
                    />
                </div>
            </>
        )
    }

    // Render cheapest prices
    return (
        <>
            <div className={contentStyle}>
                <div className={classes.contentChild}>
                    <Typography className={classes.cheapestPriceText}>
                        {t('rootContainer.cheapestPrices')}
                    </Typography>
                    <AffordablePrices
                        dates={bestPrices?.prices}
                        locale={locale}
                        selectTime={(item: PriceAndTime) =>
                            handleTimeChange(DateTime.fromISO(item.time, {setZone: true}))
                        }
                        selectedTime={bestPrices?.prices?.find(
                            (item: any) =>
                                DateTime.fromISO(item.time, {setZone: true}).toISO() ===
                                date?.toISO(),
                        )}
                        fetching={fetching.bestPrices}
                        fixedPrice={fixedPrice}
                    />
                    <div className={classes.spaceBetween}>
                        <SecondaryButton
                            onClick={handleFindOtherDate}
                            fetching={fetching.dateTimes}
                        >
                            {t('rootContainer.findDate')}
                        </SecondaryButton>
                        <PrimaryButton
                            id="next-time-suggested"
                            onClick={setInspectDate(date, campaignCode, goNext)}
                            className={classes.nextButton}
                            disabled={!timeIsSet || !canGoToIndex?.(4)}
                        >
                            {t('common.next')}
                        </PrimaryButton>
                    </div>
                    {showOffer && (
                        <div className={classes.specialOfferContainer}>
                            <Typography
                                paragraph
                                className={classes.specialOfferHeading}
                            >
                                {t('rootContainer.specialOffer')}
                            </Typography>
                            <Typography paragraph>{/* Content here */}</Typography>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
