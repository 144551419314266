import {
    StepConnector,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

const useStyles = makeStyles()((theme: Theme) => ({
    active: {
        '& $line': {
            borderColor: theme.palette.grey[100],
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.grey[100],
        },
    },
    line: {
        borderColor: theme.palette.grey[400],
        borderTopWidth: 1,
        borderRadius: 1,
    },
}))

export const StyledStepConnector: FC = ({
    ...props
}) => {
    const { classes } = useStyles()
    return (
        <StepConnector
            classes={{
                line: classes.line,
                active: classes.active,
                completed: classes.completed,
            }}
            {...props}
        />
    )
}
