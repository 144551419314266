import 'url-search-params-polyfill'
import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { get } from 'lodash'
import { FC } from 'react'
import { Redirect, Route, RouteComponentProps, Switch, useLocation } from 'react-router'
import { RootContainer } from '../Containers/RootContainer'
import {PrefillValues, ThemeCollection} from '../interfaces'
import { ErrorContainer } from '../Containers/ErrorContainer'
import { PrivacyPolicyContainer } from '../Containers/PrivacyPolicyContainer'
import { ReservationDoneContainer } from '../Containers/ReservationDoneContainer'
import { ServiceCardContainer } from '../Containers/ServiceCardContainer'
import { CancelledReservationContainer } from '../Containers/CancelledReservationContainer'
import { PaymentContainer } from '../Containers/PaymentContainer'
import {useAppThemeName} from './hooks'

interface SubRouteProps extends RouteComponentProps {
    themes: ThemeCollection
    embedded: boolean
    setGtmIds: (string) => void
}

interface RootRouteProps extends SubRouteProps {
    prefill?: PrefillValues
    scrollContainerRef?: any
}

export const MuiProviders: FC<any> = ({ children, theme }) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )
}

const CheckInRoutes: FC<SubRouteProps> = ({ match, themes, embedded , setGtmIds}) => {
    const themeName = useAppThemeName()
    const theme = get(themes, themeName)
    return (
        <MuiProviders theme={theme}>
            <Switch>
                <Route
                    path={match.path}
                    render={(props) => (
                        <ServiceCardContainer
                            {...props}
                            embedded={embedded}
                            themeName={themeName}
                            setGtmIds={setGtmIds}
                        />
                    )}
                />
                <Route render={(props: RouteComponentProps) => (<ErrorContainer {...props} />)} />
            </Switch>
        </MuiProviders>
    )
}

const ReservationRoutes: FC<RootRouteProps> = ({ match, themes, embedded, setGtmIds, prefill, scrollContainerRef}) => {
    const themeName = useAppThemeName()
    const theme = get(themes, themeName, themes.default)
    return (
        <MuiProviders theme={theme}>
            <Switch>
                <Route
                    path={[`${match.path}/thank-you/:state`, `${match.path}/thank-you`]}
                    render={(props) => <ReservationDoneContainer embedded={embedded} {...props} />}
                />
                <Route
                    exact
                    path={match.path}
                    render={(props) => (
                        <RootContainer
                            {...props}
                            embedded={embedded}
                            themeName={themeName}
                            setGtmIds={setGtmIds}
                            scrollContainerRef={scrollContainerRef}
                            prefill={prefill}
                        />
                    )}
                />
                <Route render={(props: RouteComponentProps) => (<ErrorContainer {...props} />)} />
            </Switch>
        </MuiProviders>
    )
}

interface RouteProps {
    themes: ThemeCollection
    setGtmIds: (string) => void
}

export const MainRoutes: FC<RouteProps> = ({ themes , setGtmIds}) => {
    const { pathname, search } = useLocation()
    return (
        <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1) + search} />
            <Route
                exact
                path="/chain/:chainId(\d+)/:stationId(\d+)/cancelled-reservation"
                render={(props) => {
                    const query = new URLSearchParams(props.location.search)
                    const token = query.get('token')

                    return token ? <Redirect to={"/check-in?token=" + token} /> : <CancelledReservationContainer {...props} embedded={false} themes={themes} />
                }}
            />
            <Route
                exact
                path="/chain/:chainId(\d+)/cancelled-reservation"
                render={(props) => {
                    const query = new URLSearchParams(props.location.search)
                    const token = query.get('token')

                    return token ? <Redirect to={"/check-in?token=" + token} /> : <CancelledReservationContainer {...props} embedded={false} themes={themes} />
                }}
            />
            <Route
                exact
                path="/error"
                render={(props: RouteComponentProps) => (
                    <ErrorContainer {...props} />
                )}
            />
            <Route
                exact
                path="/privacy-policy"
                render={(props: RouteComponentProps) => (
                    <PrivacyPolicyContainer {...props} />
                )}
            />
            <Route
                path="/check-in/embed"
                render={(props: RouteComponentProps) => (
                    <CheckInRoutes themes={themes} {...props} embedded={true} setGtmIds={setGtmIds} />
                )}
            />
            <Route
                path="/check-in"
                render={(props: RouteComponentProps) => (
                    <CheckInRoutes
                        embedded={false}
                        setGtmIds={setGtmIds}
                        themes={themes}
                        {...props}
                    />
                )}
            />
            <Route
                path="/booking/chain/:chainId(\d+)/station/:stationId(\d+)/embed"
                render={(props: RouteComponentProps) => (
                    <ReservationRoutes
                        embedded={true}
                        setGtmIds={setGtmIds}
                        themes={themes}
                        {...props}
                    />
                )}
            />
            <Route
                path="/booking/chain/:chainId(\d+)/station/:stationId(\d+)"
                render={(props: RouteComponentProps) => (
                    <ReservationRoutes
                        embedded={false}
                        setGtmIds={setGtmIds}
                        themes={themes}
                        {...props}
                    />
                )}
            />
            <Route
                path="/booking/:chainId(\d+)/:stationId(\d+)/embed"
                render={(props: RouteComponentProps) => (
                    <ReservationRoutes
                        embedded={true}
                        setGtmIds={setGtmIds}
                        themes={themes}
                        {...props}
                    />
                )}
            />
            <Route
                path="/booking/chain/:chainId(\d+)/embed"
                render={(props: RouteComponentProps) => (
                    <ReservationRoutes
                        embedded={true}
                        setGtmIds={setGtmIds}
                        themes={themes}
                        {...props}
                    />
                )}
            />
            <Route
                path="/booking/chain/:chainId(\d+)"
                render={(props: RouteComponentProps) => (
                    <ReservationRoutes
                        embedded={false}
                        setGtmIds={setGtmIds}
                        themes={themes}
                        {...props}
                    />
                )}
            />
            <Route
                path="/booking/:chainId(\d+)/embed"
                render={(props: RouteComponentProps) => (
                    <ReservationRoutes
                        embedded={true}
                        setGtmIds={setGtmIds}
                        themes={themes}
                        {...props}
                    />
                )}
            />
            <Route
                path="/booking/:chainId(\d+)/:stationId(\d+)?"
                render={(props: RouteComponentProps) => (
                    <ReservationRoutes
                        embedded={false}
                        setGtmIds={setGtmIds}
                        themes={themes}
                        {...props}
                    />
                )}
            />
            <Route
                path={'/quick-payment'}
                render={(props: RouteComponentProps) => (
                    <ReservationRoutes
                        embedded={false}
                        setGtmIds={setGtmIds}
                        themes={themes}
                        {...props}
                    />
                )}
            />
            <Route
                path={'/payment'}
                render={(props: RouteComponentProps) => (
                    <PaymentContainer
                        {...props}
                    />
                )}
            />
            <Route
                exact
                path="/:token([A-Za-z0-9]{8})"
                render={({match}) => <Redirect to={"/check-in?token=" + (match.params as any).token} />}
            />
            <Route
                exact
                path="/:chainId(\d+)"
                render={({match}) => <Redirect to={"/booking/" + (match.params as any).chainId} />}
            />
            <Route
                exact
                path="/:chainId(\d+)/:stationId(\d+)"
                render={({match}) => <Redirect to={"/booking/" + (match.params as any).chainId + "/" + (match.params as any).stationId} />}
            />
            <Route render={(props: RouteComponentProps) => (<ErrorContainer {...props} />)} />
        </Switch>
    )
}
