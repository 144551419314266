export type CommonReducer<T> = (state: T) => T

export type Reducer<T> = (state: T, stateChange: T) => T

export const getGeneralApiProblem = (
    problem: string,
    status: number | undefined,
): string => {
    return `Jokin meni nyt mönkään :( \nVirhekoodi: ${status}`
}
