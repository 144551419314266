import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React, {useEffect, useState} from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../Redux'
import {useAppThemeOptions} from '../../Utils/hooks'
import TextFieldWrapper from '../Form/TextField'
import {ThemeType} from '../../Utils/themes'

const useStyles = makeStyles<ThemeType>()((theme: Theme) => ({
    dialog: {
        borderRadius: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
        },
    },
    dialogContent: {
        minWidth: 320,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
}))

const validate = values => {
    const errors: any = {}
    const campaignCode = values?.campaignCode?.trim()
    if (campaignCode === '' || values.campaignCode === undefined) {
        errors.substitution = '*'
    }

    return errors
}
const CampaignCodeDialog = (props: CampaignCodeDialogProps) => {
    const {handleSetCampaignCode, dialogIsOpen, closeCampaignCodeDialog} = props
    const { t } = useTranslation()
    const themeOptions = useAppThemeOptions()
    const campaignCode = useSelector((state: RootState) => state.reservation.campaignCode)
    const { classes } = useStyles(themeOptions)

    const [isOpen, setIsOpen] = useState<boolean|null>(dialogIsOpen)

    useEffect(() => {
        setIsOpen(dialogIsOpen)
    }, [dialogIsOpen])

    return (
        <Dialog
            PaperProps={{
                classes: { root: classes.dialog },
            }}
            classes={{ root: classes.dialog }}
            aria-labelledby="campaign-code-dialog-title"
            open={isOpen || false}
        >
            <DialogTitle id="campaign-code-dialog-title" className={classes.dialogTitle}>
                {t('rootContainer.inputCampaignCode')}
            </DialogTitle>
            <Form
                initialValues={{
                    campaignCode
                }}
                validate={validate}
                onSubmit={(props) => props.campaignCode?.trim() !== campaignCode ? handleSetCampaignCode({...props, campaignCode: props.campaignCode?.trim()}): closeCampaignCodeDialog()}
                render={({ handleSubmit, hasValidationErrors, values }) =>
                    <form onSubmit={handleSubmit}>
                        <DialogContent className={classes.dialogContent}>
                            <Field
                                name="campaignCode"
                                type="text"
                            >
                                {(props) => (
                                    <TextFieldWrapper
                                        {...props}
                                        /* eslint-disable-next-line jsx-a11y/no-autofocus */
                                        inputProps={{ autoFocus: true }}
                                        variant="outlined"
                                        placeholder={t('common.campaignCode')}
                                    />
                                )}
                            </Field>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeCampaignCodeDialog} color="primary">
                                {campaignCode ? t('common.cancel') : t('common.no_campaign_code')}
                            </Button>
                            <Button disabled={!values?.campaignCode || hasValidationErrors} type="submit" color="primary">
                                {t('common.accept')}
                            </Button>
                        </DialogActions>
                    </form>
                }
            />
        </Dialog>
    )
}

interface CampaignCodeDialogProps {
    handleSetCampaignCode: (e: any) => void
    closeCampaignCodeDialog: () => void
    dialogIsOpen: boolean
}

export default CampaignCodeDialog
