import React, { FC } from "react"
import ical from 'ical-generator'
import { DateTime } from 'luxon'
import EventIcon from '@mui/icons-material/Event'
import { IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
    button: {
        padding: 0,
        cursor: 'pointer !important'
    },
}))

export const ICalendarLink: FC<ICalendarLinkProps> = ({ title, description, startTime, endTime, location }) => {
    const { classes } = useStyles()
    const calendar = ical({ name: 'Katsastus' });
    calendar.createEvent({
        start: startTime,
        end: endTime,
        summary: title,
        description,
        location,
    })

    calendar.timezone('Europe/Helsinki')

    const download = () => {
        const blob = calendar.toBlob()
        const element = document.createElement("a")
        element.href = URL.createObjectURL(blob)
        element.download = 'katsastus.ical'
        document.body.appendChild(element) // Required for this to work in FireFox
        element.click()
        document.body.removeChild(element)
    }

    return (
        <IconButton classes={{ root: classes.button }} onClick={download}><EventIcon color="secondary" /></IconButton>
    )
}

interface ICalendarLinkProps {
    title: string
    description: string
    startTime: DateTime
    endTime: DateTime
    location: string
}