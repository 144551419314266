import {
    CircularProgress,
    IconButton,
    Paper,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { Field, FormRenderProps } from 'react-final-form'
import { SearchSubmitEvent } from '../../Containers/RootContainer'
import InputBaseWrapper from '../Form/InputBase'
import SearchIcon from '@mui/icons-material/Search'

const useStyles = makeStyles()((theme: Theme) => ({
    paper: {
        borderRadius: 24,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        width: 300,
        [theme.breakpoints.down('md')]: {
            width: 250,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 0, 2),
        },
    },
    iconButton: {
        padding: theme.spacing(1),
    },
    locationTextField: {
        backgroundColor: theme.palette.background.paper,
        height: '100%',
        flex: 1,
        borderRadius: 24,
        paddingLeft: theme.spacing(1),
    },
    locationTextFieldInput: {
        color: theme.typography.caption.color,
        fontFamily: theme.typography.caption.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        paddingLeft: theme.spacing(2),
    },
}))

interface SearchFormProps
    extends FormRenderProps<SearchSubmitEvent, Partial<SearchSubmitEvent>> {
    fetching: boolean
    inputId: string
}

export const SearchForm: FC<SearchFormProps> = ({
    inputId,
    handleSubmit,
    fetching,
}) => {
    const { classes } = useStyles()
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Field id={inputId} name="search" type="text">
                {(props) => {
                    return (
                        <Paper className={classes.paper}>
                            <InputBaseWrapper
                                aria-label="Asemien haku"
                                {...props}
                                classes={{
                                    root: classes.locationTextField,
                                }}
                                inputProps={{
                                    className: classes.locationTextFieldInput,
                                }}
                                variant="standard"
                                placeholder="Aseman haku"
                            />
                            <IconButton
                                type="submit"
                                className={classes.iconButton}
                                aria-label="Hae"
                            >
                                {fetching ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <SearchIcon />
                                )}
                            </IconButton>
                        </Paper>
                    )
                }}
            </Field>
        </form>
    )
}
