import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

const useStyles = makeStyles()((theme: Theme) => ({
    accordion: {
        margin: theme.spacing(0, 0, 1),
        borderRadius: 8,
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            '&.MuiExpansionPanel-root:before': {
                display: 'none',
            },
            '&.MuiAccordion-root:before': {
                display: 'none',
            },
        },
        '&.MuiCollapse-container, .MuiCollapse-entered, .MuiCollapse-wrapper, .MuiCollapse-wrapperInner': {
            flex: 1,
        },
    },
    accordionShadow: {
        boxShadow: theme.shadows[6],
    },
    accordionFullHeight: {
        height: '100%',
    },
    accordionUnopenable: {
        '& .MuiAccordionSummary-root:hover, .MuiButtonBase-root': {
            cursor: 'default',
        },
    },
    accordionContent: {
        '&.Mui-expanded': {
            margin: '12px 0px',
        },
    },
    accordionExpanded: {
        [theme.breakpoints.down('sm')]: {
            margin: '0px !important',
        },
    },
    accordionSummaryExpanded: {},
    accordionSummaryContent: {
        flexDirection: 'column',
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    subHeading: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.grey[900],
    },
    details: {
        marginTop: theme.spacing(-1.5),
        flexDirection: 'column',
    },
}))

interface AccordionItemProps {
    content?: JSX.Element | null
    heading: string
    subHeading?: string | JSX.Element | JSX.Element[]
    autoHeight?: boolean
}

export const AccordionItem: FC<AccordionItemProps> = ({
    heading,
    content,
    subHeading,
    autoHeight,
}) => {
    const { classes } = useStyles()
    const accordionClasses = {
        root: `${classes.accordion} ${classes.accordionUnopenable} ${autoHeight ? null : classes.accordionFullHeight} ${classes.accordionShadow}`,
        expanded: classes.accordionExpanded,
    }

    return (
        <Accordion expanded={true} square classes={accordionClasses}>
            <AccordionSummary
                expandIcon={null}
                classes={{
                    content: content ? classes.accordionContent : undefined,
                    expanded: classes.accordionSummaryExpanded,
                }}
            >
                <div className={classes.accordionSummaryContent}>
                    <Typography className={classes.heading}>
                        {heading}
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.details }}>
                {subHeading && typeof subHeading === 'string' ? (
                    <Typography className={classes.subHeading}>
                        {subHeading}
                    </Typography>
                ) : (
                    subHeading
                )}
                {content}
            </AccordionDetails>
        </Accordion>
    )
}
