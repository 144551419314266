import {
    FormLabel,
    Grid, Link,
    RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React, { FC, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { InfoSubmitEvent } from '../../Containers/RootContainer'
import { useMobile, useAppThemeOptions } from '../../Utils/hooks'
import CheckboxWrapper from '../Form/Checkbox'
import TextFieldWrapper from '../Form/TextField'
import RadioButtonWrapper from '../Form/RadioButton'
import { PrimaryButton } from '../Button'
import {getLocale, getServiceTranslation, numberToCurrencyString} from '../../Utils/helpers'
import { useSelector } from 'react-redux'
import { RootState } from '../../Redux'
import {NEW_REGISTRATION, TRAFICOM_FEE_CATEGORY_ID} from '../../Utils/const'
import {InspectService, InspectStation, PrefillValues} from '../../interfaces'
import {a11yClick} from '../../Utils/a11yHelpers'
import {ThemeType} from '../../Utils/themes'

const useStyles = makeStyles<ThemeType>()((theme: Theme, options: ThemeType) => ({
    contentEnd: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'stretch',
    },
    contentErrors: {
        display: 'flex',
        marginTop: theme.spacing(2),
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkboxes: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    errorText: {
        color: theme.palette.error.main,
    },
    infoText: {
        fontSize: theme.typography.pxToRem(12),
        marginLeft: theme.spacing(4),
    },
    traficomInfoText: {
        fontSize: theme.typography.pxToRem(14),
        marginTop: theme.spacing(1),
    },
    form: {
        display: 'flex',
        marginTop: theme.spacing(4),
        flexDirection: 'column',
    },
    textFieldContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    column: {
        marginLeft: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3),
        boxShadow: theme.shadows[4],
    },
    submitButton: {
        borderRadius: 24,
        fontSize: theme.typography.pxToRem(16),
        marginTop: theme.spacing(3),
        width: 250,
        '&:hover': {
            fontWeight: 'bold',
        },
    },
    textField: {
        marginBottom: theme.spacing(2),
        maxWidth: 300,
    },
    formLabel: {
        color: options.infoFormTextColor ?? theme.palette.primary.main,
        fontWeight: 'bold',
        margin: theme.spacing(1, 0, 0),
    },
    reservingText: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 'bold',
    },
    boldText: {
        fontWeight: 'bold',
    },
    table: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    tableCell: {
        borderBottomWidth: 0,
        padding: theme.spacing(1),
        verticalAlign: 'top',
        '&:last-child': {
            width: '100%',
        }
    },
    userInformationContainer: {
        boxShadow: theme.shadows[4],
        padding: theme.spacing(2),
        margin: theme.spacing(3, 0),
        borderRadius: 8,
    },
}))

interface InfoFormProps {
    validate: any
    onSubmiInfo: any
    fetching: boolean
    inputId: string
    inspectDate: any
    inspectService: InspectService | null
    locale: string
    prices?: {
        normal: { value: number; includesVat: boolean }
        online: { value: number; includesVat: boolean }
        prepaid: { value: number; includesVat: boolean }
    }
    registerInfo: any
    station: InspectStation | null
    prefill: PrefillValues,
}

export const InfoForm: FC<InfoFormProps> = ({
    inspectDate,
    inspectService,
    locale,
    onSubmiInfo,
    validate,
    prices,
    registerInfo,
    station,
    fetching,
    prefill,
}) => {
    const themeOptions = useAppThemeOptions()
    const { classes } = useStyles(themeOptions)
    const isMobile = useMobile()
    const { t } = useTranslation()
    const language = getLocale()
    const bookingOptions = useSelector((state: RootState) => state.ui.settings?.booking?.options)
    const traficomFee = useSelector((state: RootState) => state.reservation?.reservation?.reservation?.tasks?.map(
        (task) => task?.basket?.items?.map(
            (basketItem) => basketItem?.categoryId === TRAFICOM_FEE_CATEGORY_ID ? basketItem?.traficomFee : null
        ).filter(fee => !! fee).reduce((total, fee) => total + Number.parseFloat(fee), 0)
    ).reduce((total, fee) => total + Number.parseFloat(fee), 0))
    const classesProps = {
        classes: { root: classes.textField },
    }
    const [showErrors, setShowErrors] = useState<boolean>(false)
    if (!prices) return null

    const fields = {
        firstName: 'firstName',
        lastName: 'lastName',
        email: 'email',
        phone: 'phone',
        postalAddress: 'postalAddress',
        postalCode: 'postalCode',
        postOffice: 'postOffice',
        reminder: {
            textMessage: 'reminder.textMessage',
            emailMessage: 'reminder.emailMessage',
        },
        offer: {
            textMessage: 'offer.textMessage',
            emailMessage: 'offer.emailMessage',
        },
        owner: 'owner',
        payment: 'payment',
    }

    const isReadyToSend = (values) => {
        const {
            acceptTermsOfUse,
            firstName,
            lastName,
            email,
            phone,
            postalAddress,
            postalCode,
            postOffice,
            payment,
            owner,
        } = values
        if (
            acceptTermsOfUse &&
            firstName &&
            !validate.firstName(firstName) &&
            lastName &&
            !validate.lastName(lastName) &&
            email &&
            !validate.email(email) &&
            phone &&
            !validate.phone(phone) &&
            !validate.postalAddress(postalAddress) &&
            !validate.postalCode(postalCode) &&
            !validate.postOffice(postOffice) &&
            (! bookingOptions?.askOwnership || owner) &&
            payment
        ) {
            return true
        }
        return false
    }

    const getMissingInfoTexts = (values): string[] => {
        const {
            acceptTermsOfUse,
            firstName,
            lastName,
            email,
            phone,
            postalAddress,
            postalCode,
            postOffice,
            payment,
            owner,
        } = values

        const missing: string[] = []
        const requireAddress = !! (postalAddress || postalCode || postOffice || values.reminder?.textMessage || values.reminder?.emailMessage || values.offer?.textMessage || values.offer?.emailMessage)

        if(!firstName || validate.firstName(firstName)) {
            missing.push(t('common.firstName'))
        }
        if(!lastName || validate.lastName(lastName)) {
            missing.push(t('common.lastName'))
        }
        if(!email || validate.email(email)) {
            missing.push(t('common.email'))
        }
        if(!phone || validate.phone(phone)) {
            missing.push(t('common.phone'))
        }
        if((requireAddress && !postalAddress) || validate.postalAddress(postalAddress)) {
            missing.push(t('common.postalAddress'))
        }
        if((requireAddress && !postalCode) || validate.postalCode(postalCode)) {
            missing.push(t('common.postalCode'))
        }
        if((requireAddress && !postOffice) || validate.postOffice(postOffice)) {
            missing.push(t('common.postOffice'))
        }
        if (bookingOptions?.askOwnership && !owner) {
            missing.push(t('rootContainer.checkInfo.vehicleOwnership'))
        }
        if (!payment) {
            missing.push(t('common.paymentType'))
        }
        if (!acceptTermsOfUse) {
            missing.push(t('rootContainer.checkInfo.acceptTermsOfUse'))
        }

        return missing
    }

    const renderTextFields = (values) => {
        const requireAddress = !! (values.postalAddress || values.postalCode || values.postOffice || values.reminder?.textMessage || values.reminder?.emailMessage || values.offer?.textMessage || values.offer?.emailMessage)

        return (
            <div className={classes.textFieldContainer}>
                <Field
                    name={fields.firstName}
                    type="text"
                    validate={validate.firstName}
                >
                    {(props) => (
                        <TextFieldWrapper
                            {...props}
                            {...classesProps}
                            variant="standard"
                            required
                            placeholder={t('common.firstName')}
                        />
                    )}
                </Field>
                <Field
                    name={fields.lastName}
                    type="text"
                    validate={validate.lastName}
                >
                    {(props) => (
                        <TextFieldWrapper
                            {...props}
                            {...classesProps}
                            variant="standard"
                            required
                            placeholder={t('common.lastName')}
                        />
                    )}
                </Field>
                <Field
                    name={fields.email}
                    type="text"
                    validate={validate.email}
                >
                    {(props) => (
                        <TextFieldWrapper
                            {...props}
                            {...classesProps}
                            variant="standard"
                            required
                            placeholder={t('common.email')}
                        />
                    )}
                </Field>
                <Field name={fields.phone} type="tel" validate={validate.phone}>
                    {(props) => (
                        <TextFieldWrapper
                            {...props}
                            {...classesProps}
                            variant="standard"
                            required
                            placeholder={t('common.phone')}
                        />
                    )}
                </Field>
                <Field
                    name={fields.postalAddress}
                    type="text"
                    validate={validate.postalAddress}
                >
                    {(props) => (
                        <TextFieldWrapper
                            {...props}
                            {...classesProps}
                            required={requireAddress}
                            variant="standard"
                            placeholder={t('common.postalAddress')}
                        />
                    )}
                </Field>
                <Field
                    name={fields.postalCode}
                    type="text"
                    validate={validate.postalCode}
                >
                    {(props) => (
                        <TextFieldWrapper
                            {...props}
                            {...classesProps}
                            required={requireAddress}
                            variant="standard"
                            placeholder={t('common.postalCode')}
                        />
                    )}
                </Field>
                <Field
                    name={fields.postOffice}
                    type="text"
                    validate={validate.postOffice}
                >
                    {(props) => (
                        <TextFieldWrapper
                            {...props}
                            {...classesProps}
                            required={requireAddress}
                            variant="standard"
                            placeholder={t('common.postOffice')}
                        />
                    )}
                </Field>
            </div>
        )
    }

    const askMarketingEmail = bookingOptions?.askMarketing?.includes('email') ?? true
    const askMarketingSms = bookingOptions?.askMarketing?.includes('sms') ?? true
    const askReminderEmail = bookingOptions?.askReminder?.includes('email') ?? true
    const askReminderSms = bookingOptions?.askReminder?.includes('sms') ?? true

    const renderCheckBoxes = (values) => {
        return (
            <>
                {askReminderEmail || askReminderSms ? <div className={classes.checkboxes}>
                    <FormLabel className={classes.formLabel} component="legend">
                        {t('rootContainer.allowSendingReminders')}
                    </FormLabel>
                    <div className={classes.column}>
                        {askReminderSms ? <Field
                            name={fields.reminder.textMessage}
                            defaultValue={false}
                            type="checkbox"
                        >
                            {(props) => (
                                <>
                                    <CheckboxWrapper
                                        {...props}
                                        label={t(
                                            'rootContainer.viaTxt',
                                        )}
                                    />
                                </>
                            )}
                        </Field> : null}
                        {askReminderEmail ? <Field
                            name={fields.reminder.emailMessage}
                            defaultValue={false}
                            type="checkbox"
                        >
                            {(props) => (
                                <CheckboxWrapper
                                    {...props}
                                    label={t('rootContainer.viaEmail')}
                                />
                            )}
                        </Field> : null}
                        {values.reminder?.textMessage || values.reminder?.emailMessage ?
                            <div className={classes.infoText}>
                                {t('rootContainer.inviteAddressInfo')}
                            </div>
                        : null}
                    </div>
                </div> : null}
                {askMarketingEmail || askMarketingSms ? <div className={classes.checkboxes}>
                    <FormLabel className={classes.formLabel} component="legend">
                        {t('rootContainer.allowOffers')}
                    </FormLabel>
                    <div className={classes.column}>
                        {askMarketingSms ? <Field
                            name={fields.offer.textMessage}
                            defaultValue={false}
                            type="checkbox"
                        >
                            {(props) => (
                                <>
                                    <CheckboxWrapper
                                        {...props}
                                        label={t(
                                            'rootContainer.viaTxt',
                                        )}
                                    />
                                </>
                            )}
                        </Field> : null}
                        {askMarketingEmail ? <Field
                            name={fields.offer.emailMessage}
                            defaultValue={false}
                            type="checkbox"
                        >
                            {(props) => (
                                <CheckboxWrapper
                                    {...props}
                                    label={t(
                                        'rootContainer.viaEmail',
                                    )}
                                />
                            )}
                        </Field> : null}
                    </div>
                </div> : null}
                {bookingOptions?.askOwnership ? <div className={classes.checkboxes}>
                    <FormLabel
                        className={classes.formLabel}
                        component="legend"
                        required
                        error={showErrors && !values?.owner}
                    >
                        {t('rootContainer.ownerOrOccupant')}
                    </FormLabel>
                    <div className={classes.column}>
                        <RadioGroup>
                            <Field
                                name={fields.owner}
                                value="owner"
                                type="radio"
                            >
                                {(props) => (
                                    <>
                                        <RadioButtonWrapper
                                            {...props}
                                            label={t(
                                                'rootContainer.ownerOccupant',
                                            )}
                                        />
                                    </>
                                )}
                            </Field>
                            <Field
                                name={fields.owner}
                                value="other"
                                type="radio"
                            >
                                {(props) => (
                                    <RadioButtonWrapper
                                        {...props}
                                        label={t('rootContainer.other')}
                                    />
                                )}
                            </Field>
                            <Field
                                name={fields.owner}
                                value="noAnswer"
                                type="radio"
                            >
                                {(props) => (
                                    <RadioButtonWrapper
                                        {...props}
                                        label={t('rootContainer.noAnswer')}
                                    />
                                )}
                            </Field>
                        </RadioGroup>
                    </div>
                </div> : null}
                <div className={classes.checkboxes}>
                    <FormLabel
                        className={classes.formLabel}
                        component="legend"
                        required
                        error={showErrors && !values?.payment}
                    >
                        {t('rootContainer.choosePaymentMethod')}
                    </FormLabel>
                    <div className={classes.column}>
                        <RadioGroup>
                            {bookingOptions?.allowOnlinePayment &&
                                <Field
                                    name={fields.payment}
                                    value="payNow"
                                    type="radio"
                                >
                                    {(props) => (
                                        <>
                                            <RadioButtonWrapper
                                                {...props}
                                                label={t('rootContainer.payNow', { price: numberToCurrencyString(prices.prepaid.value) })}
                                            />
                                            <span className={classes.infoText}>
                                                {t('rootContainer.onlineCheckOutInfo')}
                                            </span>
                                        </>
                                    )}
                                </Field>
                            }
                            {bookingOptions?.allowCashPayment &&
                                <Field
                                    name={fields.payment}
                                    value="payAtStation"
                                    type="radio"
                                >
                                    {(props) => (
                                        <RadioButtonWrapper
                                            {...props}
                                            label={t('rootContainer.payAtStation', { price: numberToCurrencyString(prices.online.value) })}
                                        />
                                    )}
                                </Field>
                            }
                        </RadioGroup>
                        {traficomFee > 0 ? (
                            <span className={classes.traficomInfoText}>
                                    {t('rootContainer.priceIncludesTraficomFee', { price: numberToCurrencyString(traficomFee) })}
                                </span>
                        ) : null}
                    </div>
                </div>
            </>
        )
    }

    const getDefaultPaymentMethod = () => {
        if (bookingOptions?.allowCashPayment && !bookingOptions?.allowOnlinePayment) {
            return 'payAtStation'
        } else if (!bookingOptions?.allowCashPayment && bookingOptions?.allowOnlinePayment) {
            return 'payNow'
        }

        return undefined;
    }

    return (
        <Form
            onSubmit={(e: InfoSubmitEvent) => isReadyToSend(e) ? onSubmiInfo({...e, language}) : setShowErrors(true)}
            initialValues={{payment: getDefaultPaymentMethod(), ...prefill.customer}}
        >
            {({values, handleSubmit}) => {
                const readyToSend = isReadyToSend(values)

                return <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.gridItem}>{renderTextFields(values)}</div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.gridItem}>{renderCheckBoxes(values)}</div>
                        </Grid>
                    </Grid>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} className={classes.tableCell}>
                                    <Typography
                                        className={classes.reservingText}
                                    >
                                        {t('rootContainer.youAreReserving')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    className={classes.tableCell}
                                    size={'small'}
                                >
                                    <Typography className={classes.boldText}>
                                        {t('common.vehicle')}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography noWrap>
                                        {registerInfo?.registrationNumber === NEW_REGISTRATION ? t('rootContainer.unregisteredVehicle') : registerInfo?.atj?.registration ||
                                            (registerInfo as any)
                                                ?.registrationNumber}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    className={classes.tableCell}
                                    size={'small'}
                                >
                                    <Typography className={classes.boldText}>
                                        {t('common.station')}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography>
                                        {station?.name}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    className={classes.tableCell}
                                    size={'small'}
                                >
                                    <Typography className={classes.boldText}>
                                        {t('common.service')}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    className={`${classes.tableCell}`}
                                >
                                    <Typography noWrap>
                                        {getServiceTranslation('name', inspectService)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    className={classes.tableCell}
                                    size={'small'}
                                >
                                    <Typography className={classes.boldText}>
                                        {t('common.time')}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography>
                                        {inspectDate
                                            ?.setLocale(locale)
                                            .toFormat(
                                                `ccc dd.MM.yyyy '${t('common.time_of_day_abbr')}' T`, //eslint-disable-line
                                            )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div className={classes.contentEnd}>
                        <Field
                            name="acceptTermsOfUse"
                            defaultValue={false}
                            type="checkbox"
                            error={showErrors && !values?.acceptTermsOfUse}
                        >
                            {(props) => (
                                <>
                                    <CheckboxWrapper
                                        {...props}
                                    >
                                        <Typography className={showErrors && !values?.acceptTermsOfUse ? classes.errorText : null}>
                                            {t(
                                                'rootContainer.iAccept',
                                            )}
                                            <Link href={bookingOptions?.termsOfUseUrl} color="inherit" underline="always" target="_blank">
                                                {t('common.termsOfUse').toLowerCase()}
                                            </Link>
                                        </Typography>
                                    </CheckboxWrapper>
                                </>
                            )}
                        </Field>
                    </div>
                    <div className={classes.contentEnd}>
                        <PrimaryButton
                            id="complete-reservation"
                            isMobile={isMobile}
                            className={classes.submitButton}
                            {...a11yClick(() => {
                                setShowErrors(true)
                                handleSubmit()
                            })}
                            inactive={!readyToSend}
                            fetching={fetching}
                        >
                            {t('rootContainer.reserve')}
                        </PrimaryButton>
                    </div>
                    {showErrors && !readyToSend ? <div className={classes.contentErrors}>
                        <Typography className={classes.errorText}>
                            <b>{t('rootContainer.checkInfo.heading')}</b>
                        </Typography>
                        <Typography>
                            {getMissingInfoTexts(values).join(', ')}
                        </Typography>
                    </div> : null}
                </>
            }}
        </Form>
    )
}
