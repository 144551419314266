import { ResourceLanguage } from 'i18next'

const translation = {
    common: {
        atjVehicleType_car: 'Auto',
        atjVehicleType_atv: 'Maastoajoneuvo',
        atjVehicleType_motorbike: 'Moottoripyörä',
        atjVehicleType_moped: 'Mopo',
        atjVehicleType_trailer: 'Perävaunu',
        atjVehicleType_tractor: 'Traktori/työkone',
        privacyPolicy: 'Tietosuojaseloste',
        termsOfUse: "Käyttöehdot",
        registerDescription: "Rekisteriseloste",
        save: 'Tallenna',
        yes: 'Kyllä',
        no: 'En',
        ok: 'Hyväksy',
        cancel: 'Peruuta',
        campaignCode: 'Kampanjakoodi',
        no_campaign_code: 'Ei kampanjakoodia',
        customer: 'Asiakas',
        accept: 'Hyväksy',
        price: 'Hinta',
        paymentType: 'Maksutapa',
        personReserving: 'Varaaja',
        vehicle: 'Ajoneuvo',
        station: 'Asema',
        service: 'Palvelu',
        time: 'Aika',
        time_of_day: 'kello',
        time_of_day_abbr: 'klo',
        fuel: 'Käyttövoima',
        fuel_diesel: 'Diesel',
        fuel_diesel_electricity: 'Diesel/Sähkö',
        fuel_electricity: 'Sähkö',
        fuel_gas: 'Kaasu',
        fuel_no_engine: 'Moottoriton ajoneuvo',
        fuel_petrol: 'Bensiini',
        fuel_petrol_electricity: 'Bensiini/Sähkö',
        fuel_unknown: '-',
        manual_vehicle_group_car: 'Henkilöauto',
        manual_vehicle_group_van: 'Pakettiauto',
        manual_vehicle_group_camper: 'Matkailuauto',
        manual_vehicle_group_truck_light: 'Kevyt kuorma-auto nestejarruilla',
        manual_vehicle_group_truck_max12t: 'Kuorma-auto max. 12 000 kg',
        manual_vehicle_group_truck_over12t: 'Kuorma-auto yli 12 000 kg',
        manual_vehicle_group_bus_max5t: 'Linja-auto max 5000 kg',
        manual_vehicle_group_bus_over5t: 'Linja-auto yli 5000kg',
        manual_vehicle_group_moped: 'Mopo',
        manual_vehicle_group_mopedcar: 'Mopoauto',
        manual_vehicle_group_atv: 'Mönkijä',
        manual_vehicle_group_trailer_max3500: 'Perävaunu max. 3500 kg',
        manual_vehicle_group_trailer_max10t: 'Perävaunu max. 10 000 kg',
        manual_vehicle_group_trailer_over10t: 'Perävaunu yli 10 000 kg',
        registrationDate: 'Käyttöönottopäivämäärä',
        reservation: 'Ajanvaraus',
        reservationInfo: 'Ajanvarauksen info',
        vehicleGroup: 'Ajoneuvoluokka',
        vehicleType: 'Ajoneuvolaji',
        postalAddress: 'Postiosoite',
        postalCode: 'Postinumero',
        postOffice: 'Postitoimipaikka',
        week: 'Viikko',
        choose: 'Valitse',
        submit: 'Lähetä',
        back: 'Edellinen',
        next: 'Seuraava',
        continue: 'Jatka',
        firstName: 'Etunimi',
        lastName: 'Sukunimi',
        email: 'Sähköposti',
        phone: 'Puhelinnumero',
        registrationNumber: 'Rekisterinumero',
    },
    errors: {
        postOffice: 'Virheellinen postitoimipaikka',
        postalAddress: 'Virheellinen postiosoite',
        postalCode: 'Virheellinen postinumero',
        register: 'Virheellinen rekisterinumero',
        firstName: 'Virheellinen etunimi',
        lastName: 'Virheellinen sukunimi',
        email: 'Virheellinen sähköposti',
        phone: 'Virheellinen puhelinnumero',
        timeNotAvailable: 'Valitsemasi aika ei ole varattavissa',
        paymentFailed: 'Maksu epäonnistui.',
        cancelFailed: 'Varauksen peruminen epäonnistui. Ota yhteys asemalle.',
        required: 'Pakollinen tieto',
    },
    cancelledReservationContainer: {
        reservationCancelled: 'Varauksesi on peruttu.',
        cancelledParagraph: 'Varauksesi on peruttu onnistuneesti.',
        newReservation: 'Voit tehdä uuden varauksen täältä',
    },
    reservationDoneContainer: {
        thankYou: 'Kiitos varauksestasi!',
        thankYouParagraph: `Sinulle on lähetetty vahvistuksena linkki palvelukorttiin, jossa voit tarkastella varaustasi.`,
        serviceCardFromHere: 'Pääset palvelukortille myös tästä.',
        paymentFailed: 'Maksu epäonnistui!',
        paymentFailedParagraph: 'Varauksesi on kuitenkin onnistunut. Sinulle on lähetetty vahvistuksena linkki palvelukorttiin, jossa voit tarkastella varaustasi. Voit yrittää myös maksaa palvelun verkkomaksuna palvelukortin kautta.',
    },
    serviceCardContainer: {
        serviceCard: 'Palvelukortti',
        reportHelperText: 'Tästä pääset lataamaan katsastusraportin sekä kuitin.',
        feedbackSubmitOk: 'Kiitos palautteestasi!',
        createCalendarEvent: 'Luo kalenterimerkintä',
        feedbackText: 'Kirjallinen palaute',
        feedbackTitle: 'Anna palautetta',
        confirmParagraph: 'Peruuttamalla tilauksen hyväksyt peruutusehdot.',
        changeTimeTooltip: 'Ajan muuttaminen on mahdollista {{time}} ennen varauksen alkua',
        changeServiceTooltip: 'Palvelun muuttaminen on mahdollista {{time}} ennen varauksen alkua',
        checkInHelperText: 'Ilmoittautuminen aukeaa {{ time }} ennen varauksen alkua',
        cancelHelperText: 'Varauksen peruminen on mahdollista {{ time }} ennen varauksen alkua. Mikäli olet jo maksanut varauksen, maksu hyvitetään automaattisesti.',
        paymentHelperText: 'Ennakkomaksu on mahdollista {{ time }} ennen varauksen alkua',
        paymentFailed: 'Maksu epäonnistui.',
        paymentInProgress: 'Maksun käsittely on kesken',
        dateSubmitOk: 'Vaihdettu aika katsastukseen: \n{{time}}',
        serviceSubmitOk: 'Vaihdettu palvelu: {{service}}',
        changeService: 'Muuta palvelua',
        changeTime: 'Muuta aikaa',
        clock: 'Kello',
        grade: 'Arvosana',
        inspection: 'Katsastus',
        inspectReady: 'Katsastus on suoritettu.',
        refundInProgress: 'Maksupalautuksen käsittely on kesken',
        reservationDate: 'Varaamasi aika',
        reservationCancelled: 'Varauksesi on peruttu',
        checkedIn: 'Olet ilmoittautunut. Varauksesi alkaa {{time}}.',
        checkInFailed: 'Itsepalveluilmoittautuminen epäonnistui. Ilmoittaudu asemalla ennen katsastusta.',
        confirmCancelReservation: 'Haluatko peruuttaa varauksesi?',
        paymentFulfilled: 'Maksu suoritettu ({{price}} sis. alv {{vat}})',
        paymentRefunded: 'Maksu palautettu',
        prepaidPrice: 'Maksu verkossa',
        normalPrice: 'Maksu toimipisteessä',
        servicePrice: 'Palvelun hinta',
        confirmCheckIn: 'Haluatko ilmoittautua?',
        confirmCheckInContent: 'Tällä hyväksynnällä vahvistat ilmoittautumisen varattuun katsastukseen.',
        changeReservation: 'Muuta varausta',
        cancelReservation: 'Peru varaus',
        office: 'Toimipiste',
        checkIn: 'Ilmoittaudu',
        payNow: 'Maksa nyt',
        showDocuments: 'Näytä dokumentit',
        documents: 'Dokumentit',
        showOnMap: 'Näytä kartalla',
        downloadReceipt: 'Lataa kuitti',
        downloadCertificate: 'Lataa katsastustodistus'
    },
    rootContainer: {
        checkInfo: {
            heading: 'Tarkista puuttuvat/virheelliset tiedot',
            acceptTermsOfUse: 'Hyväksyn palvelun käyttöehdot',
            vehicleOwnership: 'Ajoneuvon omistaja/haltija',
        },
        inviteAddressInfo: 'Täytäthän myös osoitetiedot. Osoitetietojen avulla varmistamme, että katsastuskutsu lähetetään oikealle ajoneuvolle oikeaan aikaan. Kohdennuksessa käytetään apuna Traficomin liikenneasioiden rekisteriä.',
        onlineCheckOutInfo: 'Kun painat lopussa Varaa-nappia, sinut siirretään maksupalvelun sivulle',
        contactStation: 'Ota yhteys asemalle (puh. {{ phone }})',
        couldNotFindRegister: 'Rekisteritunnusta ei löytynyt!',
        iAccept: 'Hyväksyn palvelun ',
        inputCampaignCode: 'Syötä kampanjakoodi',
        showAll: 'Näytä kaikki',
        noAnswer: 'Ei vastausta',
        timeLeft: 'Varaus voimassa',
        showCheapest: 'Näytä edullisimmat',
        freeTimes: 'Vapaat ajat',
        noFreeTimes: 'Ei vapaita aikoja',
        specialOffer: 'Erikoistarjous',
        selected: 'Valittu',
        startingPrice: 'Alkaen',
        startingPrice_abbr: 'alk.',
        invoicePrice: 'Collector-lasku',
        prepaidPrice: 'Ennakkomaksajan etuhinta',
        reservable: 'Varattavissa',
        youAreReserving: 'Olet varaamassa',
        choosePaymentMethod: 'Valitse maksutapa',
        payNow: 'Maksa heti (Paytrail): {{price}}',
        payWithInvoice: 'Maksa laskulla: {{price}}',
        payAtStation: 'Maksa asemalla: {{price}}',
        nextInspectionBefore: 'Katsastettava viimeistään',
        nextAfterInspectionBefore: 'Jälkitarkastus viimeistään',
        ownerOrOccupant: 'Olen ajoneuvon',
        ownerOccupant: 'Omistaja / haltija',
        other: 'Joku muu',
        priceIncludesTraficomFee: 'Hintaan sisältyy Traficomin valvontamaksu {{price}}',
        allowOffers: 'Haluan lisäksi tietoa tarjouksista',
        allowSendingReminders: 'Haluan jatkossa muistutuksen, kun katsastus on ajankohtainen',
        viaTxt: 'Tekstiviestillä',
        viaEmail: 'Sähköpostilla',
        noStationsFound: 'Asemia ei löytynyt sijainnilla',
        inputRegistrationNumber: 'Syötä rekisterinumero',
        inputRegistrationInfo: 'Syötä ajoneuvon tiedot',
        chooseStation: 'Valitse asema',
        chooseService: 'Valitse palvelu',
        chooseDatetime: 'Valitse aika',
        fillInformation: 'Täytä tiedot',
        accept: 'Hyväksy',
        reserve: 'Varaa',
        vehicleInfo: 'Ajoneuvon tiedot',
        getRegisterInfo: 'Hae tiedot',
        appBarTitle: 'Katsastusasema Oy',
        registerNumber: 'Rekisterinumero',
        getStations: 'Hae asemat sijainnillani',
        registrationDate: 'Rekisteröity',
        manufacturer: 'Merkki',
        model: 'Malli',
        findDate: 'Etsi muu aika',
        cheapestPrices: 'Edullisimmat ajat',
        showMore: 'Näytä lisää',
        unregisteredVehicle: 'Rekisteröimätön ajoneuvo',
        vehicleNokConsumption: 'Lakimuutoksen ja muuttuneiden laitevaatimusten vuoksi emme juuri tällä hetkellä pysty katsastamaan autoanne.',
        vehicleNokMassM1: 'Valitettavasti emme pysty katsastamaan ajoneuvoanne.',
        cookieConsent: {
            title: 'Tämä sivusto käyttää evästeitä',
            disclaimer: 'Käytämme seuranta- ja tehokkuusevästeitä. Näiden evästeiden avulla kerätään tietoa sivustomme liikenteestä sekä käyttötavoista. Esim. nämä evästeet voivat seurata sitä, paljonko aikaa vietät sivustollamme, mikä auttaa meitä parantamaan sivustomme käyttökokemusta jatkossa. Näiden evästeiden avulla kerätty tietoa ei voida yhdistää yksittäiseen käyttäjään.',
            accept: 'Hyväksyn',
            refuse: 'Kieltäydyn',
        },
    },
}

const _export: ResourceLanguage = { translation }

export default _export
