import {
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { Field, FieldRenderProps, FormRenderProps } from 'react-final-form'
import { RegisterSubmitEvent } from '../../Containers/RootContainer'
import { ReservationState } from '../../Redux/reservationRedux'
import { registerPlateFormatter } from '../../Utils/helpers'
import InputWrapper from '../Form/Input'
import registerPlateStars from '../../Static/european_stars.svg'
import CheckboxWrapper from '../Form/Checkbox'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../Redux'
import SelectWrapper from '../Form/Select'
import { PrimaryButton } from '../Button'
import {ATJ_VEHICLE_TYPE_CAR, atjVehicleTypes} from '../../Utils/const'

const useStyles = makeStyles()((theme: Theme) => ({
    buttonText: {
        color: theme.palette.grey['100'],
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    errorText: {
        color: theme.palette.error.main,
    },
    form: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    formField: {
        '&:nth-of-type(1)': {
            marginTop: 0,
        },
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        width: 250,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    landText: {
        color: '#fff',
        fontWeight: theme.typography.fontWeightBold,
    },
    nextButton: {
        borderRadius: 24,
        fontSize: theme.typography.pxToRem(16),
        height: 40,
        width: 250,
        minWidth: 150,
        marginTop: theme.spacing(3),
    },
    registerPlate: {
        borderRadius: 8,
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #000',
        maxWidth: 350,
        boxShadow: theme.shadows[4],
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
            marginLeft: 0,
            marginRight: 0,
        },
    },
    registerPlateBlue: {
        borderBottomLeftRadius: 8,
        borderTopLeftRadius: 8,
        flex: 0.21,
        backgroundColor: '#3333FF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    registerPlateContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    registerStars: {
        width: '70%',
        height: 'auto',
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: '60%',
        },
    },
    registerTextField: {
        borderRadius: 8,
        backgroundColor: theme.palette.background.paper,
        height: '100%',
        flex: 0.8,
    },
    registerTextFieldInput: {
        color: theme.typography.caption.color,
        fontFamily: theme.typography.caption.fontFamily,
        fontSize: theme.typography.pxToRem(50),
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(30),
        },
        ['@media (max-width:360px)']: {
            fontSize: theme.typography.pxToRem(22),
        },
    },
    submitButton: {
        marginTop: theme.spacing(3),
        minWidth: 140,
        [theme.breakpoints.down('sm')]: {},
    },
    vehicleType: {
        fontSize: theme.typography.pxToRem(18),
    },
    textField: {
        width: 250,
        paddingRight: '0 !important',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}))

const RegisterFormInput = (props: FieldRenderProps<any> & Partial<RegisterFormProps>) => {
    const { meta, disabled } = props
    const { classes } = useStyles()
    const showError = !meta.active && meta.error && meta.touched

    return (
        <div className={classes.registerPlateContainer}>
            <div className={classes.registerPlate}>
                <div className={classes.registerPlateBlue}>
                    <img
                        src={registerPlateStars}
                        className={classes.registerStars}
                        alt="Rekisterikilven tähdet"
                    />
                    <Typography className={classes.landText}>FIN</Typography>
                </div>
                <InputWrapper
                    aria-label="Rekisterinumerolla haku"
                    {...props}
                    disabled={disabled}
                    disableUnderline
                    classes={{
                        root: classes.registerTextField,
                    }}
                    inputProps={{
                        className: classes.registerTextFieldInput,
                    }}
                    placeholder="ABC-123"
                />
            </div>
            {showError && (
                <Typography display="inline" className={classes.errorText}>
                    {props.meta.error}
                </Typography>
            )}
        </div>
    )
}

interface RegisterFormProps
    extends FormRenderProps<RegisterSubmitEvent, Partial<RegisterSubmitEvent>> {
    validate: any
    registerInfo?: ReservationState['registerInfo']
    validateRegisterNumber: any
    fetching: boolean
    renderSubmitRegisterPlate: () => JSX.Element
    inputId: string
}

export const RegisterForm: FC<RegisterFormProps> = ({
    inputId,
    handleSubmit,
    validate,
    values,
    renderSubmitRegisterPlate,
}) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const fuels = useSelector((state: RootState) => state.ui.settings?.fuels)
    const vehicleGroups = useSelector((state: RootState) => state.ui.settings?.vehicleGroups)
    const vehicleTypes = useSelector((state: RootState) => state.ui.settings?.booking.options.vehicleTypes)
    const allowRegistrationInspection = useSelector((state: RootState) => state.ui.settings?.booking.options.allowRegistrationInspection)

    const vehicleTypeOptions = vehicleTypes && (vehicleTypes.length > 1 || (vehicleTypes.length === 1 && vehicleTypes[0] !== ATJ_VEHICLE_TYPE_CAR)) ? atjVehicleTypes.filter(o => vehicleTypes.includes(o.id)) : null

    const classesProps = {
        classes: { root: classes.textField },
    }

    return (
        <form
            onSubmit={handleSubmit}
            className={classes.form}
            id="registerForm"
        >
            {!values.unregistered &&
                <div className={classes.container}>
                    {vehicleTypeOptions && <Field name="vehicleType" type="text" defaultValue={vehicleTypeOptions[0].id} className={classes.vehicleType}>
                        {(props) => (
                            <FormControl variant="standard" className={classes.formField}>
                                <InputLabel htmlFor="vehicle-type">
                                    {t('common.vehicleType')}
                                </InputLabel>
                                <SelectWrapper
                                    {...props}
                                    {...classesProps}
                                    inputProps={{
                                        name: 'vehicle-type',
                                        id: 'vehicle-type-select',
                                    }}
                                >
                                    {vehicleTypeOptions?.map((item: any) => (
                                        <MenuItem value={item.id} key={item.name}>
                                            {t('common.' + item.name)}
                                        </MenuItem>
                                    ))}
                                </SelectWrapper>
                            </FormControl>
                        )}
                    </Field>}
                    <Field
                        id={inputId}
                        name="registerNumber"
                        type="text"
                        validate={validate.register}
                        format={registerPlateFormatter}
                    >
                        {(props) => <RegisterFormInput {...props} disabled={values.unregistered} />}
                    </Field>
                    {renderSubmitRegisterPlate()}
                </div>
            }
            {allowRegistrationInspection &&
                <Field
                    name="unregistered"
                    defaultValue={false}
                    type="checkbox"
                >
                    {(props) => (
                        <>
                            <CheckboxWrapper
                                {...props}
                                label={t(
                                    'rootContainer.unregisteredVehicle',
                                )}
                            />
                        </>
                    )}
                </Field>
            }
            {values.unregistered &&
                <>
                    <Field
                        name="fuel"
                        type="text"
                    >
                        {(props) => (
                            <FormControl variant="standard" required className={classes.formField}>
                                <InputLabel htmlFor="fuel-select">
                                    {t('common.fuel')}
                                </InputLabel>
                                <SelectWrapper
                                    {...props}
                                    {...classesProps}
                                    inputProps={{
                                        name: 'fuel',
                                        id: 'fuel-select',
                                    }}
                                >
                                    {fuels?.map((item: any) => (
                                        <MenuItem value={item.id} key={item.id}>
                                            {t('common.fuel_' + item.name)}
                                        </MenuItem>
                                    ))}
                                </SelectWrapper>
                            </FormControl>
                        )}
                    </Field>
                    <Field name="vehicleGroup" type="text">
                        {(props) => (
                            <FormControl variant="standard" required className={classes.formField}>
                                <InputLabel htmlFor="vehicle-group">
                                    {t('common.vehicleGroup')}
                                </InputLabel>
                                <SelectWrapper
                                    {...props}
                                    {...classesProps}
                                    inputProps={{
                                        name: 'vehicle-group',
                                        id: 'vehicle-group-select',
                                    }}
                                >
                                    {vehicleGroups?.map((item: any) => (
                                        <MenuItem value={item.name} key={item.name}>
                                            {t('common.' + item.name)}
                                        </MenuItem>
                                    ))}
                                </SelectWrapper>
                            </FormControl>
                        )}
                    </Field>
                    <PrimaryButton
                        id="next-vehicle-new"
                        type="submit"
                        className={classes.nextButton}
                        disabled={!values.fuel || !values.vehicleGroup}
                    >
                        {t('common.next')}
                    </PrimaryButton>
                </>
            }
        </form >
    )
}
