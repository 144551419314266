import { createReducer, createActions } from 'reduxsauce'
import { CommonReducer } from '../Utils/reduxHelpers'
import { Action } from 'redux'
import { ReducerTypes } from './utils'

type ActionTypes = {
    CREATE_PAYMENT: string
    CREATE_PAYMENT_FAILURE: string
}

const actions = {
    createPayment: ['token', 'quick'],
    createPaymentFailure: null,
}

type AnyCreators = {
    [key in keyof typeof actions]: (...params: any[]) => Action<any>
}

// Override AnyCreators with strong types
interface Creators extends AnyCreators {
    createPayment: (token: string, quick?: boolean) => Action<any>
    handlePaymentResult: (result: string, query: string) => Action<any>
}

const { Types, Creators } = createActions<ActionTypes, Creators>(actions)

export const PaymentTypes = Types
export default Creators

export interface PaymentState {
    fetching: {
        payment: boolean
    }
}

export const INITIAL_STATE: PaymentState = {
    fetching: {
        payment: false,
    },
}

type CommonFetchingType = keyof PaymentState['fetching']

const commonFetching = (
    type: CommonFetchingType,
): CommonReducer<PaymentState> => (state: PaymentState) => ({
    ...state,
    fetching: {
        ...state.fetching,
        [type]: true,
    },
})

const commonFailure = (
    type: CommonFetchingType,
): CommonReducer<PaymentState> => (state: PaymentState) => ({
    ...state,
    fetching: {
        ...state.fetching,
        [type]: false,
    },
})

const PAYMENT = 'payment'

export const createPayment: CommonReducer<PaymentState> = commonFetching(
    PAYMENT,
)
export const createPaymentFailure: CommonReducer<PaymentState> = commonFailure(
    PAYMENT,
)

type CheckInReducerTypes = ReducerTypes<PaymentState, ActionTypes>

export const reducerTypes: CheckInReducerTypes = {
    [Types.CREATE_PAYMENT]: createPayment,
    [Types.CREATE_PAYMENT_FAILURE]: createPaymentFailure,
}

export const reducer = createReducer<PaymentState, Action<CheckInReducerTypes>>(
    INITIAL_STATE,
    reducerTypes,
)
