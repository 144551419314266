import 'url-search-params-polyfill'
import React, {useEffect, useLayoutEffect, useRef, useState, RefObject} from 'react'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { a11yClick, a11yEventCall } from '../Utils/a11yHelpers'
import {
    Backdrop,
    CircularProgress,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Paper,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import { Header } from '../Components/Header'
import { PrimaryButton, SecondaryButton } from '../Components/Button'
import ReservationActions from '../Redux/reservationRedux'
import UIActions from '../Redux/uiRedux'
import {
    useSettings,
    useMobile,
    usePrevious,
    useAppThemeOptions,
} from '../Utils/hooks'
import {getLocale, registerPlateFormatter, validate} from '../Utils/helpers'
import { RootState } from '../Redux'
import RootContent from '../Components/RootContainer/Content'
import { HeaderImage } from '../Components/RootContainer/HeaderImage'
import { ThemeType } from '../Utils/themes'
import {InspectService, InspectStation, PrefillValues} from '../interfaces'
import { DateTime } from 'luxon'
import { HeaderStepper } from '../Components/RootContainer/HeaderStepper'
import { Helmet } from 'react-helmet-async'
import { Timer } from '../Components/RootContainer/Timer'
import { Footer } from '../Components/Footer'
import PaymentActions from '../Redux/paymentRedux'
import { CallbackArray } from '../Utils/types'
import {GTMEvent, NEW_REGISTRATION, VEHICLE_SEARCH_TYPE_DORIS} from '../Utils/const'
import { InfoSection } from '../Components/InfoSection'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import {ErrorContainer} from './ErrorContainer'
import { getCookieConsent, storeCookieConsent } from "../Utils/helpers";

interface RootContainerProps extends RouteComponentProps {
    themeName: string
    embedded: boolean
    setGtmIds: (string) => void
    scrollContainerRef?: RefObject<HTMLDivElement>
    prefill?: PrefillValues
}

const TRANSITION_DURATION = 300

const registerPlateRegex = /^[0-9a-zA-ZÄÖÅäöå]{1,4}-[0-9a-zA-ZÄÖÅäöå]{1,4}$/gis

interface StyleProps extends ThemeType {
}

const useLayoutStyles = makeStyles<Partial<StyleProps>>()((theme: Theme, styleProps: StyleProps) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        zIndex: 0,
        [theme.breakpoints.down('sm')]: {
            overflowX: 'hidden',
        },
        '&:before': {
            content: '""',
            backgroundImage: styleProps.backgroundImage ? `url(${styleProps.backgroundImage})` : undefined,
            backgroundRepeat: 'repeat-y',
            backgroundSize: 'cover',
            position: 'fixed',
            opacity: styleProps.backgroundImageOverlay ? undefined : (styleProps.backgroundOpacity ?? 0.25),
            mixBlendMode: styleProps.backgroundImageOverlay ? undefined : styleProps.backgroundBlendMode,
            width: '100vw',
            height: '100vh',
            zIndex: -10,
            filter: styleProps.backgroundFilter,
        },
    },
    backgroundOverlay: styleProps.backgroundImageOverlay ? {
        backgroundColor: theme.palette.background.default,
        opacity: (styleProps.backgroundOpacity ?? 0.25),
        mixBlendMode: styleProps.backgroundBlendMode,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: -9,
    } : {
        display: 'none',
    }
}))

const useStyles = makeStyles<ThemeType>()((theme: Theme, options: ThemeType) => ({
    backdrop: {
        zIndex: 1000,
    },
    appBar: {
        backgroundColor: theme.palette.background.paper,
    },
    checkboxText: {
        color: '#fff',
    },
    container: {
        padding: 0,
        maxWidth: 1200,
        top: 200,
    },
    containerEmbedded: {
        backgroundColor: options.backgroundColor ?? 'white',
        padding: 0,
        maxWidth: 1200,
        display: 'flex',
    },
    contentCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    embeddedPaper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        padding: theme.spacing(2),
        border: 0,
        borderRadius: 0,
        backgroundColor: options.backgroundColor ?? 'white',
    },
    activeLabel: {
        fontSize: theme.typography.pxToRem(20),
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    label: {
        fontSize: theme.typography.pxToRem(20),
        marginLeft: theme.spacing(1),
        display: 'inline',
        color: theme.palette.grey[600],
        fontWeight: 'bold',
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    labelValue: {
        color: options.stepLabelTextColor ?? theme.palette.secondary.main,
    },
    paper: {
        backgroundColor: options.transparentPaper ? 'rgba(0,0,0,0)' : theme.palette.background.paper,
        borderRadius: 24,
        border: options.transparentPaper ? 0 : 1,
        margin: theme.spacing(3),
        marginTop: theme.spacing(2),
        padding: theme.spacing(3, 4),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0),
            padding: theme.spacing(1, 1, 3),
        },
    },
    postFinalReservationForm: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    step: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
        },
        boxShadow: theme.shadows[6],
        outline: 'none',
        position: 'relative',
        borderRadius: 24,
        zIndex: 0,
        '&:before': {
            backgroundColor: '#fff',
            content: '""',
            position: 'absolute',
            borderRadius: 24,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
        },
    },
    stepContent: {
        border: 0,
        margin: 0,
        padding: 0,
    },
    stepLabel: {
        padding: 0,
    },
    stepperContainer: {
        backgroundColor: options.transparentPaper ? 'rgba(0,0,0,0)' : theme.palette.background.paper,
        flexGrow: 1,
        display: 'flex',
        padding: 0,
        justifyContent: 'center',
    },
    termsOfUseContainer: {
        color: '#fff',
        display: 'flex',
    },
    termsOfUseText: {
        marginTop: theme.spacing(2),
        color: '#fff',
        fontSize: theme.typography.pxToRem(14),
    },
    title: {
        color: theme.palette.primary.main,
        fontFamily: theme.typography.caption.fontFamily,
    },
    dialogSecondaryButton: {
        margin: 0,
        border: 0,
    },
}))

export interface RegisterSubmitEvent {
    registerNumber: string
    unregistered: boolean
    fuel?: string
    vehicleClass?: string
    vehicleGroup?: string
    vehicleType?: number
    searchType?: number
}

export interface RegisterInfoSubmitEvent {
    fuel: string
    vehicleGroup: string
    registrationNumber: string
}

export interface InfoSubmitEvent {
    acceptTermsOfUse: boolean
    firstName: string
    lastName: string
    language: string
    email: string
    phone: string
    postOffice: string
    postalCode: string
    reminder: {
        emailMessage: boolean
        txtMessage: boolean
    }
    offer: {
        emailMessage: boolean
        txtMessage: boolean
    }
    owner?: 'owner' | 'other' | 'noAnswer'
    postalAddress: string
    payment?: string
}

export interface SearchSubmitEvent {
    search: string
}

interface Fields {
    registerNumber: string
    station?: InspectStation
    service?: InspectService
}

const RootContainerLayout: FC<RootContainerProps> = (props) => {
    const themeOptions = useAppThemeOptions()
    const { classes } = useLayoutStyles(themeOptions)

    return (
        <div className={classes.root}>
            <div className={classes.backgroundOverlay}></div>
            <RootContainerContent {...props} />
        </div>
    )
}

const RootContainerContent: FC<RootContainerProps> = ({
    themeName,
    setGtmIds,
    prefill,
    scrollContainerRef,
    ...props
}) => {
    const language = getLocale()
    const dispatch = useDispatch()
    const chainIdUrl = (props.match.params as any).chainId
    const stationIdUrl = (props.match.params as any).stationId
    const query = new URLSearchParams(location.search)
    const queryCampaignCodes = query.has('c') ? query.get('c') : null
    const queryRegistration = query.has('r') ? registerPlateFormatter(query.get('r')) : null
    const queryStationId = query.has('s') ? Number.parseInt(query.get('s')) : null
    const queryFuel = query.get('f')
    const queryVehicleGroup = query.get('g')
    const {
        bookingEnabled,
        atjEnabled,
        isEmbedded,
        chainId,
        chainLogo,
        chainName,
        chainInfoText,
        fuels,
        inspectDate,
        privacyPolicyUrl,
        registerDescriptionUrl,
        registerInfo,
        reservation,
        reservationFetching,
        service,
        station,
        stations,
        stationLogo,
        stationName,
        wwwAddress,
        termsOfUseUrl,
        uiFetching,
        userInformation,
        token,
        vehicleGroups,
    }: any = useSelector(
        ({ reservation, ui }: RootState): any => ({
            bookingEnabled: ui.settings?.booking?.enabled,
            atjEnabled: ui.settings?.booking?.atjEnabled,
            fuels: ui.settings?.fuels || [],
            isEmbedded: ui.options.embedded || props.embedded,
            chainId: ui.settings?.chain?.id || chainIdUrl,
            chainLogo: ui.settings?.chain?.logo?.data,
            chainName: ui.settings?.chain?.name,
            chainInfoText: ui.chainInfoText,
            inspectDate: reservation.inspectDate,
            registerInfo: reservation.registerInfo,
            reservation: reservation.reservation,
            reservationFetching: reservation.fetching,
            service: reservation.service,
            station: reservation.station,
            stations: reservation.stations,
            stationName: ui.settings?.station?.name,
            stationLogo: ui.settings?.station?.logo?.data,
            uiFetching: ui.fetching,
            userInformation: reservation.userInformation,
            token: reservation.reservation?.token,
            vehicleGroups: ui.settings?.vehicleGroups || [],
            privacyPolicyUrl: ui.settings?.booking?.options?.privacyPolicyUrl,
            registerDescriptionUrl: ui.settings?.booking?.options?.registerDescriptionUrl,
            termsOfUseUrl: ui.settings?.booking?.options?.termsOfUseUrl,
            wwwAddress: ui.settings?.station?.www || ui.settings?.chain?.www || undefined,
        }),
    )

    const chainGtmId = useSelector((state: RootState) => state.ui.chainGtmId)
    const chainGA = useSelector((state: RootState) => state.ui.chainGoogleAnalyticsId)
    const stationGA = useSelector((state: RootState) => state.ui.settings?.booking?.options?.googleAnalyticsId)
    const sendDataToGTM = useGTMDispatch()

    const idealGA = 'G-ZD4QPQ9KNX'

    const [cookieConsent, setCookieConsent] = useState<string|null>(getCookieConsent())

    const handleCookieConsent = (value: string) => {
        storeCookieConsent(value)
        setCookieConsent(value)
    }

    useEffect(() => {
        if (cookieConsent === "1") {
            setGtmIds(chainGtmId)
        }
    }, [chainGtmId, setGtmIds, cookieConsent])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (chainGA) sendDataToGTM({ event: GTMEvent.Initialization, chainGoogleAnalyticsId: chainGA })
            if (stationGA) sendDataToGTM({ event: GTMEvent.Initialization, stationGoogleAnalyticsId: stationGA })
            if (idealGA) sendDataToGTM({ event: GTMEvent.Initialization, idealInspectGoogleAnalyticsId: idealGA })
        }, 200)
        return () => clearTimeout(timeout)
    }, [stationGA, chainGA, idealGA, sendDataToGTM, cookieConsent])

    const oneStationChain = stations && stations.length === 1

    const displayOne = false
    const themeOptions = useAppThemeOptions()
    const { classes } = useStyles(themeOptions)
    const { t } = useTranslation()
    const isMobile = useMobile()
    const showStepData = !isMobile

    const [activeStep, setActiveStep] = useState<number>(0)
    const [fields, setFields] = useState<Fields>({
        registerNumber: '',
        service: undefined,
        station: undefined,
    })

    const previousStep = usePrevious(activeStep)
    const stepRef = useRef<HTMLElement[]>([])
    const headerStepperRef = useRef<HTMLElement | null>(null)
    const headerNavigationRef = useRef<HTMLElement | null>(null)
    const headerStepperHeight = isMobile
        ? 0
        : headerStepperRef.current?.clientHeight ?? 0
    const headerNavigationHeight =
        headerNavigationRef.current?.clientHeight ?? 0
    const headerHeight = headerStepperHeight + headerNavigationHeight
    const topSpace = headerHeight + 12
    const reservationEndRef = useRef<DateTime>()
    useSettings(chainIdUrl, stationIdUrl, props.history, props.embedded)
    const getSteps = () => {
        const firstStep = oneStationChain ? stations[0].name : 'rootContainer.chooseStation'
        const secondStep = atjEnabled
            ? 'rootContainer.inputRegistrationNumber'
            : 'rootContainer.inputRegistrationInfo'
        return [
            firstStep,
            secondStep,
            'rootContainer.chooseService',
            'rootContainer.chooseDatetime',
            'rootContainer.fillInformation',
        ]
    }

    useEffect(() => {
        if (typeof chainId === 'number') getAllStations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId])

    const getTopSteps = () => {
        let [
            firstStep,
            secondStep,
            thirdStep,
            fourthStep,
            fifthStep,
        ] = getSteps()
        const step = activeStep || 0
        if (fields.station && step >= 0) firstStep = getLabelValue(0)
        if (registerInfo && step >= 1) secondStep = getLabelValue(1)
        if (service && step >= 2) thirdStep = getLabelValue(2)
        if (inspectDate && step >= 3) fourthStep = getLabelValue(3)
        if (userInformation && step >= 4)
            fifthStep = getLabelValue(4)
        return [firstStep, secondStep, thirdStep, fourthStep, fifthStep]
    }

    const requestLocation = (callback: (position: any) => void): void => {
        navigator.geolocation.getCurrentPosition(callback, console.error)
    }

    const getAllStations = (callback?: () => void) => {
        dispatch(ReservationActions.getStations(chainId, stationIdUrl, callback ? [callback] : undefined))
    }

    const getStationsByLocation = (callback?: () => void): void => {
        const callbacks = callback ? [callback] : undefined
        requestLocation((position: any) => {
            const location = position.coords
            dispatch(
                ReservationActions.getFilteredStations(
                    chainId,
                    location,
                    undefined,
                    callbacks,
                ),
            )
        })
    }

    const getSettings = (chainId: number, stationId?: number | null, callbacks?: CallbackArray) =>
        dispatch(UIActions.getSettings(chainId as any, stationId as any, props.history, props.embedded, callbacks))

    useLayoutEffect(() => {
        const executeScroll = (index: number): void => {
            // Without timeout scrolling to animating ref does not work correctly
            setTimeout(() => {
                if (stepRef.current[index]) {
                    const scrollContainer = scrollContainerRef?.current ?? window
                    const topOffset = stepRef.current[index].offsetTop
                    const top = topOffset - topSpace
                    try {
                        scrollContainer.scroll({top, behavior: 'smooth'})
                    } catch (e) {
                        try {
                            scrollContainer.scroll(0, top)
                        } catch (e) {
                            try {
                                scrollContainer.scrollTo(0, top)
                            } catch (e) {

                            }
                        }
                    }
                }
            }, TRANSITION_DURATION)
        }

        // Prevent scroll when initializing
        if (activeStep === 0) return
        if (
            activeStep > Number(previousStep ?? 0) ||
            (activeStep === 0 && previousStep === undefined)
        ) {
            return executeScroll(activeStep)
        }
    }, [activeStep, previousStep, topSpace, scrollContainerRef])

    const validateRegisterNumber = (str: string) => {
        return validate(registerPlateFormatter(str), registerPlateRegex)
    }

    const selectStep = (index: number) => {
        a11yEventCall(undefined, () => setActiveStep(index))
    }

    const steps = getSteps()

    const handleSubmitRegisterPlate = (
        { registerNumber, vehicleType, searchType, vehicleClass, vehicleGroup, fuel }: RegisterSubmitEvent,
        callback?: () => void | CallbackArray,
    ) => {
        const callbacks = callback && Array.isArray(callback) ? callback : (callback ? [callback] : undefined)

        dispatch(
            ReservationActions.getRegisterInfo(
                registerNumber?.toUpperCase()?.trim() ?? '',
                vehicleType,
                vehicleClass,
                vehicleGroup,
                fuel,
                searchType,
                fields.station?.id,
                callbacks,
            ),
        )
    }

    const handleSubmitRegisterInfo = (
        {
            registrationNumber,
            fuel,
            vehicleGroup,
        }: RegisterInfoSubmitEvent,
        campaignCode?: string,
    ) => {
        const vehicleGroupInfo = vehicleGroups.find(
            (item: any) => item.name === vehicleGroup,
        )
        if (registrationNumber && fuel && vehicleGroup) {
            const registerInfo = {
                registrationNumber: registrationNumber?.toUpperCase()?.trim() ?? '',
                engineType: fuels.find(
                    (item: any) => item.id === fuel,
                ),
                vehicleGroup: vehicleGroupInfo,
                vehicleClass: {
                    id: vehicleGroupInfo?.vehicleClass
                },
            }
            const registerPlate = {
                registerNumber: registerInfo.registrationNumber,
                searchType: VEHICLE_SEARCH_TYPE_DORIS,
                fuel,
                vehicleClass: vehicleGroupInfo?.vehicleClass,
                vehicleGroup: vehicleGroupInfo?.id,
                vehicleType: null,
                unregistered: false,
            }
            dispatch(
                ReservationActions.setRegisterInfo(registerInfo, [() => handleSubmitRegisterPlate(registerPlate, () => handleGetServices(nextStep, campaignCode))]),
            )
        } else if (fuel && vehicleGroup) {
            dispatch(
                ReservationActions.setRegisterInfo({
                    registrationNumber: NEW_REGISTRATION,
                    engineType: fuels.find(
                        (item: any) => item.id === fuel,
                    ),
                    vehicleGroup: vehicleGroupInfo,
                    vehicleClass: {
                        id: vehicleGroupInfo?.vehicleClass
                    }
                }, [() => handleGetServices(undefined, campaignCode), nextStep]),
            )
        }
    }

    const handleSubmitSearch = (
        { search }: SearchSubmitEvent,
        callback: () => void,
    ) => {
        const callbacks = [callback]
        dispatch(
            ReservationActions.getFilteredStations(chainId, null, search, callbacks),
        )
    }

    const nextStep = (e?: any, callback?: () => void) => {
        e?.stopPropagation()
        setActiveStep((prev) => {
            if (prev !== undefined) return prev + 1
            return 1
        })
        if (callback) callback()
    }

    const getInputId = (index: number) => {
        switch (index) {
            case 0:
                return 'station'
            case 1:
                return 'registerInfo'
            case 2:
                return 'service'
            case 3:
                return 'datetime'
            case 4:
                return 'information'
            default:
                return ''
        }
    }

    const getTitle = () => {
        const station = stationIdUrl ? stationName : ''
        if (station) return `${station} – ${t('common.reservation')}`
        const chain = chainName || stationName || ''
        if (!chain) return ''
        return `${chain} – ${t('common.reservation')}`
    }

    const handleGetBestPrices = (
        service: InspectService,
        campaign: string | null,
        dateStart: string | undefined,
        dateEnd: string | undefined,
        callback?: () => void,
    ) => {
        if (dateStart && dateEnd)
            dispatch(
                ReservationActions.getBestPrices(
                    chainId,
                    service,
                    campaign,
                    DateTime.fromISO(dateStart).toISODate(),
                    DateTime.fromISO(dateEnd).toISODate(),
                    callback ? [callback] : undefined,
                ),
            )
        else
            dispatch(
                ReservationActions.getBestPrices(
                    chainId,
                    service,
                    campaign,
                    undefined,
                    undefined,
                    callback ? [callback] : undefined,
                ),
            )
    }

    const handleSetStation = (station: InspectStation) => {
        if (! oneStationChain && station === fields.station)
            setFields((prev) => ({ ...prev, station: undefined }))
        else setFields((prev) => ({ ...prev, station }))
    }
    const handleSetService = (service: InspectService) => {
        dispatch(ReservationActions.resetDateTimes())
        setFields((prev) => ({ ...prev, service }))
    }

    const handleGetServices = (
        callback?: () => void | CallbackArray,
        campaign?: string | null,
    ) => {
        const callbacks = callback && Array.isArray(callback) ? callback : (callback ? [callback] : undefined)
        dispatch(ReservationActions.getServices(chainId, fields.station, campaign, callbacks))
    }

    const handleSubmitInfo = (event: any) => {
        dispatch(ReservationActions.setInformation(event))
    }

    useEffect(() => {
        if (userInformation?.acceptTermsOfUse && userInformation?.payment) {
            postFinalReservation(userInformation.payment)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation?.payment, userInformation?.submitSeed])

    const postFinalReservation = (paymentType: string) => {
        const callbacks = []
        if (!reservationFetching.finalReservation) {
            if (paymentType === 'payNow') {
                callbacks.push(() => dispatch(PaymentActions.createPayment(token, true)))
            } else {
                callbacks.push(() => props.history.push(`${props.match.url}/thank-you?token=${token}`))
            }
            callbacks.push(() => setActiveStep(0))
            if (chainGA) callbacks.push(() => sendDataToGTM({ event: GTMEvent.Reservation, chainGoogleAnalyticsId: chainGA }))
            if (stationGA) callbacks.push(() => sendDataToGTM({ event: GTMEvent.Reservation, stationGoogleAnalyticsId: stationGA }))
            if (idealGA) callbacks.push(() => sendDataToGTM({ event: GTMEvent.Reservation, idealInspectGoogleAnalyticsId: idealGA }))
            dispatch(ReservationActions.postFinalReservation(callbacks))
        }
    }

    const setReservationCampaignCode = (
        campaignCode: string,
        callback: (() => void) | CallbackArray,
    ) => {
        let callbackArray: any
        if (Array.isArray(callback)) {
            callbackArray = callback
        } else {
            callbackArray = [callback]
        }
        dispatch(ReservationActions.setCampaignCode(campaignCode?.toUpperCase(), callbackArray))
    }

    useEffect(() => {
        if (queryCampaignCodes?.length > 0) {
            const code: string|null = queryCampaignCodes.split(',')[0] || null
            setReservationCampaignCode(code, [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setInspectDate = (
        inspectDate: DateTime | null,
        campaignCode: string,
        callback: () => void | CallbackArray,
    ) => () => {
        let callbackArray: any
        if (Array.isArray(callback)) {
            callbackArray = callback
        } else {
            callbackArray = [callback]
        }
        if (inspectDate && chainId) {
            dispatch(ReservationActions.setCampaignCode(campaignCode))
            dispatch(ReservationActions.setInspectDate(inspectDate, campaignCode || null, chainId, callbackArray))
        }
    }

    const getInspectDateTimes = (
        dateStart: DateTime | null,
        dateEnd: DateTime | null,
        campaign: string | null,
        callback?: () => void,
    ) => {
        let callbacks: any
        if (callback) callbacks = [callback]
        if (dateStart)
            dispatch(ReservationActions.getDateTimes(chainId, campaign, dateStart, dateEnd, callbacks))
    }

    const getLabelValue = (index: number): string => {
        switch (index) {
            case 0:
                return fields.station?.name || ''
            case 1:
                return registerInfo?.registrationNumber === NEW_REGISTRATION ? t('rootContainer.unregisteredVehicle') : registerInfo?.registrationNumber || ''
            case 2:
                return service?.name || ''
            case 3:
                return (
                    inspectDate?.toFormat('dd.MM.yyyy HH:mm') || ''
                )
            case 4:
                return userInformation
                    ? `${userInformation.firstName} ${userInformation.lastName}`
                    : ''
        }
        return ''
    }

    const canGoToIndex = (index: number) => {
        switch (index) {
            case 0:
                return true
            case 1: {
                if (fields.station) return true
                return false
            }
            case 2: {
                if (registerInfo && fields.station) return true
                return false
            }
            case 3: {
                if (
                    registerInfo &&
                    station &&
                    fields.service
                )
                    return true
                return false
            }
            case 4: {
                if (
                    registerInfo &&
                    station &&
                    service &&
                    reservation
                )
                    return true
                return false
            }
        }
    }

    const renderStepper = () => (
        <Container
            disableGutters
            classes={{
                root: isEmbedded ? classes.containerEmbedded : classes.container,
            }}
        >
            <Paper
                variant="outlined"
                classes={
                    isEmbedded
                        ? { root: classes.embeddedPaper }
                        : { root: classes.paper }
                }
                component="main"
            >
                {chainInfoText ?
                    <Step classes={{ root: classes.step }}>
                        <InfoSection text={chainInfoText}/>
                    </Step>
                    : null
                }
                <Stepper
                    className={classes.stepperContainer}
                    activeStep={activeStep}
                    orientation="vertical"
                    connector={null as any}
                >
                    {steps.map((label, index) => {
                        const inputId = getInputId(index)
                        return (
                            <Step
                                expanded
                                {...a11yClick(() =>
                                    index !== activeStep
                                        ? canGoToIndex(index + 1) &&
                                        selectStep(index)
                                        : undefined,
                                )}
                                role="button"
                                key={label}
                                tabIndex={0}
                                ref={(ref: HTMLElement) => {
                                    stepRef.current[index] = ref
                                }}
                                classes={{ root: classes.step }}
                            >
                                <StepLabel

                                    optional={
                                        showStepData && (
                                            <Typography
                                                component="span"
                                                className={
                                                    classes.labelValue
                                                }
                                                noWrap
                                            >
                                                {getLabelValue(index)}
                                            </Typography>
                                        )
                                    }
                                    classes={{
                                        root: classes.stepLabel,
                                        label: classes.label,
                                        labelContainer:
                                            classes.labelContainer,
                                    }}
                                >
                                    {inputId ? (
                                        <label
                                            className={
                                                index <= (activeStep || 0)
                                                    ? classes.activeLabel
                                                    : classes.label
                                            }
                                            htmlFor={inputId}
                                        >
                                            {t(label)}
                                        </label>
                                    ) : (
                                        t(label)
                                    )}
                                </StepLabel>
                                <StepContent
                                    transitionDuration={TRANSITION_DURATION}
                                    classes={{ root: classes.stepContent }}
                                >
                                    <RootContent
                                        stationIdUrl={stationIdUrl}
                                        getAllStations={getAllStations}
                                        canGoToIndex={canGoToIndex}
                                        onGetBestPrices={
                                            handleGetBestPrices
                                        }
                                        onSubmitInfo={handleSubmitInfo}
                                        handleGetServices={
                                            handleGetServices
                                        }
                                        onSubmitSearch={handleSubmitSearch}
                                        setService={handleSetService}
                                        activeService={fields.service}
                                        setStation={handleSetStation}
                                        getStationsByLocation={
                                            getStationsByLocation
                                        }
                                        inputId={inputId}
                                        goNext={nextStep}
                                        goToStep={selectStep}
                                        activeStep={activeStep}
                                        activeStation={fields.station}
                                        onSubmitRegisterPlate={
                                            handleSubmitRegisterPlate
                                        }
                                        onSubmitRegisterInfo={
                                            handleSubmitRegisterInfo
                                        }
                                        step={index}
                                        display={
                                            displayOne
                                                ? index ===
                                                (activeStep || 0)
                                                : index <= (activeStep || 0)
                                        }
                                        prefill={{
                                            enabled: (Boolean) (prefill || queryRegistration || queryStationId),
                                            stationId: queryStationId,
                                            vehicle: {
                                                registerNumber: queryRegistration,
                                                fuel: queryFuel,
                                                vehicleGroup: queryVehicleGroup,
                                            },
                                            ...prefill,
                                        }}
                                        validateRegisterNumber={
                                            validateRegisterNumber
                                        }
                                        getInspectDateTimes={
                                            getInspectDateTimes
                                        }
                                        setInspectDate={setInspectDate}
                                        setReservationCampaignCode={setReservationCampaignCode}
                                        getSettings={getSettings}
                                    />
                                </StepContent>
                            </Step>
                        )
                    })}
                </Stepper>
            </Paper>
        </Container>
    )

    const renderTimer = () => (
        <Timer
            display={!!reservation}
            reservation={reservation}
            setActiveStep={setActiveStep}
            confirmBefore={reservation?.confirmBefore}
            confirmIn={reservation?.confirmIn}
            reservationEndRef={reservationEndRef}
        />
    )

    if (bookingEnabled === undefined) {
        return (
            <Backdrop className={classes.backdrop} open={true} style={{ backgroundColor: 'white' }}>
                <CircularProgress size={48} color="primary" />
            </Backdrop>
        )
    } else if (! bookingEnabled) {
        return (<ErrorContainer {...props}/>)
    }

    return (
        <>
            <Helmet>
                <html lang={language} />
                <title>{getTitle()}</title>
            </Helmet>
            <Header
                ref={headerNavigationRef}
                title={getTitle()}
                logo={stationLogo || chainLogo}
                wwwAddress={wwwAddress}
                display={!isEmbedded}
                renderTimer={renderTimer}
            />
            <HeaderImage
                themeName={themeName}
                display={!isEmbedded}
                hasBackground={!!themeOptions?.backgroundImage}
            />
            <HeaderStepper
                ref={headerStepperRef}
                display={!isEmbedded}
                activeStep={activeStep ?? 0}
                steps={getTopSteps()}
                canGoToIndex={canGoToIndex}
                selectStep={selectStep}
            />
            {renderStepper()}
            <Footer embedded={isEmbedded} privacyPolicyUrl={privacyPolicyUrl} registerDescriptionUrl={registerDescriptionUrl} termsOfUseUrl={termsOfUseUrl} />
            <Backdrop className={classes.backdrop} open={uiFetching}>
                <CircularProgress size={48} color="primary" />
            </Backdrop>
            <Dialog
                open={cookieConsent === null}
                onClose={undefined}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('rootContainer.cookieConsent.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('rootContainer.cookieConsent.disclaimer')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <PrimaryButton onClick={() => handleCookieConsent('1')}>
                        {t('rootContainer.cookieConsent.accept')}
                    </PrimaryButton>
                    <SecondaryButton className={classes.dialogSecondaryButton} onClick={() => handleCookieConsent('0')}>
                        {t('rootContainer.cookieConsent.refuse')}
                    </SecondaryButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export const RootContainer = RootContainerLayout
