import {
    FormControl,
    InputLabel,
    MenuItem,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { Field, FormRenderProps } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { RegisterInfoSubmitEvent } from '../../Containers/RootContainer'
import SelectWrapper from '../Form/Select'
import TextFieldWrapper from '../Form/TextField'
import {PrimaryButton} from '../Button'
import {registerPlateFormatter} from '../../Utils/helpers'

interface ManualRegisterFormProps
    extends FormRenderProps<
        RegisterInfoSubmitEvent,
        Partial<RegisterInfoSubmitEvent>
    > {
    inputId: string
    fetching: boolean
    fuels: any
    vehicleGroups: any
}

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: theme.shadows[4],
        padding: theme.spacing(3),
        flex: 1,
        borderRadius: 8,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        marginTop: 0,
    },
    formField: {
        '&:nth-of-type(1)': {
            marginTop: 0,
        },
        marginTop: theme.spacing(1),
        width: 250,
        [theme.breakpoints.down('sm')]: {
            width: 200,
        },
    },
    submitButton: {
        borderRadius: 24,
        marginTop: theme.spacing(3),
        width: 250,
    },
    textField: {
        width: 250,
        paddingRight: '0 !important',
        [theme.breakpoints.down('sm')]: {
            width: 200,
        },
    },
    buttonText: {
        fontWeight: 'bold',
        color: theme.palette.grey['100'],
    },
    datePicker: {
        margin: 0,
        width: 250,
        [theme.breakpoints.down('sm')]: {
            width: 200,
        },
    },
    contentEnd: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flex: 1,
        alignSelf: 'stretch',
    },
}))

export const ManualRegisterForm: FC<ManualRegisterFormProps> = ({
    inputId,
    fetching,
    values,
    fuels,
    vehicleGroups,
    hasValidationErrors,
    handleSubmit,
}) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const classesProps = {
        classes: { root: classes.textField },
    }
    const { fuel, vehicleGroup, registrationNumber } = values
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.container}>
                <Field
                    name="registrationNumber"
                    type="text"
                    id={inputId}
                    format={registerPlateFormatter}
                    className={classes.formField}
                >
                    {(props) => (
                        <TextFieldWrapper
                            {...props}
                            {...classesProps}
                            variant="standard"
                            label={t('common.registrationNumber')}
                            placeholder={t('common.registrationNumber')}
                        />
                    )}
                </Field>
                <Field name="fuel" type="text">
                    {(props) => (
                        <FormControl variant="standard" className={classes.formField}>
                            <InputLabel htmlFor="fuel-select">
                                {t('common.fuel')}
                            </InputLabel>
                            <SelectWrapper
                                {...props}
                                {...classesProps}
                                inputProps={{
                                    name: 'fuel',
                                    id: 'fuel-select',
                                }}
                            >
                                {fuels?.map((item: any) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {t('common.fuel_' + item.name)}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </FormControl>
                    )}
                </Field>
                <Field name="vehicleGroup" type="text">
                    {(props) => (
                        <FormControl variant="standard" className={classes.formField}>
                            <InputLabel htmlFor="vehicle-group">
                                {t('common.vehicleGroup')}
                            </InputLabel>
                            <SelectWrapper
                                {...props}
                                {...classesProps}
                                inputProps={{
                                    name: 'vehicle-group',
                                    id: 'vehicle-group-select',
                                }}
                            >
                                {vehicleGroups?.map((item: any) => (
                                    <MenuItem value={item.name} key={`${item.name}_${item.id}`}>
                                        {t('common.' + item.name)}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </FormControl>
                    )}
                </Field>
            </div>
            <div className={classes.contentEnd}>
                <PrimaryButton
                    id="next-vehicle-manual"
                    className={classes.submitButton}
                    disabled={
                        !(
                            !hasValidationErrors &&
                            fuel &&
                            registrationNumber &&
                            vehicleGroup
                        )
                    }
                    fetching={fetching}
                    type="submit"
                >
                    {t('common.next')}
                </PrimaryButton>
            </div>
        </form>
    )
}
