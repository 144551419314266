import 'url-search-params-polyfill'
import {Backdrop, CircularProgress, Link, Typography} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { RootState } from '../Redux'
import { useTranslation } from 'react-i18next'
import { AccordionItem } from '../Components/ServiceCardContainer/AccordionItem'
import { Header } from '../Components/Header'
import { Helmet } from 'react-helmet-async'
import { useSettings, useSettingsByToken } from '../Utils/hooks'
import {getLocale} from '../Utils/helpers'
import {themeAssets, ThemeType} from '../Utils/themes'
import {get} from 'lodash'

interface StyleOptions extends ThemeType {
    isEmbedded: boolean,
}

const useStyles = makeStyles<StyleOptions>()((theme: Theme, options: StyleOptions) => ({
    backdrop: {
        zIndex: 1000,
    },
    root: {
        backgroundColor: options.isEmbedded ? options.backgroundColor ?? 'white' : undefined,
        height: '100%',
        fontSize: 24,
        justifyContent: 'center',
        display: 'flex',
    },
    container: {
        margin: theme.spacing(3),
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 800,
    },
    thankYou: {
        fontWeight: 'bold',
    },
}))

interface ReservationDoneContainerProps extends RouteComponentProps {
    embedded: boolean
}

export const ReservationDoneContainer = ({ location, ...props }: ReservationDoneContainerProps) => {
    const language = getLocale()
    const { bookingEnabled, title, isEmbedded, logo, wwwAddress, uiTheme, uiThemeName } = useSelector((state: RootState) => ({
        bookingEnabled: state.ui.settings?.booking?.enabled,
        title: state.ui.settings?.station?.name || state.ui.settings?.chain?.name || '',
        isEmbedded: state.ui.options?.embedded || props.embedded,
        logo: state.ui.settings?.station?.logo?.data || state.ui.settings?.chain?.logo?.data || undefined,
        wwwAddress: state.ui.settings?.station?.www || state.ui.settings?.chain?.www || undefined,
        uiTheme: state.ui.options?.theme,
        uiThemeName: state.ui.options?.themeName,
    }))
    const themeOptions: StyleOptions = {isEmbedded, ...get(themeAssets, uiThemeName || 'default'), ...(uiTheme ?? {})}
    const { classes } = useStyles(themeOptions)
    const query = new URLSearchParams(location.search)
    const token = query.get('token')
    const { t } = useTranslation()
    const chainIdUrl = (props.match.params as any).chainId
    const stationIdUrl = (props.match.params as any).stationId

    if (! chainIdUrl && ! stationIdUrl && token) {
        useSettingsByToken(token, props.history, props.embedded)
    } else {
        useSettings(chainIdUrl, stationIdUrl, props.history, props.embedded)
    }

    const paymentFailed = location.pathname.includes('cancel')

    const getTitle = () => {
        return `${title} – ${t('reservationDoneContainer.thankYou')}`
    }

    if (bookingEnabled === undefined) {
        return (
            <Backdrop className={classes.backdrop} open={true} style={{ backgroundColor: 'white' }}>
                <CircularProgress size={48} color="primary" />
            </Backdrop>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Helmet>
                    <html lang={language} />
                    <title>{getTitle()}</title>
                </Helmet>
                <Header
                    title={title}
                    display={!isEmbedded}
                    logo={logo}
                    wwwAddress={wwwAddress}
                />
                <AccordionItem
                    autoHeight
                    heading={paymentFailed ? t('reservationDoneContainer.paymentFailed') : t('reservationDoneContainer.thankYou')}
                    content={
                        <>
                            <Typography paragraph>
                                {paymentFailed ? t('reservationDoneContainer.paymentFailedParagraph') : t(
                                    'reservationDoneContainer.thankYouParagraph',
                                )}
                            </Typography>
                            <Typography>
                                <Link target="_top" href={`/check-in?token=${token}`}>
                                    {t(
                                        'reservationDoneContainer.serviceCardFromHere',
                                    )}
                                </Link>
                            </Typography>
                        </>
                    }
                />
            </div>
        </div>
    )
}
