import { createReducer, createActions } from 'reduxsauce'
import { CommonReducer, Reducer } from '../Utils/reduxHelpers'
import { Action } from 'redux'
import { ReducerTypes } from './utils'
import { CallbackArray } from '../Utils/types'

type ActionTypes = {
    GIVE_FEEDBACK: string
    GIVE_FEEDBACK_SUCCESS: string
    GIVE_FEEDBACK_FAILURE: string
    GET_CHECK_IN: string
    GET_CHECK_IN_SUCCESS: string
    GET_CHECK_IN_FAILURE: string
    CANCEL_RESERVATION: string
    CANCEL_RESERVATION_SUCCESS: string
    CANCEL_RESERVATION_FAILURE: string
    CHECK_IN: string
    CHECK_IN_SUCCESS: string
    CHECK_IN_FAILURE: string
    CHANGE_RESERVATION: string
    CHANGE_RESERVATION_SUCCESS: string
    CHANGE_RESERVATION_FAILURE: string
    SUBMIT_RESERVATION_CHANGES: string
    SUBMIT_RESERVATION_CHANGES_SUCCESS: string
    SUBMIT_RESERVATION_CHANGES_FAILURE: string
    PREVENT_RESERVATION_CHANGES: string
    PREVENT_RESERVATION_CHANGES_SUCCESS: string
    PREVENT_RESERVATION_CHANGES_FAILURE: string
    FAILURE: string
    GET_RECEIPT: string
    GET_CERTIFICATE: string
}

const actions = {
    giveFeedback: ['grade', 'feedbackText', 'token', 'callbacks'],
    giveFeedbackSuccess: ['checkIn'],
    giveFeedbackFailure: null,
    getCheckIn: ['token', 'errorCallback'],
    getCheckInSuccess: ['checkIn'],
    getCheckInFailure: null,
    cancelReservation: ['token', 'callbacks'],
    cancelReservationSuccess: null,
    cancelReservationFailure: null,
    checkIn: ['token', 'callbacks'],
    checkInSuccess: ['checkIn'],
    checkInFailure: null,
    changeReservation: ['token', 'modifications', 'callbacks'],
    changeReservationSuccess: ['checkIn'],
    changeReservationFailure: null,
    submitReservationChanges: ['token', 'callbacks'],
    submitReservationChangesSuccess: ['checkIn'],
    submitReservationChangesFailure: null,
    preventReservationChanges: ['token', 'callbacks'],
    preventReservationChangesSuccess: ['checkIn'],
    preventReservationChangesFailure: null,
    failure: null,
    getReceipt: ['token'],
    getCertificate: ['token'],
}

type AnyCreators = {
    [key in keyof typeof actions]: (...params: any[]) => Action<any>
}

// Override AnyCreators with strong types
interface Creators extends AnyCreators {
    getCheckIn: (token: string, errorCallback: () => void) => Action<any>
    checkIn: (token: string, callbacks: CallbackArray) => Action<any>
    cancelReservation: (token: string, callbacks: CallbackArray) => Action<any>
    changeReservation: (
        token: string,
        modifications: { [key: string]: any },
        callbacks?: CallbackArray,
    ) => Action<any>
    submitReservationChanges: (
        token: string,
        callbacks?: CallbackArray,
    ) => Action<any>
    preventReservationChanges: (
        token: string,
        callbacks?: CallbackArray,
    ) => Action<any>
    giveFeedback: (
        grade: number,
        feedbackText: string | undefined,
        token: string,
        callbacks?: CallbackArray,
    ) => Action<any>
}

const { Types, Creators } = createActions<ActionTypes, Creators>(actions)

export const CheckInTypes = Types
export default Creators

export interface CheckInState {
    checkIn: any
    checkInFailed: boolean
    reservationChanges: any
    feedback: any
    fetching: {
        feedback: boolean
        reservationChanges: boolean
        cancelReservation: boolean
        checkIn: boolean
    }
}

export const INITIAL_STATE: CheckInState = {
    checkIn: null,
    checkInFailed: false,
    reservationChanges: null,
    feedback: null,
    fetching: {
        feedback: false,
        reservationChanges: false,
        cancelReservation: false,
        checkIn: false,
    },
}

type CommonFetchingType = keyof CheckInState['fetching']

const commonFetching = (
    type: CommonFetchingType,
): CommonReducer<CheckInState> => (state: CheckInState) => ({
    ...state,
    fetching: {
        ...state.fetching,
        [type]: true,
    },
})
const commonFailure = (
    type: CommonFetchingType,
): CommonReducer<CheckInState> => (state: CheckInState) => ({
    ...state,
    fetching: {
        ...state.fetching,
        [type]: false,
    },
})

const stateAfterFetch = (state: CheckInState, type: CommonFetchingType) => ({
    ...state,
    fetching: { ...state.fetching, [type]: false },
})

const CHECK_IN = 'checkIn'

export const getCheckIn: CommonReducer<CheckInState> = commonFetching(CHECK_IN)
export const getCheckInSuccess: Reducer<CheckInState> = (
    state,
    { checkIn },
) => ({
    ...stateAfterFetch(state, CHECK_IN),
    checkIn,
})
export const getCheckInFailure: CommonReducer<CheckInState> = commonFailure(
    CHECK_IN,
)

const CANCEL_RESERVATION = 'cancelReservation'

export const cancelReservation: CommonReducer<CheckInState> = commonFetching(
    CANCEL_RESERVATION,
)
export const cancelReservationSuccess: Reducer<CheckInState> = (state) => ({
    ...stateAfterFetch(state, CANCEL_RESERVATION),
})
export const cancelReservationFailure = commonFailure(CANCEL_RESERVATION)

const CHECKED_IN = 'checkIn'

export const checkIn: CommonReducer<CheckInState> = commonFetching(CHECKED_IN)
export const checkInSuccess: Reducer<CheckInState> = (
    state,
    { checkIn },
) => ({
    ...stateAfterFetch(state, CHECKED_IN),
    checkIn,
})
export const checkInFailure:  Reducer<CheckInState> = (state: CheckInState) => ({
    ...stateAfterFetch(state, CHECKED_IN),
    checkInFailed: true,
})

export const changeReservation: CommonReducer<CheckInState> = commonFetching(
    CHECK_IN,
)
export const changeReservationSuccess: Reducer<CheckInState> = (
    state,
    { checkIn },
) => ({
    ...stateAfterFetch(state, CHECK_IN),
    checkIn,
})
export const changeReservationFailure: CommonReducer<CheckInState> = commonFailure(
    CHECK_IN,
)

export const submitReservationChanges: CommonReducer<CheckInState> = commonFetching(
    CHECK_IN,
)

export const submitReservationChangesSuccess: Reducer<CheckInState> = (
    state,
    { checkIn },
) => ({
    ...stateAfterFetch(state, CHECK_IN),
    checkIn,
})

export const submitReservationChangesFailure: CommonReducer<CheckInState> = commonFailure(
    CHECK_IN,
)

export const preventReservationChanges: CommonReducer<CheckInState> = commonFetching(
    CHECK_IN,
)

export const preventReservationChangesSuccess: Reducer<CheckInState> = (
    state,
    { checkIn },
) => ({
    ...stateAfterFetch(state, CHECK_IN),
    checkIn,
})

export const preventReservationChangesFailure: CommonReducer<CheckInState> = commonFailure(
    CHECK_IN,
)

const FEEDBACK = 'feedback'

export const giveFeedback: CommonReducer<CheckInState> = commonFetching(
    FEEDBACK,
)

export const giveFeedbackSuccess: Reducer<CheckInState> = (
    state,
    { checkIn },
) => ({ ...stateAfterFetch(state, FEEDBACK), checkIn })

export const giveFeedbackFailure: CommonReducer<CheckInState> = commonFailure(
    FEEDBACK,
)

export const failure = (state: CheckInState) => ({
    ...state,
    fetching: INITIAL_STATE.fetching,
})

type CheckInReducerTypes = ReducerTypes<CheckInState, ActionTypes>

export const reducerTypes: CheckInReducerTypes = {
    [Types.GET_CHECK_IN]: getCheckIn,
    [Types.GET_CHECK_IN_SUCCESS]: getCheckInSuccess,
    [Types.GET_CHECK_IN_FAILURE]: getCheckInFailure,
    [Types.CANCEL_RESERVATION]: cancelReservation,
    [Types.CANCEL_RESERVATION_SUCCESS]: cancelReservationSuccess,
    [Types.CANCEL_RESERVATION_FAILURE]: cancelReservationFailure,
    [Types.CHECK_IN]: checkIn,
    [Types.CHECK_IN_SUCCESS]: checkInSuccess,
    [Types.CHECK_IN_FAILURE]: checkInFailure,
    [Types.CHANGE_RESERVATION]: changeReservation,
    [Types.CHANGE_RESERVATION_SUCCESS]: changeReservationSuccess,
    [Types.CHANGE_RESERVATION_FAILURE]: changeReservationFailure,
    [Types.PREVENT_RESERVATION_CHANGES]: preventReservationChanges,
    [Types.PREVENT_RESERVATION_CHANGES_SUCCESS]: preventReservationChangesSuccess,
    [Types.PREVENT_RESERVATION_CHANGES_FAILURE]: preventReservationChangesFailure,
    [Types.SUBMIT_RESERVATION_CHANGES]: submitReservationChanges,
    [Types.SUBMIT_RESERVATION_CHANGES_SUCCESS]: submitReservationChangesSuccess,
    [Types.SUBMIT_RESERVATION_CHANGES_FAILURE]: submitReservationChangesFailure,
    [Types.GIVE_FEEDBACK]: giveFeedback,
    [Types.GIVE_FEEDBACK_SUCCESS]: giveFeedbackSuccess,
    [Types.GIVE_FEEDBACK_FAILURE]: giveFeedbackFailure,
    [Types.FAILURE]: failure,
}

export const reducer = createReducer<CheckInState, Action<CheckInReducerTypes>>(
    INITIAL_STATE,
    reducerTypes,
)
