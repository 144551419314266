import {
    CircularProgress,
    lighten,
    Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { DateTime } from 'luxon'
import React, {FC, useEffect, useMemo, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { a11yClick } from '../../Utils/a11yHelpers'
import {useMobile, usePrevious, useAppThemeOptions} from '../../Utils/hooks'
import { DatePrices } from './Content'
import {PriceAndTime, PriceObject} from '../../interfaces'
import {numberToCurrencyString} from '../../Utils/helpers'
import { ThemeType } from '../../Utils/themes'

interface TimePickerProps {
    forceMobile?: boolean
    clickDisabled?: boolean
    locale?: string
    date: DateTime | null
    dates: DateTime[]
    display: boolean
    onChange(item: DateTime): void
    time: DateTime | null
    first: { hour: number; minute: number }
    last: { hour: number; minute: number }
    listPrice?: PriceObject
    fixedPrice?: number | null
    timesAndPrices?: DatePrices
    timeIsSet: boolean
    showPrices: boolean
    fetching: boolean
    renderSelectButton: (classes?: any) => JSX.Element
    renderMiddleButton?: (classes?: any) => JSX.Element
}

interface StyleProps extends ThemeType {
    isMobile: boolean
}

const useStyles = makeStyles<StyleProps>()((theme: Theme, styleProps: StyleProps) => ({
    activeDateTime: {
        color: `${styleProps.pricesTextActiveColor ?? '#fff'} !important`,
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            width: 250,
        },
    },
    circularProgress: {
        marginLeft: theme.spacing(2),
    },
    timesContainer: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'hidden',
        padding: theme.spacing(1, 0),
        width: '100%',
    },
    column: {
        padding: theme.spacing(1),
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 0, 0),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
    },
    activeRow: {
        backgroundColor: styleProps.pricesMinColor ?? theme.palette.secondary.main,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: 0,
        outline: 0,
        height: 38,
        padding: theme.spacing(1, 0.5),
        margin: theme.spacing(0.5, 0),
        boxShadow: theme.shadows[3],
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 8,
        '&:hover, &:active, &:focus': {
            boxShadow: '0px 0px 2px 2px rgba(90,90,90,1)',
        },
    },
    centerContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    middleButton: {
        borderRadius: 24,
        color: theme.palette.grey[100],
        fontSize: theme.typography.pxToRem(16),
        width: 250,
        '&:hover': {
            fontWeight: 'bold',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    nextButton: {
        borderRadius: 24,
        fontSize: theme.typography.pxToRem(16),
        width: 250,
        '&:hover': {
            fontWeight: 'bold',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    price: {
        height: '100%',
        margin: 0,
    },
    minPrice: {
        fontWeight: 'bold',
    },
    minPriceBorder: {
        borderColor: styleProps.pricesMinColor ?? theme.palette.secondary.main,
        borderRightWidth: theme.spacing(1.5),
    },
    lowPrice: {
        fontWeight: 'bold',
    },
    lowPriceBorder: {
        borderColor: styleProps.pricesLowColor ?? lighten(theme.palette.secondary.main, 0.5),
        borderRightWidth: theme.spacing(1.5),
    },
    normalPrice: {
        fontWeight: 'bold',
    },
    normalPriceBorder: {
        paddingRight: theme.spacing(2),
        borderColor: lighten(styleProps.pricesTextColor ?? theme.palette.primary.main, 0.6),
    },
    highPrice: {
        fontWeight: 'bold',
    },
    highPriceBorder: {
        paddingRight: theme.spacing(2),
        borderColor: lighten(styleProps.pricesTextColor ?? theme.palette.primary.main, 0.6),
    },
    priceContainer: {
        marginLeft: theme.spacing(0.5),
        color: styleProps.pricesTextColor ?? theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: styleProps.isMobile ? 'flex-end' : 'center',
        marginRight: styleProps.isMobile ? theme.spacing(1) : 0,
    },
    activePriceLevelContainer: {
    },
    priceLevelContainer: {
        width: theme.spacing(1.5),
        marginTop: parseInt(theme.spacing(-1)) - 1,
        marginRight: theme.spacing(-1.5),
        marginBottom: parseInt(theme.spacing(-1)) - 1,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
    activePriceContainer: {
        color: styleProps.pricesTextActiveColor ?? 'white',
    },
    dateContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: styleProps.isMobile ? 'normal' : 'center',
        marginLeft: styleProps.isMobile ? theme.spacing(2) : 0,
    },
    dateTime: {
        color: styleProps.pricesTextColor ?? theme.palette.primary.main,
    },
    date: {
        fontWeight: 'bold',
        color: styleProps.pricesTextColor ?? theme.palette.primary.main,
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2),
        },
    },
}))

interface DayPrices {
    date: DateTime
    prices: PriceAndTime[],

}
export const TimePicker: FC<TimePickerProps> = ({
    forceMobile,
    display,
    timeIsSet,
    onChange,
    fetching,
    time,
    date,
    dates,
    listPrice,
    fixedPrice,
    showPrices,
    timesAndPrices,
    renderSelectButton,
    renderMiddleButton,
    clickDisabled,
}) => {
    const isMobile = forceMobile || useMobile('md')
    const themeOptions = useAppThemeOptions()
    const { classes } = useStyles({ isMobile, ...themeOptions })
    const originalCount = 9
    const [renderCount, setRenderCount] = useState(originalCount)
    const [clickedTime, setClickedTime] = useState(null)
    const { t } = useTranslation()
    const prevDate = usePrevious(date)
    useEffect(() => {
        if (
            renderCount !== originalCount &&
            date?.toISODate() !== prevDate?.toISODate()
        )
            setRenderCount(originalCount)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, originalCount])

    const handleTimeSelect = (selectedTime) => {
        setClickedTime(selectedTime)
        onChange(selectedTime)
    }

    const dayPrices: DayPrices[] = useMemo(() => {
        if (!date || !dates || !timesAndPrices) {
            return []
        }
        const visibleDates = isMobile ? [date] : dates

        return visibleDates.map(day => ({
            date: day,
            prices: timesAndPrices.prices.filter((price) => DateTime.fromISO(price.time, {setZone: true}).toISODate() === day.toISODate()),
        }))
    }, [date, dates, timesAndPrices, isMobile])

    const priceRange = useMemo(() => {
        const range = {
            min: null,
            max: null,
        }

        const prices = timesAndPrices?.prices ?? []

        for (const price of prices) {
            if (!range.min || range.min > price.price.value) {
                range.min = price.price.value
            }
            if (!range.max || range.max < price.price.value) {
                range.max = price.price.value
            }
        }

        return {
            min: range.min ?? listPrice?.value ?? 0,
            max: range.max ?? listPrice?.value ?? 0,
        }
    }, [timesAndPrices, listPrice])

    const normalPrice = listPrice?.value >= priceRange.min && listPrice?.value <= priceRange.max ? listPrice?.value : priceRange.max

    const getPriceClass = (price: number) => {
        if (fixedPrice) {
            return classes.normalPrice
        }

        const val = normalPrice.toFixed(2) !== price.toFixed(2) ? Math.round((price - priceRange.min) / (normalPrice - priceRange.min) * 100) : 100

        if (val === 0 || price === priceRange.min) {
            return classes.minPrice
        } else if (val < 100) {
            return classes.lowPrice
        } else if (val > 100) {
            return classes.highPrice
        } else {
            return classes.normalPrice
        }
    }

    const getPriceBorderClass = (price: number) => {
        if (fixedPrice) {
            return classes.normalPriceBorder
        }

        const val = normalPrice.toFixed(2) !== price.toFixed(2) ? Math.round((price - priceRange.min) / (normalPrice - priceRange.min) * 100) : 100

        if (val === 0) {
            return classes.minPriceBorder
        } else if (val < 100) {
            return classes.lowPriceBorder
        } else if (val > 100) {
            return classes.highPriceBorder
        } else {
            return classes.normalPriceBorder
        }
    }

    if (!display || !time || !timesAndPrices) return null

    return (<>
        <div className={classes.timesContainer}>
            {dayPrices.map(({prices}, index) => (<div key={index} className={classes.column}>
                {prices.length === 0 && (
                    <div className={isMobile ? classes.centerContentContainer : undefined}>
                        {t('rootContainer.noFreeTimes')}
                    </div>
                )}
                {prices
                    .map(({ time: dateTime, price }) => {
                        const date = DateTime.fromISO(dateTime, {setZone: true})
                        const isActive =
                            date.equals(time) &&
                            timeIsSet

                        return (
                            <div
                                key={dateTime}
                                {...a11yClick(() =>
                                    fetching || clickDisabled || isActive
                                        ? undefined
                                        : handleTimeSelect(date),
                                )}
                                className={`${classes.row} ${isActive ? classes.activeRow : ''} ${getPriceBorderClass(price.value)}`}
                                role="button"
                                tabIndex={0}
                            >
                                <div className={classes.dateContainer}>
                                    <div>
                                        {!isMobile && fetching && clickedTime && clickedTime.equals(date) ? (
                                            <CircularProgress
                                                className={
                                                    classes.circularProgress
                                                }
                                                size={24}
                                            />
                                        ) : (
                                            <Typography
                                                className={
                                                    isActive
                                                        ? `${classes.dateTime} ${classes.activeDateTime}`
                                                        : classes.dateTime
                                                }
                                            >
                                                {date.toFormat('HH:mm')}
                                            </Typography>
                                        )}
                                    </div>
                                    {isMobile ? <div>
                                        {fetching && clickedTime && clickedTime.equals(date) ? (
                                            <CircularProgress
                                                className={
                                                    classes.circularProgress
                                                }
                                                size={24}
                                            />
                                        ) : (
                                            <Typography
                                                className={
                                                    isActive
                                                        ? `${classes.date} ${classes.activeDateTime}`
                                                        : classes.date
                                                }
                                            >
                                                {t(
                                                    isActive
                                                        ? 'rootContainer.selected'
                                                        : 'rootContainer.reservable',
                                                )}
                                            </Typography>
                                        )}
                                    </div> : null}
                                </div>
                                {showPrices && (
                                    <div className={`${classes.priceContainer} ${isActive ? classes.activePriceContainer : ''}`}>
                                        <Typography
                                            className={`${classes.price} ${getPriceClass(price.value)}`}
                                            noWrap
                                        >
                                            {numberToCurrencyString(fixedPrice ?? price.value, 'EUR', 0)}
                                        </Typography>
                                    </div>
                                )}
                            </div>
                        )
                    })}
            </div>))}
        </div>
        <div className={classes.buttonsContainer}>
            {renderMiddleButton && renderMiddleButton({ root: classes.middleButton })}
            {renderSelectButton({ root: classes.nextButton })}
        </div>
    </>)
}
