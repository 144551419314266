import {
    AppBar,
    Grid,
    Link,
    MenuItem,
    Select,
    Toolbar,
    Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React, {forwardRef, Ref, useEffect, useState} from 'react'
import i18next from "i18next";
import {getLocale, storeLocale} from '../Utils/helpers'
import { ThemeType } from '../Utils/themes'
import { useAppThemeOptions } from '../Utils/hooks'

interface HeaderProps {
    title: string
    display: boolean
    renderTimer?: () => JSX.Element
    logo?: string
    wwwAddress?: string
}

const useStyles = makeStyles<ThemeType>()((theme: Theme, options: ThemeType) => ({
    appBar: {
        height: 65,
        backgroundColor: options.headerBackgroundColor ?? theme.palette.primary.dark,
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    logo: {
        maxHeight: 50,
        maxWidth: '95%',
    },
    select: {
        color: options.headerTextColor ?? 'white',
        marginRight: theme.spacing(2),
        '&:before': {
            borderColor: options.headerTextColor ?? 'white',
        },
        '&:after': {
            borderColor: options.headerTextColor ?? 'white',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: options.headerTextColor ?? 'white',
        },
    },
    icon: {
        fill: options.headerTextColor ?? 'white',
    },
    title: {
        color: '#fff',
        fontFamily: theme.typography.h3.fontFamily,
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 'bold',
    },
    toolbar: {
        padding: theme.spacing(1),
    },
}))

export const Header = forwardRef<HTMLElement, HeaderProps>(
    ({ title, display, renderTimer, logo, wwwAddress }, ref) => {
        const [language, setLanguage] = useState(getLocale())
        const themeOptions = useAppThemeOptions()
        const { classes } = useStyles(themeOptions)
        useEffect(() => {
            (async () => {
                if (language) storeLocale(language)
                await i18next.changeLanguage(language)
            })()
        }, [language])
        const getStationUrl = () => {
            if (wwwAddress) {
                if (wwwAddress.startsWith('http')) {
                    return wwwAddress
                } else {
                    return 'http://' + wwwAddress
                }
            } else {
                return '';
            }
        }
        if (!display) return null
        return (
            <>
                <AppBar
                    ref={ref as Ref<HTMLDivElement>}
                    position="fixed"
                    className={classes.appBar}
                    elevation={0}
                >
                    <Toolbar className={classes.toolbar}>
                        <Grid container>
                            <Grid
                                item
                                xs={4}
                            >
                                <Link href={getStationUrl()} underline="none">
                                    {logo ? (
                                        <img
                                            className={classes.logo}
                                            src={logo}
                                            alt="logo"
                                        />
                                    ) : (
                                        <Typography
                                            className={classes.title}
                                            noWrap
                                        >
                                            {title}
                                        </Typography>
                                    )}
                                </Link>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                            >
                                {renderTimer && renderTimer()}
                            </Grid>
                            <Grid item xs={4} justifyContent="flex-end" alignItems="center" container>
                                <Select
                                    variant="standard"
                                    onChange={(e) => setLanguage(e.target.value as any)}
                                    value={language}
                                    className={classes.select}
                                    inputProps={{
                                        classes: {
                                            icon: classes.icon,
                                        },
                                    }}
                                >
                                    <MenuItem value="fi">FI</MenuItem>
                                    <MenuItem value="sv">SE</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Toolbar />
            </>
        )
    },
)

Header.displayName = 'Header'
