import { all, put, takeLatest } from 'redux-saga/effects'
import { ReservationTypes, reducerTypes } from '../Redux/reservationRedux'
import { CheckInTypes } from '../Redux/checkInRedux'
import { PaymentTypes } from '../Redux/paymentRedux'
import api from '../Services/api'
import {
    getBestPrices,
    getDates,
    resetDateTimes,
    getDateTimes,
    getFilteredStations,
    getGeocodes,
    getRegisterInfo,
    getServices,
    getStations,
    discardReservation,
    postFinalReservation,
    postReservation,
    setInformation,
    setInspectDate,
    setRegisterInfo,
} from './reservationSagas'
import { UITypes } from '../Redux/uiRedux'
import { getSettings } from './uiSagas'
import {
    cancelReservation,
    changeReservation,
    checkIn,
    getCheckIn,
    getReceipt,
    getCertificate,
    giveFeedback,
    preventReservationChanges,
    submitReservationChanges,
} from './checkInSagas'
import ErrorActions from '../Redux/errorRedux'
import { createPayment } from './paymentSagas'
import { getGeneralApiProblem } from '../Utils/reduxHelpers'

const API = api.create()

function errorHandler(func: any) {
    return function* (api: any, action: any) {
        try {
            yield* func(api, action)
        } catch (error) {
            if (error?.response) {
                const { response} = error
                const problem = getGeneralApiProblem(
                    response.problem,
                    response.status,
                )
                yield put(ErrorActions.setError(problem))
            } else {
                // Unknown error -> report to server

                try {
                    api.postError({error, message: 'saga error', userAgent: window.navigator?.userAgent}, window.location.href)
                } catch (logError) {
                    // ignore
                }

                throw error
            }
        }
    }
}

type SagaType = [keyof typeof reducerTypes, any, typeof API]

export default function* root(): Generator {
    const functions: SagaType[] = [
        [ReservationTypes.GET_REGISTER_INFO, getRegisterInfo, API],
        [ReservationTypes.GET_STATIONS, getStations, API],
        [ReservationTypes.GET_FILTERED_STATIONS, getFilteredStations, API],
        [ReservationTypes.GET_GEOCODES, getGeocodes, API],
        [ReservationTypes.GET_SERVICES, getServices, API],
        [ReservationTypes.GET_DATES, getDates, API],
        [ReservationTypes.GET_BEST_PRICES, getBestPrices, API],
        [ReservationTypes.RESET_DATE_TIMES, resetDateTimes, API],
        [ReservationTypes.GET_DATE_TIMES, getDateTimes, API],
        [ReservationTypes.SET_INFORMATION, setInformation, API],
        [ReservationTypes.SET_REGISTER_INFO, setRegisterInfo, API],
        [ReservationTypes.SET_INSPECT_DATE, setInspectDate, API],
        [ReservationTypes.DISCARD_RESERVATION, discardReservation, API],
        [ReservationTypes.POST_RESERVATION, postReservation, API],
        [ReservationTypes.POST_FINAL_RESERVATION, postFinalReservation, API],
        [UITypes.GET_SETTINGS, getSettings, API],
        [UITypes.GET_SETTINGS_BY_TOKEN, getSettings, API],
        [CheckInTypes.GET_CHECK_IN, getCheckIn, API],
        [CheckInTypes.CANCEL_RESERVATION, cancelReservation, API],
        [CheckInTypes.CHECK_IN, checkIn, API],
        [CheckInTypes.GET_RECEIPT, getReceipt, API],
        [CheckInTypes.GET_CERTIFICATE, getCertificate, API],
        [CheckInTypes.CHANGE_RESERVATION, changeReservation, API],
        [CheckInTypes.PREVENT_RESERVATION_CHANGES, preventReservationChanges, API],
        [CheckInTypes.SUBMIT_RESERVATION_CHANGES, submitReservationChanges, API],
        [CheckInTypes.GIVE_FEEDBACK, giveFeedback, API],
        [PaymentTypes.CREATE_PAYMENT, createPayment, API],
    ]

    yield all([
        ...functions.map(([pattern, worker, api]) =>
            takeLatest(pattern, errorHandler(worker), api),
        ),
    ])
}
