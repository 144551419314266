import React, { FC } from 'react'
import { numberToCurrencyString } from '../Utils/helpers'

interface CurrencyProps {
    value?: number | string | null
    currency?: string
    minimumFractionDigits?: number
}

export const Currency: FC<CurrencyProps> = ({ value, currency, minimumFractionDigits }) => {
    if (value === undefined || value === null) return null
    const _value = typeof value === 'string' ? parseFloat(value) : value
    return <>{numberToCurrencyString(_value, currency, minimumFractionDigits)}</>
}
