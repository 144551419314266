import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material'

interface CheckboxWrapperProps {
    checkboxProps?: CheckboxProps
    children?: any
}

const CheckboxWrapper: FC<CheckboxWrapperProps & FieldRenderProps<any>> = ({
    input: { onChange, checked },
    label,
    checkboxProps,
    children,
}) => {
    return (
        <FormControlLabel
            
            control={
                <Checkbox
                    {...checkboxProps}
                    checked={checked || false}
                    onChange={onChange}
                    color="primary"
                />
            }
            label={children ? children : label}
            labelPlacement="end"
        />
    )
}

export default CheckboxWrapper
