import { useMediaQuery, useTheme, Theme } from '@mui/material'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../Redux'
import UIActions from '../Redux/uiRedux'
import {themes, themeAssets, ThemeType} from './themes'
import {get} from 'lodash'

export const usePrevious = <T>(value: T): MutableRefObject<T>['current'] => {
    const ref = useRef<T>()
    useEffect(() => {
        ref.current = value
    })
    return ref.current as T
}

type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const useAppThemeName = () =>
    useSelector((state: RootState) =>
        state.ui?.options?.themeName ?? (state.ui?.settings?.booking?.enabled === undefined ? 'white' : 'default'))

export const useAppThemeOptions = (): ThemeType => get(themeAssets, useAppThemeName())

export const useAppTheme = (): Theme => get(themes, useAppThemeName())

export const useMobile = (
    size?: ScreenSize | number,
    options?: { unit: string },
): boolean => {
    const theme = useTheme()
    if (typeof size === 'string') {
        return useMediaQuery(theme.breakpoints.down(size || 'sm'))
    }
    return useMediaQuery(`@media (max-width:${size}${options?.unit})`)
}

export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0)
    const handleScroll = () => {
        const position = window.pageYOffset
        setScrollPosition(position)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    return scrollPosition
}

export const useSettingsByToken = (token: string, history: any, embedded: boolean) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(UIActions.getSettingsByToken(token, history, embedded))
    }, [token, dispatch, embedded, history])
}

export const useSettings = (chainIdUrl: string, stationIdUrl: string, history: any, embedded: boolean) => {
    const dispatch = useDispatch()
    useEffect(() => {
        if (stationIdUrl && chainIdUrl) {
            dispatch(UIActions.getSettings(chainIdUrl, stationIdUrl, history, embedded))
        }
        else if (chainIdUrl) {
            dispatch(UIActions.getSettings(chainIdUrl, null, history, embedded))
        }
    }, [chainIdUrl, dispatch, embedded, history, stationIdUrl])
}

export const useTitle = () => {
    const { ui } = useSelector(({ ui }: RootState) => ({
        ui,
    }))
    return ui.settings?.station?.name || ui.settings?.chain?.name || ''
}

type IntervalFunction = () => unknown | void

export const useInterval = (
    callback: IntervalFunction,
    delay: number | null,
) => {
    const savedCallback = useRef<IntervalFunction | null>(null)

    useEffect(() => {
        if (delay === null) return
        savedCallback.current = callback
    })

    useEffect(() => {
        if (delay === null) return
        const tick = () => {
            if (savedCallback.current !== null) {
                savedCallback.current()
            }
        }
        const id = setInterval(tick, delay)
        return () => clearInterval(id)
    }, [delay])
}
