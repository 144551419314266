import React, {FC, useEffect, useState} from 'react'
import { Card, CardContent, Grid, Typography, } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import {InspectService} from '../../interfaces'
import { ReservationState } from '../../Redux/reservationRedux'
import { useTranslation } from 'react-i18next'
import { a11yClick } from '../../Utils/a11yHelpers'
import { PrimaryButton } from '../Button'
import { RootState } from '../../Redux'
import { useSelector } from 'react-redux'
import { getServiceTranslation, numberToCurrencyString } from "../../Utils/helpers";
import { ThemeType } from '../../Utils/themes'
import { useAppThemeOptions } from '../../Utils/hooks'
import {CallbackArray} from "../../Utils/types";
import CampaignCodeDialog from "./CampaignCodeDialog";

const useStyles = makeStyles<ThemeType>()((theme: Theme, options: ThemeType) => ({
    accordion: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(0, 2, 3),
    },
    accordionCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(0, 2, 3),
    },
    cardDetails: {
        padding: theme.spacing(2),
        lineHeight: 1.4,
        flex: 1,
        justifyContent: 'center',
    },
    cardEl: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        boxShadow: theme.shadows[4],
        '&:active, &:focus': {
            boxShadow: `0 0 0 1px ${theme.palette.secondary.main}`,
        },
        outline: 0,
    },
    cardHeader: {
        position: 'relative',
        display: 'flex',
        padding: theme.spacing(3, 2, 2, 2),
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    contentEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    accordionTexts: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
    activeCardEl: {
        outline: 'none',
        boxShadow: `0 0 0 3px ${theme.palette.secondary.main}`,
        '&:hover, &:active, &:focus': {
            boxShadow: `0 0 0 3px ${theme.palette.secondary.main}`,
        },
    },
    headerText: {
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(16),
        },
        '@media(max-width:360px)': {
            fontSize: theme.typography.pxToRem(15),
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flex: 1,
    },
    headingPrice: {
        color: theme.palette.grey[600],
        fontSize: theme.typography.pxToRem(15),
        flex: 1,
    },
    nextButton: {
        borderRadius: 24,
        fontSize: theme.typography.pxToRem(16),
        marginTop: theme.spacing(3),
        width: 250,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            maxWidth: '100%',
        },
        '&:hover': {
            fontWeight: 'bold',
        },
    },
    priceContainer: {
        display: 'flex',
        right: theme.spacing(3),
        flexDirection: 'column',
        marginTop: theme.spacing(-3),
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        marginLeft: theme.spacing(1),
        '@media(max-width:360px)': {
            right: 8,
            position: 'absolute',
        },
    },
    priceInnerUpperContainer: {
        backgroundColor: options.priceTagHeaderColor ?? theme.palette.primary.light,
        padding: theme.spacing(1, 1.5, 1),
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    priceInnerBottomContainer: {
        backgroundColor: options.priceTagMainColor ?? theme.palette.primary.dark,
        padding: theme.spacing(1.5, 1.5),
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    startingPriceText: {
        color: options.priceTagTextColor ?? '#fff',
        fontSize: theme.typography.pxToRem(14),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(12),
        },
    },
    startingPrice: {
        fontSize: theme.typography.pxToRem(16),
        color: options.priceTagTextColor ?? '#fff',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(14),
        },
    },
    campaignCodeButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
        marginBottom: theme.spacing(2),
    },
    campaignCodeButton: {
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(2),
        },
    },
    infoBox: {
        marginBottom: theme.spacing(2),
        flex: 1,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        backgroundColor: '#eee',
        padding: theme.spacing(2),
        borderRadius: 8,
    },
    infoText: {
        color: theme.palette.secondary.main,
        padding: 0,
        margin: 0,
    },
}))

interface ServiceStepProps {
    services: InspectService[] | null
    fetching: ReservationState['fetching']
    goNext: any
    activeService?: InspectService
    contentStyle: string
    canGoToIndex: any
    nextButtonText?: string
    forceMobile?: boolean
    handleGetServices?: (callbacks?: CallbackArray | (() => void), campaignCode?: string | null) => void

    onGetBestPrices(
        service: InspectService,
        campaign: string | null,
        dateStart: string | undefined,
        dateEnd: string | undefined,
        callback?: () => void,
    ): void
    setService(service: InspectService | null): void
    setReservationCampaignCode?: (campaignCode: string, callbacks?: CallbackArray | (() => void)) => void
}

export const ServiceStep: FC<ServiceStepProps> = (props) => {
    const {
        services,
        goNext,
        onGetBestPrices,
        fetching,
        canGoToIndex,
        setService,
        activeService,
        nextButtonText,
        contentStyle,
        forceMobile,
        setReservationCampaignCode,
        handleGetServices,
    } = props
    const showBestPrices = useSelector((state: RootState) => state.ui.settings?.booking?.options?.showBestPrices)
    const forceOpenCampaignCodeDialog = useSelector((state: RootState) => state.ui.settings?.booking?.options?.forceCampaignCodeCheck ?? false)
    const reservationCampaignCode = useSelector((state: RootState) => state.reservation.campaignCode)
    const registerInfo = useSelector((state: RootState) => state.reservation.registerInfo ?? state.checkIn?.checkIn?.reservation?.tasks?.find(t => t.vehicle?.afterInspectionProductId)?.vehicle)
    const { t } = useTranslation()
    const themeOptions = useAppThemeOptions()
    const { classes } = useStyles(themeOptions)
    const [campaignCode, setCampaignCode] = useState<string|null>(reservationCampaignCode)
    const getInitialDialogOpenState = () => {
        if (reservationCampaignCode) {
            return false
        }
        return forceOpenCampaignCodeDialog
    }
    const [campaignCodeDialogOpen, setCampaignCodeDialogOpen] = useState(getInitialDialogOpenState())
    const allowCampaignCode = useSelector((state: RootState) => state.ui.settings?.booking?.options?.allowCampaignCode ?? false)

    const openCampaignCodeDialog = () => {
        setCampaignCodeDialogOpen(true)
    }

    const closeCampaignCodeDialog = () => {
        setCampaignCodeDialogOpen(false)
    }

    const handleSetCampaignCode = (e: any) => {
        setReservationCampaignCode(e.campaignCode)
        handleGetServices(null, e.campaignCode)
        setCampaignCode(e.campaignCode)
        closeCampaignCodeDialog()
    }

    const renderCampaignCodeButton = () => allowCampaignCode ? (
        <div className={classes.campaignCodeButtonContainer}>
            <PrimaryButton
                className={classes.campaignCodeButton}
                onClick={openCampaignCodeDialog}
            >
                {t('common.campaignCode')}
            </PrimaryButton>
        </div>
    ) : null

    useEffect(() => {
        if (! activeService && registerInfo?.afterInspectionProductId) {
            const afterInspectionService = services?.find((s) => s.id === registerInfo.afterInspectionProductId)
            if (afterInspectionService) {
                setService(afterInspectionService)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [services]);

    return (
        <div className={contentStyle}>
            {!!campaignCode &&
                <div className={classes.infoBox}>
                    <p className={classes.infoText}>
                        Kampanjakoodi: <b>{campaignCode.toUpperCase()}</b>
                    </p>
                </div>
            }
            {renderCampaignCodeButton()}
            <Grid container spacing={2}>
                {services?.map((item) => (
                    <Grid
                        key={item.id}
                        item
                        sm={12}
                        xs={12}
                        md={forceMobile ? 12 : 6}
                    >
                        <Card
                            tabIndex={0}
                            role="button"
                            classes={{
                                root:
                                    item.id === activeService?.id
                                        ? `${classes.cardEl} ${classes.activeCardEl}`

                                        : classes.cardEl,
                            }}
                            {...a11yClick(() => setService(item))}
                        >
                            <div className={classes.cardHeader}>
                                <div>
                                    <Typography className={classes.headerText}>
                                        {getServiceTranslation('name', item)}
                                    </Typography>
                                </div>
                                {showBestPrices && (
                                    <div className={classes.priceContainer}>
                                        <div
                                            className={
                                                classes.priceInnerUpperContainer
                                            }
                                        >
                                            <Typography
                                                noWrap
                                                className={
                                                    classes.startingPriceText
                                                }
                                            >
                                                {t(
                                                    'rootContainer.startingPrice',
                                                )}
                                            </Typography>
                                        </div>
                                        <div
                                            className={
                                                classes.priceInnerBottomContainer
                                            }
                                        >
                                            <Typography
                                                noWrap
                                                className={
                                                    classes.startingPrice
                                                }
                                            >
                                                {numberToCurrencyString((item.id === registerInfo?.afterInspectionProductId ? registerInfo?.afterInspectionPrice?.value : null) ?? item.lowestPrice.value, 'EUR', 0)}
                                            </Typography>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <CardContent
                                classes={{ root: classes.cardDetails }}
                            >
                                <Typography>{getServiceTranslation('additionalInfo', item)}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <div className={classes.contentEnd}>
                <PrimaryButton
                    id="next-service"
                    disabled={!canGoToIndex(3)}
                    onClick={() =>
                        onGetBestPrices(
                            activeService,
                            campaignCode,
                            undefined,
                            undefined,
                            goNext,
                        )
                    }
                    className={classes.nextButton}
                    fetching={fetching.bestPrices}
                >
                    {nextButtonText || t('common.next')}
                </PrimaryButton>
            </div>
            <CampaignCodeDialog handleSetCampaignCode={handleSetCampaignCode} dialogIsOpen={campaignCodeDialogOpen} closeCampaignCodeDialog={closeCampaignCodeDialog} />
        </div>
    )
}
