import React, { FC } from 'react'
import {InspectService, InspectStation, PrefillValues} from '../../interfaces'
import { ReservationState } from '../../Redux/reservationRedux'
import { InfoForm } from './InfoForm'
import {getLocale} from '../../Utils/helpers'

interface InfoStepProps {
    prices: any
    onSubmitInfo: any
    inputId: string
    contentStyle: string
    validate: any
    userInformation: any
    fetching: ReservationState['fetching']
    registerInfo: any
    inspectDate: any
    station: InspectStation | null
    inspectService: InspectService | null
    price: number | null
    prefill: PrefillValues
}

export const InfoStep: FC<InfoStepProps> = ({
    onSubmitInfo,
    prices,
    inputId,
    fetching,
    inspectService,
    registerInfo,
    inspectDate,
    validate,
    station,
    contentStyle,
    prefill,
}) => (
    <div className={contentStyle}>
        <InfoForm
            onSubmiInfo={onSubmitInfo}
            prices={prices}
            inputId={inputId}
            fetching={fetching.finalReservation}
            inspectDate={inspectDate}
            inspectService={inspectService}
            locale={getLocale()}
            registerInfo={registerInfo}
            station={station}
            validate={validate}
            prefill={prefill}
        />
    </div>
)
