import { call, put, select } from '@redux-saga/core/effects'
import { ApiResponse } from 'apisauce'
import { ApiType } from '../Services/api'
import { getGeneralApiProblem } from '../Utils/reduxHelpers'
import ErrorActions from '../Redux/errorRedux'
import CheckInActions, { CheckInState } from '../Redux/checkInRedux'
import { DateTime } from 'luxon'
import { get } from 'lodash'
import { handleCallbacks } from '../Utils/helpers'
import download from 'downloadjs'

interface ActionType {
    type: string
}
interface AnyAction extends ActionType {
    [key: string]: any
}

export function* getCheckIn(api: ApiType, action: AnyAction): unknown {
    const { token, errorCallback } = action
    const response: ApiResponse<any> = yield call(api.getCheckIn, token)
    if (response.ok) {
        const { data } = response
        const localTimestamp = DateTime.local().toISO()
        yield put(CheckInActions.getCheckInSuccess({ ...data, localTimestamp }))
    } else if (response.problem) {
        if (errorCallback) {
            const problem = getGeneralApiProblem(response.problem, response.status)
            yield put(ErrorActions.setError(problem))
            yield call(errorCallback)
        }
        yield put(CheckInActions.getCheckInFailure())
    }
}

export function* getCertificate(api: ApiType, action: AnyAction): unknown {
    const { token } = action
    const response: ApiResponse<any> = yield call(api.getCertificate, token)
    if (response.ok) {
        const { data } = response
        download(data, 'katsastustodistus.pdf')
    } else if (response.problem) {
        const problem = getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(CheckInActions.failure())
    }
}

export function* getReceipt(api: ApiType, action: AnyAction): unknown {
    const { token } = action
    const response: ApiResponse<any> = yield call(api.getReceipt, token)
    if (response.ok) {
        const { data } = response
        download(data, 'katsastuskuitti.pdf')
    } else if (response.problem) {
        const problem = getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(CheckInActions.failure())
    }
}

export function* cancelReservation(api: ApiType, action: AnyAction): unknown {
    const { token, callbacks } = action
    const response: ApiResponse<any> = yield call(api.cancelReservation, token)
    if (response.ok) {
        const { data } = response
        yield put(CheckInActions.cancelReservationSuccess(data))
        yield handleCallbacks(callbacks)
    } else if (response.problem) {
        const problem = response.status === 409 ? 'errors.cancelFailed' : getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(CheckInActions.cancelReservationFailure())
    }
}

export function* checkIn(api: ApiType, action: AnyAction): unknown {
    const { token, callbacks } = action
    const response: ApiResponse<any> = yield call(api.checkIn, token)
    if (response.ok) {
        const { data } = response
        yield put(CheckInActions.checkInSuccess(data))
        yield handleCallbacks(callbacks)
    } else if (response.problem) {
        const problem = getGeneralApiProblem(response.problem, response.status)
        if (response.status === 409) {
            yield put(CheckInActions.checkInFailure())
        } else {
            yield put(ErrorActions.setError(problem))
            yield put(CheckInActions.checkInFailure())
        }
    }
}
export function* changeReservation(api: ApiType, action: AnyAction): unknown {
    const { token, modifications, callbacks } = action
    const state: any = yield select(
        ({ checkIn }: { checkIn: CheckInState }) => ({
            registrationNumber: get(
                checkIn,
                'checkIn.reservation.tasks[0].vehicle.registrationNumber',
            ),
            chainId: get(checkIn, 'checkIn.chain.id'),
            stationId:
                modifications?.station?.id ||
                get(checkIn, 'checkIn.station.id'),
            event: get(checkIn, 'checkIn.reservation.tasks[0].event'),
            vehicleGroup: get(
                checkIn,
                'checkIn.reservation.tasks[0].vehicle.vehicleGroup',
            ),
        }),
    )
    let event: any

    if (modifications.date) {
        event = {
            duration: {
                start: (modifications.date as DateTime).toISO(),
                end: (modifications.date as DateTime)
                    .plus({ minutes: 15 })
                    .toISO(),
            },
        }
    }

    const params = {
        reservation: {
            chainId: state.chainId,
            stationId: state.stationId,
            tasks: [
                {
                    event: event ?? state.event,
                    vehicle: {
                        registrationNumber: state.registrationNumber,
                        vehicleGroup: state.vehicleGroup,
                    },
                    basket: modifications.service
                        ? {
                              items: [
                                  {
                                      productId: modifications.service.id,
                                      name: modifications.service.name,
                                  },
                              ],
                          }
                        : undefined,
                },
            ],
        },
    }
    const response: ApiResponse<any> = yield call(api.changeReservation, {
        token,
        modifications: params,
    })
    if (response.ok) {
        const { data } = response
        const localTimestamp = DateTime.local().toISO()
        yield put(
            CheckInActions.changeReservationSuccess({
                ...data,
                localTimestamp,
            }),
        )
        yield handleCallbacks(callbacks)
    } else if (response.problem) {
        if (response.status === 409 && modifications.date) {
            yield put(ErrorActions.setError('errors.timeNotAvailable'))
        } else {
            const problem = getGeneralApiProblem(response.problem, response.status)
            yield put(ErrorActions.setError(response.status === 409 ? 'errors.timeNotAvailable' : problem))
        }
        yield put(CheckInActions.changeReservationFailure())
    }
}

export function* submitReservationChanges(
    api: ApiType,
    action: AnyAction,
): unknown {
    const { token, callbacks } = action
    const response: ApiResponse<any> = yield call(
        api.confirmReservationChanges,
        { token },
    )
    if (response.ok) {
        const { data } = response
        yield put(CheckInActions.submitReservationChangesSuccess(data))
        yield handleCallbacks(callbacks)
    } else if (response.problem) {
        const problem = getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(CheckInActions.submitReservationChangesFailure())
    }
}

export function* preventReservationChanges(
    api: ApiType,
    action: AnyAction,
): unknown {
    const { token, callbacks } = action
    const response: ApiResponse<any> = yield call(
        api.revertReservationChanges,
        { token },
    )
    if (response.ok) {
        const { data } = response
        yield put(CheckInActions.preventReservationChangesSuccess(data))
        yield handleCallbacks(callbacks)
    } else if (response.problem) {
        const problem = getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(CheckInActions.preventReservationChangesFailure())
    }
}

export function* giveFeedback(api: ApiType, action: AnyAction): unknown {
    const { feedbackText, grade, token, callbacks } = action
    const response: ApiResponse<any> = yield call(api.giveFeedback, {
        token,
        feedbackText,
        grade,
    })
    if (response.ok) {
        yield put(CheckInActions.giveFeedbackSuccess(response.data))
        yield handleCallbacks(callbacks)
    } else if (response.problem) {
        const problem = getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(CheckInActions.giveFeedbackFailure())
    }
}
