import { ApiResponse, CancelToken } from 'apisauce'
import { call, cancelled, put, select } from 'redux-saga/effects'
import { ApiType } from '../Services/api'
import ReservationActions, { ReservationState } from '../Redux/reservationRedux'
import ErrorActions from '../Redux/errorRedux'
import { getGeneralApiProblem } from '../Utils/reduxHelpers'
import { Coordinates, InspectStation, ServiceDateParams } from '../interfaces'
import { DateTime } from 'luxon'
import { get, uniqBy } from 'lodash'
import { UIState } from '../Redux/uiRedux'
import { CheckInState } from '../Redux/checkInRedux'
import Fuse from 'fuse.js'
import { RootState } from '../Redux'
import { handleCallbacks } from '../Utils/helpers'
import {VEHICLE_SEARCH_TYPE_DORIS} from '../Utils/const'

interface ActionType {
    type: string
}

type Callbacks = (() => void)[]

interface RegisterPlateAction extends ActionType {
    registerNumber: string
    vehicleType: number | null
    vehicleClass: string | null
    vehicleGroup: number | null
    fuel: string | null
    searchType: number | null
    stationId: number
    callbacks?: Callbacks
}

interface LocationAction extends ActionType {
    location: string
    chainId: number
    callbacks: Callbacks
    forwardFilter?: boolean
}

interface StationAction extends ActionType {
    coordinates?: Partial<Coordinates>
    chainId: number | string
    search?: string
    callbacks?: Callbacks
}

interface DateTimeAction extends ActionType {
    dateStart: DateTime
    dateEnd: DateTime | null
    chainId: number
    campaign: string | null,
    callbacks?: Callbacks
}

interface AnyAction extends ActionType {
    [key: string]: any
}

const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(() => resolve(undefined), ms))

export function* getRegisterInfo(
    api: ApiType,
    action: RegisterPlateAction,
): unknown {
    const { registerNumber, vehicleType, vehicleClass, vehicleGroup, fuel, searchType, stationId, callbacks } = action
    const { chainId } = yield select(
        ({ ui: { settings } }: { ui: UIState }) => ({
            chainId: settings?.chain?.id,
        }),
    )
    const isAtjSearch = searchType !== VEHICLE_SEARCH_TYPE_DORIS;
    const response: ApiResponse<any> = yield call(api.getRegisterInfo, {
        registerNumber,
        vehicleType,
        vehicleClass: vehicleClass ? {id: vehicleClass} : null,
        vehicleGroup: vehicleGroup ? {id: vehicleGroup} : null,
        engineType: fuel ? {id: fuel} : null,
        searchType,
        chainId,
        stationId,
    })
    if (response.ok) {
        const { data } = response
        if (data && !data.vehicleClass && isAtjSearch) {
            yield put(ReservationActions.shouldContactStation())
            yield put(ReservationActions.getRegisterInfoFailure())
            return
        }
        if (data) {
            yield put(ReservationActions.getRegisterInfoSuccess(data))
        }
        yield handleCallbacks(callbacks)
    } else if (response.problem) {
        if (!isAtjSearch) {
            yield handleCallbacks(callbacks)
            return
        } else if (response.status === 404 || response.status === 409 || response.status === 422 || response.status === 502) { // TODO: check correct status code
            yield put(ReservationActions.shouldContactStation())
            yield put(ReservationActions.getRegisterInfoFailure())
        } else {
            const problem = getGeneralApiProblem(
                response.problem,
                response.status,
            )
            yield put(ErrorActions.setError(problem))
            yield put(ReservationActions.getRegisterInfoFailure())
        }
    }
}

export function* getGeocodes(api: ApiType, action: LocationAction): unknown {
    const { chainId, location, callbacks, forwardFilter } = action
    const response: ApiResponse<
        { lat: string; lon: string; [key: string]: any }[]
    > = yield call(api.getGeocodes, location)
    if (response.ok) {
        const { data } = response
        const coordinates = get(data, '[0]')
        yield put(ReservationActions.getGeocodesSuccess(data))
        yield put(
            ReservationActions.getFilteredStations(
                chainId,
                coordinates,
                forwardFilter ? location : undefined,
                callbacks,
            ),
        )
    } else if (response.problem) {
        const problem = getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(ReservationActions.getGeocodesFailure())
    }
}

export function* getStations(api: ApiType, action: AnyAction): unknown {
    const cancelSource = CancelToken.source()
    const { token: cancelToken } = cancelSource
    try {
        const { chainId, stationId, callbacks } = action
        const response: ApiResponse<any> = yield call(
            api.getStations,
            { chainId, stationId },
            cancelToken,
        )
        if (response.ok) {
            const { data } = response
            yield put(ReservationActions.getStationsSuccess(data))
            yield handleCallbacks(callbacks)
        } else if (response.problem) {
            const problem = getGeneralApiProblem(
                response.problem,
                response.status,
            )
            yield put(ErrorActions.setError(problem))
            yield put(ReservationActions.getStationsFailure())
        }
    } finally {
        if (yield cancelled()) {
            cancelSource.cancel('SAGA_CANCEL')
        }
    }
}

export function* getFilteredStations(
    api: ApiType,
    action: StationAction,
): unknown {
    const cancelSource = CancelToken.source()
    const { token: cancelToken } = cancelSource
    try {
        const { chainId, coordinates, search, callbacks } = action

        const { allStations }: { allStations: InspectStation[] } = yield select(
            ({ reservation }: { reservation: ReservationState }) => ({
                allStations: reservation.stations ?? [],
            }),
        )

        const fuse = new Fuse(allStations, {
            keys: [
                'name',
                'address.city',
                'address.line1',
                'address.line2',
                'zipCode',
            ],
            threshold: 0.2,
        })
        let matches: InspectStation[] = []
        if (search) {
            matches = fuse.search(search).map((item) => item.item)
        }

        // Handle situation where no coordinates were given
        if (!coordinates) {
            yield put(ReservationActions.getFilteredStationsSuccess(matches))
        } else {
            // compose stations found by coordinates and keyword
            const { latitude, longitude } = coordinates
            const params: any = {
                search,
                chainId,
                coordinates: { latitude, longitude, radius: 30 },
            }
            const response: ApiResponse<any> = yield call(
                api.getStations,
                params,
                cancelToken,
            )
            if (response.ok) {
                const { data } = response
                const uniqueValues = uniqBy([...matches, ...data], 'id')
                yield put(
                    ReservationActions.getFilteredStationsSuccess(uniqueValues),
                )
            } else if (response.problem) {
                const problem = getGeneralApiProblem(
                    response.problem,
                    response.status,
                )
                yield put(ErrorActions.setError(problem))
                yield put(ReservationActions.getFilteredStationsFailure())
            }
        }
        yield handleCallbacks(callbacks)
    } finally {
        if (yield cancelled()) {
            cancelSource.cancel('SAGA_CANCEL')
        }
    }
}

export function* getServices(api: ApiType, action: AnyAction): unknown {
    const cancelSource = CancelToken.source()
    const { token: cancelToken } = cancelSource
    try {
        const { chainId, station, campaign, callbacks } = action
        const registerInfo = yield select(
            (root: RootState) =>
                root.reservation?.registerInfo ??
                root.checkIn.checkIn?.sale?.tasks?.[0]?.vehicle,
        )
        const vehicleGroupId = registerInfo?.vehicleGroup?.id
        const fuel = registerInfo?.engineType?.id
        const response: ApiResponse<any> = yield call(
            api.getServices,
            {
                chainId,
                campaign,
                fuel,
                vehicleGroupId,
                stationId: station.id,
            },
            cancelToken,
        )
        if (response.ok) {
            const { data } = response
            yield put(ReservationActions.getServicesSuccess(data, station))
            yield handleCallbacks(callbacks)
        } else if (response.problem) {
            const problem = getGeneralApiProblem(
                response.problem,
                response.status,
            )
            yield put(ErrorActions.setError(problem))
            yield put(ReservationActions.getServicesFailure())
        }
    } finally {
        if (yield cancelled()) {
            cancelSource.cancel('SAGA_CANCEL')
        }
    }
}

export function* getDates(api: ApiType, action: AnyAction): unknown {
    const { chainId, service, dateStart, dateEnd, callbacks } = action
    const state = yield select(
        ({ reservation }: { reservation: ReservationState }) => ({
            stationId: reservation.station?.id,
            vehicleGroupId: reservation.registerInfo?.vehicleGroup?.id || null,
        }),
    )
    const params: ServiceDateParams = {
        stationId: state.stationId,
        productId: service.id,
        vehicleGroupId: state.vehicleGroupId,
        campaign: null,
        dateStart: dateStart || DateTime.local().toISODate(),
        dateEnd: dateEnd || DateTime.local().plus({ days: 14 }).toISODate(),
        token: null,
    }
    const response: ApiResponse<any> = yield call(api.getDates, {
        dateParams: params,
        chainId,
    })
    if (response.ok) {
        const { data } = response
        yield put(ReservationActions.getDatesSuccess(data, service))
        yield handleCallbacks(callbacks)
    } else if (response.problem) {
        const problem = getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(ReservationActions.getDatesFailure())
    }
}

export function* getBestPrices(api: ApiType, action: AnyAction): unknown {
    const { chainId, service, campaign, dateStart, dateEnd, callbacks } = action
    const state = yield select(
        ({
            reservation,
            checkIn,
        }: {
            reservation: ReservationState
            checkIn: CheckInState
        }) => ({
            stationId: reservation.station?.id || checkIn.checkIn?.station?.id,
            vehicleGroupId:
                reservation.registerInfo?.vehicleGroup?.id ||
                get(checkIn, 'checkIn.sale.tasks[0].vehicle.vehicleGroup.id') ||
                null,
        }),
    )

    const oldBestPrices = yield select(
        (state: RootState) => state.reservation.bestPrices,
    )
    const oldServiceId = yield select(
        (state: RootState) => state.reservation.service?.id,
    )

    const defaultStartDate = dateStart ? null : DateTime.local().plus({days: 1}).startOf('week')
    const defaultEndDate = dateEnd ? null : DateTime.local().plus({days: 8}).endOf('week')

    const params: ServiceDateParams = {
        stationId: state.stationId,
        productId: service.id,
        vehicleGroupId: state.vehicleGroupId,
        campaign,
        dateStart: dateStart || defaultStartDate?.toISODate(),
        dateEnd: dateEnd || defaultEndDate?.toISODate(),
        token: null,
    }
    let response: ApiResponse<any> = yield call(api.getBestPriceDates, {
        dateParams: params,
        chainId,
    })
    if (defaultStartDate && defaultEndDate && response.ok && !response.data?.prices?.length) {
        params.dateStart = defaultStartDate.plus({weeks: 2}).toISODate()
        params.dateEnd = defaultEndDate.plus({weeks: 2}).toISODate()
        response = yield call(api.getBestPriceDates, {
            dateParams: params,
            chainId,
        })
    }
    if (response.ok) {
        const { data } = response
        if (oldBestPrices && oldServiceId && oldServiceId === service.id) {
            const periodStart = data?.period?.start ? DateTime.fromISO(data?.period?.start) : null
            const periodEnd = data?.period?.end ? DateTime.fromISO(data?.period?.end) : null
            const newBestPrices = [...oldBestPrices.prices.filter(p => periodStart && periodEnd && (DateTime.fromISO(p.time) < periodStart || DateTime.fromISO(p.time) > periodEnd)), ...data.prices]
            const _data = { ...data, prices: newBestPrices }
            yield put(ReservationActions.getBestPricesSuccess(_data, service))
        } else yield put(ReservationActions.getBestPricesSuccess(data, service))
        yield handleCallbacks(callbacks)
    } else if (response.problem) {
        const problem = getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(ReservationActions.getBestPricesFailure())
    }
}

export function* resetDateTimes(): any {
    yield put(ReservationActions.getDateTimesSuccess(null))
}

export function* getDateTimes(api: ApiType, action: DateTimeAction): unknown {
    const { chainId, campaign, dateStart, dateEnd, callbacks } = action
    const state = yield select(
        ({
            reservation,
            checkIn,
        }: {
            reservation: ReservationState
            checkIn: CheckInState
        }) => ({
            stationId: reservation.station?.id || checkIn.checkIn?.station?.id,
            vehicleGroupId:
                reservation.registerInfo?.vehicleGroup?.id ||
                get(checkIn, 'checkIn.sale.tasks[0].vehicle.vehicleGroup.id') ||
                null,
            service:
                reservation.service ||
                get(checkIn, 'checkIn.sale.tasks[0].basket.items[0]', {}),
            token: reservation?.reservation?.token || checkIn?.checkIn?.token
        }),
    )
    const params: ServiceDateParams = {
        stationId: state.stationId,
        productId: state.service.id,
        vehicleGroupId: state.vehicleGroupId,
        campaign,
        dateStart: dateStart.toISODate(),
        dateEnd: (dateEnd ?? dateStart).toISODate(),
        token: state.token,
    }
    const response: ApiResponse<any> = yield call(api.getDates, {
        dateParams: params,
        chainId,
    })
    if (response.ok) {
        const { data } = response
        yield put(ReservationActions.getDateTimesSuccess(data, state.service))
        yield handleCallbacks(callbacks)
    } else if (response.problem) {
        const problem = getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(ReservationActions.getDateTimesFailure())
    }
}

export function* setInspectDate(api: ApiType, action: AnyAction): unknown {
    const { callbacks, campaignCode, inspectDate, chainId } = action
    if (campaignCode) {
        yield put(
            ReservationActions.postReservation(
                inspectDate,
                chainId,
                campaignCode,
            ),
        )
    }
    yield handleCallbacks(callbacks)
}

export function* setInformation(api: ApiType, action: AnyAction): unknown {
    const { userInformation, callbacks } = action
    yield delay(100)
    yield put(ReservationActions.setInformationSuccess(userInformation))
    yield handleCallbacks(callbacks)
}

export function* setRegisterInfo(api: ApiType, action: AnyAction): unknown {
    const { callbacks } = action
    yield handleCallbacks(callbacks)
}

interface ReservationAction extends ActionType {
    chainId: number
    dateTime: DateTime
    campaignCode?: string
    callbacks?: Callbacks
}

export function* discardReservation(
    api: ApiType,
    action: ReservationAction,
): unknown {
    const state = yield select(
        ({
             reservation,
         }: {
            reservation: ReservationState
        }) => ({
            stationId: reservation.station?.id,
            token: reservation.reservation?.token,
        }),
    )

    const params = {
        reservation: {
            stationId: state.stationId,
            chainId: action.chainId,
        },
        token: state.token,
    }
    const { callbacks } = action
    const response: ApiResponse<any> = yield call(api.discardReservation, params)
    if (response.ok) {
        yield put(ReservationActions.discardReservationSuccess())
        yield handleCallbacks(callbacks)
    } else if (response.problem) {
        const problem = getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(ReservationActions.discardReservationFailure())
        yield handleCallbacks(callbacks)
    }
}

export function* postReservation(
    api: ApiType,
    action: ReservationAction,
): unknown {
    const state = yield select(
        ({
            reservation,
            checkIn,
        }: {
            reservation: ReservationState
            checkIn: CheckInState
        }) => ({
            product: {
                name: reservation.service?.name,
                productId: reservation.service?.id,
                code: null,
            },
            stationId: reservation.station?.id || checkIn.checkIn?.station?.id,
            reservation: reservation.reservation,
            vehicle: {
                registrationNumber:
                    reservation.registerInfo?.registrationNumber ??
                    get(
                        checkIn,
                        'checkIn.reservation.tasks[0].vehicle.registrationNumber',
                    ),
                vehicleGroup: reservation.registerInfo?.vehicleGroup,
                vehicleClass: reservation.registerInfo?.vehicleClass,
                engineType: reservation.registerInfo?.engineType,
            },
        }),
    )

    const params = {
        reservation: {
            stationId: state.stationId,
            chainId: action.chainId,
            campaignCodes: action.campaignCode
                ? [action.campaignCode]
                : undefined,
            tasks: [
                {
                    basket: {
                        items: [state.product],
                    },
                    event: {
                        duration: {
                            start: action.dateTime.toISO(),
                            end: action.dateTime.plus({ minutes: 15 }).toISO(),
                        },
                    },
                    vehicle: state.vehicle,
                },
            ],
        },
        token: state.reservation?.token,
    }
    let response: ApiResponse<any>
    const isAfterEnd =
        DateTime.local() > DateTime.fromISO(state.reservation?.valid?.end)
    if (state.reservation && state.reservation.token && !isAfterEnd) {
        response = yield call(api.putReservation, params)
    } else {
        response = yield call(api.postReservation, params)
    }
    if (response.ok) {
        const { data } = response
        yield put(ReservationActions.postReservationSuccess(data))
        yield handleCallbacks(action.callbacks)
    } else if (response.problem) {
        const problem = response.status === 409 ? 'errors.timeNotAvailable' : getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(ReservationActions.postReservationFailure())
    }
}

export function* postFinalReservation(
    api: ApiType,
    action: AnyAction,
): unknown {
    const { callbacks } = action
    const state = yield select(
        ({ reservation }: { reservation: ReservationState }) => ({
            product: {
                name: reservation.service?.name,
                productId: reservation.service?.id,
                code: null,
            },
            reservation: reservation.reservation,
            stationId: reservation.station?.id,
            vehicle: {
                registrationNumber:
                    reservation.registerInfo?.registrationNumber,
                vehicleGroup: reservation.registerInfo?.vehicleGroup,
                vehicleClass: reservation.registerInfo?.vehicleClass,
                engineType: reservation.registerInfo?.engineType,
            },
            userInformation: reservation.userInformation,
        }),
    )

    const inspectionReminder = []
    const marketingPermission = []

    const { userInformation } = state
    if (userInformation.offer?.textMessage) marketingPermission.push('sms')
    if (userInformation.offer?.emailMessage) marketingPermission.push('email')
    if (userInformation.reminder?.textMessage) inspectionReminder.push('sms')
    if (userInformation.reminder?.emailMessage) inspectionReminder.push('email')

    const chainId = get(state, 'reservation.reservation.chainId')
    const duration = get(
        state,
        'reservation.reservation.tasks[0].event.duration',
    )

    const dateNow = DateTime.local().toISODate()

    const params = {
        reservation: {
            stationId: state.stationId,
            chainId,
            tasks: [
                {
                    basket: {
                        items: [state.product],
                    },
                    event: {
                        duration,
                    },
                    vehicle: state.vehicle,
                },
            ],
            customer: {
                companyCustomer: false,
                organizationIdentifier: null,
                name: `${userInformation.firstName} ${userInformation.lastName}`,
                firstName: userInformation.firstName,
                lastName: userInformation.lastName,
                email: userInformation.email,
                phone: userInformation.phone,
                primaryAddress: {
                    city: userInformation.postOffice || null,
                    country: null,
                    line1: userInformation.postalAddress || null,
                    line2: null,
                    region: null,
                    zipCode: userInformation.postalCode || null,
                },
                inspection_reminder: inspectionReminder?.length > 0 ? inspectionReminder : ['default'],
                marketing_permission: marketingPermission?.length > 0 ? marketingPermission : ['default'],
                settings: {
                    language: userInformation.language,
                },
                vehicles: [
                    {
                        registrationNumber: state.vehicle.registrationNumber,
                        valid: {
                            start: dateNow,
                        },
                        type: userInformation.owner === 'owner' ? 1 : (userInformation.owner ? 2 : null)
                    },
                ],
            },
        },
        token: state.reservation?.token,
    }
    const isAddressEmpty = Object.values(
        params.reservation.customer.primaryAddress,
    ).every((x) => x === null || x === '')
    if (isAddressEmpty)
        (params.reservation.customer.primaryAddress as any) = null
    const response = yield call(api.postFinalReservation, params)
    if (response.ok) {
        yield handleCallbacks(callbacks)
        // Reset state to prevent broken page if user uses back button of browser
        yield put(ReservationActions.reset())
    } else if (response.problem) {
        const problem = getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(ReservationActions.postFinalReservationFailure())
    }
}
