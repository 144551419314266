import 'url-search-params-polyfill'
import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'

// eslint-disable-next-line
interface PaymentContainerProps extends RouteComponentProps {
}

export const PaymentContainer: FC<PaymentContainerProps> = ({ location, history }) => {
    const dispatch = useDispatch()
    const query = new URLSearchParams(location.search)
    const token = query.get('token')
    const paymentState = location.pathname.replace('/payment/', '').replace('thank-you/', '')

    useEffect(() => {
        if (token) history.push(`/check-in?token=${token}&paymentState=${paymentState}`)
    }, [dispatch, paymentState, history, token])

    return null
}
