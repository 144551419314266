import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RootState } from '../Redux/'
import ErrorActions from '../Redux/errorRedux'
import { useTranslation } from 'react-i18next'

export const Notification: FC = (): JSX.Element => {
    const errorMessage = useSelector((state: RootState) => state.error.message)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    // Handle error notifications
    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage.match(/errors\..+/) ? t(errorMessage) : errorMessage)
            dispatch(ErrorActions.clearError())
        }
    }, [errorMessage, dispatch, t])

    return (
        <ToastContainer
            style={{ whiteSpace: 'pre-line' }}
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    )
}
