import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { DateTime } from 'luxon'
import React, { FC } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    RegisterInfoSubmitEvent,
    RegisterSubmitEvent,
} from '../../Containers/RootContainer'
import { RootState } from '../../Redux'
import { ReservationState } from '../../Redux/reservationRedux'
import { NEW_REGISTRATION } from '../../Utils/const'
import { PrimaryButton } from '../Button'
import { ManualRegisterForm } from './ManualRegisterForm'
import { RegisterForm } from './RegisterForm'
import {PrefillValues} from '../../interfaces'

const useStyles = makeStyles()((theme: Theme) => ({
    content: {
        display: 'flex',
        marginTop: theme.spacing(2),
        padding: theme.spacing(0, 2, 2),
        flexDirection: 'column',
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentCenter: {
        justifyContent: 'center',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'stretch',
        marginTop: theme.spacing(3),
    },
    getInfoButton: {
        borderRadius: 24,
        color: theme.palette.grey[100],
        fontSize: theme.typography.pxToRem(16),
        height: 40,
        width: 250,
        minWidth: 150,
        fontWeight: 'bold',
    },
    infoBox: {
        marginBottom: theme.spacing(2),
        flex: 1,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        backgroundColor: '#eee',
        padding: theme.spacing(2),
        borderRadius: 8,
    },
    infoText: {
        color: theme.palette.grey[900],
        padding: 0,
        margin: 0,
    },
    nextButton: {
        borderRadius: 24,
        fontSize: theme.typography.pxToRem(16),
        height: 40,
        width: 250,
        minWidth: 150,
        '&:hover': {
            fontWeight: 'bold',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    registerInfo: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    registerInfoBoldText: {
        fontWeight: theme.typography.fontWeightBold,
    },
    registerInfoHeading: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(24),
    },
    table: {
        margin: theme.spacing(2, 0, 0),
        borderRadius: 12,
        boxShadow: theme.shadows[4],
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0, 0),
            padding: theme.spacing(2),
        },
    },
    tableCell: {
        padding: theme.spacing(1),
    },
    tableCellLeft: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            maxWidth: 160,
        },
    },
    tableRow: {
        width: '100%',
    },
    tableHead: {
        display: 'flex',
    },
    tableHeadRow: {
        display: 'flex',
        flex: 1,
    },
    tableHeadCell: {
        display: 'flex',
        flex: 1,
    },
}))

interface RegisterStep {
    prefill: PrefillValues
    form: any
    inputId: string
    fetching: ReservationState['fetching']
    validate: any
    registerInfo: any
    validateRegisterNumber: any
    onSubmitRegisterPlate: any
    onSubmitNewRegistration: (e: any) => void
    canGoToIndex: any
    goNext: any
    onSubmitRegisterInfo: any
    handleGetServices: any
}

export const RegisterStep: FC<RegisterStep> = ({
    prefill,
    inputId,
    fetching,
    validate,
    registerInfo,
    validateRegisterNumber,
    onSubmitRegisterPlate,
    onSubmitNewRegistration,
    canGoToIndex,
    goNext,
    onSubmitRegisterInfo,
    handleGetServices,
}) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const { chainId } = useSelector((state: RootState) => ({chainId: state.ui?.settings?.chain?.id, stationId: state.ui?.settings?.station?.id}))
    const fuels = useSelector((state: RootState) => state.ui.settings?.fuels)
    const vehicleGroups = useSelector((state: RootState) => state.ui.settings?.vehicleGroups)
    const atjEnabled = useSelector((state: RootState) => state.ui.settings?.booking?.atjEnabled)
    const vehicleMaxTotalMassM1 = useSelector((state: RootState) => state.ui.settings?.booking?.options?.vehicleMaxTotalMassM1)
    const vehicleMaxTotalMassM1Message = useSelector((state: RootState) => state.ui.settings?.booking?.options?.vehicleMaxTotalMassM1Message)
    const shouldContactStation = useSelector((state: RootState) => state.reservation.shouldContactStation)
    const campaignCode = useSelector((state: RootState) => state.reservation.campaignCode)
    const stationPhone = useSelector((state: RootState) => state.ui.settings?.station?.phone)
    const infoText = useSelector((state: RootState) => state.ui.settings?.booking?.options.reservationInfo)

    const renderRegisterInfo = () => {
        if (!registerInfo) return null
        return (
            <Table className={classes.table}>
                <TableBody>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell} colSpan={2}>
                            <Typography className={classes.registerInfoHeading}>
                                {t('rootContainer.vehicleInfo')}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCellLeft}>
                            <Typography
                                className={classes.registerInfoBoldText}
                            >
                                {t('rootContainer.registerNumber')}
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <Typography>
                                {registerInfo?.registrationNumber}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCellLeft}>
                            <Typography
                                className={classes.registerInfoBoldText}
                            >
                                {t('common.fuel')}
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <Typography>
                                {registerInfo?.engineType?.id
                                    ? t('common.fuel_' + (fuels?.find(f => f.id === registerInfo.engineType.id)?.name ?? 'unknown'))
                                    : '-'}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCellLeft}>
                            <Typography
                                className={classes.registerInfoBoldText}
                            >
                                {t('rootContainer.manufacturer')}
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <Typography>
                                {registerInfo?.manufacturer
                                    ? registerInfo.manufacturer
                                    : '-'}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCellLeft}>
                            <Typography
                                className={classes.registerInfoBoldText}
                            >
                                {t('rootContainer.model')}
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <Typography>
                                {registerInfo?.model
                                    ? registerInfo.model
                                    : '-'}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    {registerInfo?.nextAfterInspectionBefore ?
                        <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCellLeft}>
                                <Typography
                                    className={classes.registerInfoBoldText}
                                >
                                    {t('rootContainer.nextAfterInspectionBefore')}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography>
                                    {DateTime.fromISO(
                                        registerInfo.nextAfterInspectionBefore,
                                        {setZone: true}
                                    ).toFormat('dd.MM.yyyy')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        :
                        <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCellLeft}>
                                <Typography
                                    className={classes.registerInfoBoldText}
                                >
                                    {t('rootContainer.nextInspectionBefore')}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography>
                                    {registerInfo?.nextInspectionBefore
                                        ? DateTime.fromISO(
                                            registerInfo.nextInspectionBefore,
                                            {setZone: true}
                                        ).toFormat('dd.MM.yyyy')
                                        : '-'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        )
    }

    const handleSubmit = (e: RegisterSubmitEvent) => {
        if (e.unregistered && e.fuel && e.vehicleGroup) {
            onSubmitNewRegistration(e)
        }
        else onSubmitRegisterPlate(e)
    }

    const isVehicleOkForInspection = () => {
        if (chainId === 92) {
            if (registerInfo && DateTime.fromISO(registerInfo.registrationDate) >= DateTime.fromISO('2021-01-01')) {
                if (['M1', 'M1G', 'N1', 'N1G'].includes(registerInfo.vehicleClass?.id)) {
                    if (registerInfo.engineType?.id && registerInfo.engineType?.id !== '04') {
                        return t('rootContainer.vehicleNokConsumption');
                    }
                }
            }
        }
        if (vehicleMaxTotalMassM1 && registerInfo?.trafficMaxTotalMass && registerInfo?.trafficMaxTotalMass > vehicleMaxTotalMassM1) {
            if (['M1', 'M1G'].includes(registerInfo.vehicleClass?.id)) {
                return vehicleMaxTotalMassM1Message ?? t('rootContainer.vehicleNokMassM1');
            }
        }

        return null;
    }

    const vehicleNotOkForInspectionMessage = isVehicleOkForInspection()

    const findVehicleGroup = (id: string) => {
        return vehicleGroups?.find((group) => group.id === id)?.name
    }

    const renderAtjForm = () => {
        return (
            <div className={classes.columnContainer}>
                <Form
                    initialValues={prefill?.enabled ? {registerNumber: prefill.vehicle?.registerNumber ?? null} : null}
                    onSubmit={(e: RegisterSubmitEvent) =>
                        handleSubmit(e)
                    }
                    render={(props) => {
                        const unregistered: boolean = props.values.unregistered
                        const { values, submitting } = props
                        return (
                            <>
                                <div className={classes.rowContainer}>
                                    <RegisterForm
                                        inputId={inputId}
                                        {...props}
                                        fetching={fetching.registerInfo}
                                        validateRegisterNumber={
                                            validateRegisterNumber
                                        }
                                        validate={validate}
                                        registerInfo={registerInfo}
                                        renderSubmitRegisterPlate={() => <div className={classes.rowContainer}><PrimaryButton
                                            id="search-vehicle"
                                            type="submit"
                                            form="registerForm"
                                            className={classes.getInfoButton}
                                            disabled={
                                                unregistered ||
                                                !values?.registerNumber ||
                                                !validateRegisterNumber(
                                                    values?.registerNumber,
                                                ) ||
                                                submitting
                                            }
                                            fetching={fetching.registerInfo}
                                        >
                                            {t('rootContainer.getRegisterInfo')}
                                        </PrimaryButton></div>}
                                    />
                                </div>
                                {shouldContactStation && !unregistered &&
                                    <>
                                        <div className={classes.rowContainer}>
                                            <Typography>
                                                {t('rootContainer.couldNotFindRegister')}
                                            </Typography>
                                        </div>
                                        <div className={classes.rowContainer}>
                                            <Typography>
                                                {t('rootContainer.contactStation', { phone: stationPhone })}
                                            </Typography>
                                        </div>
                                    </>
                                }
                                {!unregistered &&
                                    <>
                                        <div className={classes.rowContainer}>
                                            {registerInfo?.registrationNumber !== NEW_REGISTRATION && renderRegisterInfo()}
                                        </div>
                                        { vehicleNotOkForInspectionMessage ?
                                            <div className={classes.rowContainer}>
                                                <Typography>
                                                    <br/>
                                                    {vehicleNotOkForInspectionMessage}
                                                </Typography>
                                            </div>
                                            :
                                            <div className={classes.contentCenter}>
                                                <PrimaryButton
                                                    id="next-vehicle"
                                                    disabled={(!canGoToIndex(1) && !unregistered) || !registerInfo?.vehicleClass || !registerInfo?.registrationNumber || registerInfo?.registrationNumber === NEW_REGISTRATION || !!shouldContactStation}
                                                    onClick={() => handleGetServices(goNext, campaignCode)}
                                                    className={classes.nextButton}
                                                >
                                                    {t('common.next')}
                                                </PrimaryButton>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        )
                    }}
                />
            </div>
        )
    }

    const renderManualForm = () => (
        <>
            <Form
                onSubmit={(e: RegisterInfoSubmitEvent) =>
                    onSubmitRegisterInfo(e, campaignCode)
                }
                validate={validate.manualRegisterForm}
                initialValues={prefill?.enabled ? {registrationNumber: prefill.vehicle?.registerNumber ?? null, fuel: prefill.vehicle?.fuel ?? null, vehicleGroup: findVehicleGroup(prefill.vehicle?.vehicleGroup)} : null}
                render={(props) => {
                    return (
                        <ManualRegisterForm
                            fetching={fetching.services}
                            inputId={inputId}
                            fuels={fuels}
                            vehicleGroups={vehicleGroups}
                            {...props}
                        />
                    )
                }}
            />
        </>
    )

    return (
        <div className={classes.content}>
            {!!infoText?.trim()?.length &&
                <div className={classes.infoBox}>
                    <p className={classes.infoText}>
                        {infoText}
                    </p>
                </div>
            }
            {atjEnabled ? renderAtjForm() : renderManualForm()}
        </div>
    )
}
