import 'url-search-params-polyfill'
import {
    AppBar,
    Backdrop,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Header } from '../Components/Header'
import { RootState } from '../Redux'
import {
    useSettings,
    useInterval,
    useMobile,
    useTitle,
} from '../Utils/hooks'
import { useTranslation } from 'react-i18next'
import {
    PrimaryButton,
    SecondaryButton,
    TextButton,
} from '../Components/Button'
import CheckInActions from '../Redux/checkInRedux'
import { get } from 'lodash'
import { AccordionItem } from '../Components/ServiceCardContainer/AccordionItem'
import { Currency } from '../Components/Currency'
import { DateTime, Duration } from 'luxon'
import ReservationActions from '../Redux/reservationRedux'
import { DateStep } from '../Components/RootContainer/DateStep'
import CloseIcon from '@mui/icons-material/Close'
import { ServiceStep } from '../Components/RootContainer/ServiceStep'
import { toast } from 'react-toastify'
import PaymentActions from '../Redux/paymentRedux'
import { FeedbackDialog } from '../Components/ServiceCardContainer/FeedbackDialog'
import {getLocale, getServiceTranslation, numberToCurrencyString} from '../Utils/helpers'
import { ICalendarLink } from '../Components/ServiceCardContainer/ICalendarLink'
import { InspectService } from '../interfaces'
import ErrorActions from '../Redux/errorRedux'
import {themeAssets, ThemeType} from '../Utils/themes'
import {TASK_COMPLETED_VALID_AFTER_MINUTES, TRAFICOM_FEE_CATEGORY_ID} from '../Utils/const'

interface ServiceCardContainerProps extends RouteComponentProps {
    themeName: string
    locale?: string
    embedded: boolean
    setGtmIds: (string) => void
}

interface StyleOptions extends ThemeType {
    isEmbedded: boolean,
}

const useStyles = makeStyles<StyleOptions>()((theme: Theme, options: StyleOptions) => ({
    root: {
        backgroundColor: options.isEmbedded ? options.backgroundColor ?? undefined : undefined,
        height: '100%',
    },
    accordionContainer: {
        margin: theme.spacing(0, 2),
        marginTop: theme.spacing(2),
        minWidth: 600,
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
        },
    },
    accordionContent: {
        flex: 1,
    },
    buttonColumnContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    button: {
        marginTop: 0,
        '&:disabled': {
            backgroundColor: theme.palette.grey[500],
        },
    },
    backdrop: {
        zIndex: 1000,
    },
    checkedInText: {
        color: '#fff',
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 'bold',
    },
    container: {
        maxWidth: 900,
        top: 200,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(0, 2, 3),
    },
    containerEmbedded: {
        padding: 0,
        maxWidth: 900,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    dialogContent: {
        padding: theme.spacing(2, 0),
    },
    documentDialog: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    paper: {
        margin: theme.spacing(2, 0),
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
    },
    text: {
        fontSize: theme.typography.pxToRem(14),
    },
    textButton: {
        color: theme.palette.secondary.main,
        textAlign: 'left',
        display: 'inline-block',
        width: 'fit-content',
    },
    textButtonText: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        fontSize: theme.typography.pxToRem(14),
    },
    timeReserved: {
        color: '#fff',
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 'bold',
    },
    accordionText: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.grey[900],
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    checkInHelpText: {
        fontSize: theme.typography.pxToRem(12),
        marginTop: theme.spacing(1),
        color: '#fff',
    },
}))

const minutesToHoursAndMinutes = (minutes: number) => {
    if (minutes <= 0) return ''
    let hours: any = Math.floor(minutes / 60)
    let remainingMinutes: any = minutes % 60
    if (remainingMinutes === 0) return `${hours}h`
    if (hours < 10) hours = '0' + hours
    if (remainingMinutes < 10) remainingMinutes = '0' + remainingMinutes
    if (hours === '00') return `${remainingMinutes}min`
    return `${hours}h ${remainingMinutes}min`
}

export const ServiceCardContainer: FC<ServiceCardContainerProps> = (props) => {
    const language = getLocale()
    const { location, locale = language } = props
    const { t } = useTranslation()
    const {
        address,
        bookingEnabled,
        isEmbedded,
        checkIn,
        customerInfo,
        feedbackGiven,
        fetching,
        chainId,
        stationId,
        iCal,
        reservation,
        taskComplete,
        taskCompletedAt,
        ui,
        cancelBefore,
        logo,
        wwwAddress,
        checkInOpenBefore,
        changeBefore,
        prePaymentBefore,
        allowCancel,
        allowChanges,
        allowCheckIn,
        allowCashPayment,
        allowOnlinePayment,
        taskState,
        stationMapLink,
    } = useSelector(
        ({ ui, checkIn, reservation }: RootState) => {
            const bookingOptions: any = ui.settings?.booking?.options ?? {}
            return {
                bookingEnabled: ui.settings?.booking?.enabled,
                customerInfo: checkIn.checkIn?.sale?.customer,
                ui,
                logo: ui.settings?.station?.logo?.data || ui.settings?.chain?.logo?.data || undefined,
                wwwAddress: ui.settings?.station?.www || ui.settings?.chain?.www || undefined,
                checkIn,
                chainId: checkIn.checkIn?.chain?.id,
                stationId: checkIn.checkIn?.station?.id,
                fetching: checkIn.fetching,
                reservation,
                isEmbedded: ui.options.embedded || props.embedded,
                taskComplete: checkIn.checkIn?.sale?.taskState === 4,
                taskCompletedAt: DateTime.fromISO(
                    get(checkIn, 'checkIn.sale.completedAt'),
                    {zone: 'utc'}
                ),
                address: checkIn.checkIn?.station?.address,
                iCal: {
                    title: t('serviceCardContainer.inspection'),
                    description: '',
                    startTime: DateTime.fromISO(
                        get(checkIn, 'checkIn.sale.tasks[0].event.duration.start'),
                        {setZone: true}
                    ),
                    endTime: DateTime.fromISO(
                        get(checkIn, 'checkIn.sale.tasks[0].event.duration.end'),
                        {setZone: true}
                    ),
                    location: `${get(checkIn, 'checkIn.station.name')}, ${get(checkIn, 'checkIn.station.address.line1')}, ${get(checkIn, 'checkIn.station.address.zipCode')} ${get(checkIn, 'checkIn.station.address.city')}`,
                },
                cancelBefore: bookingOptions.cancelBefore,
                changeBefore: bookingOptions.changeBefore,
                allowCancel: bookingOptions.allowCancel || false,
                allowChanges: bookingOptions.allowChanges || false,
                allowCashPayment: bookingOptions.allowCashPayment || false,
                allowCheckIn: bookingOptions.allowCheckIn || false,
                allowOnlinePayment: bookingOptions.allowOnlinePayment || false,
                checkInOpenBefore: bookingOptions.checkInOpenBefore || 30,
                prePaymentBefore: bookingOptions.prePaymentBefore,
                feedbackGiven: checkIn.checkIn?.sale?.feedback?.length > 0,
                taskState: checkIn.checkIn?.sale?.taskState,
                stationMapLink: bookingOptions.mapLink ?? null,
            }
        },
    )

    const traficomFee = useSelector((state: RootState) => state.checkIn?.checkIn?.reservation?.tasks?.map(
        (task) => task?.basket?.items?.map(
            (basketItem) => basketItem?.categoryId === TRAFICOM_FEE_CATEGORY_ID ? basketItem?.price?.value : null
        ).filter(fee => !! fee).reduce((total, fee) => total + Number.parseFloat(fee), 0)
    ).reduce((total, fee) => total + Number.parseFloat(fee), 0))

    const dispatch = useDispatch()
    const [serviceDialogOpen, setServiceDialogOpen] = useState(false)
    const [dateDialogOpen, setDateDialogOpen] = useState(false)
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
    const [documentDialogOpen, setDocumentDialogOpen] = useState<boolean>(false)
    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false)
    const [date, setDate] = useState<DateTime>(DateTime.now().startOf('day'))
    const [timeIsSet, setTimeIsSet] = useState(false)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [checkInDialog, setCheckInDialog] = useState(false)
    const themeOptions: StyleOptions = {isEmbedded, ...get(themeAssets, ui.options?.themeName || 'default'), ...(ui.options?.theme ?? {})}
    const { classes } = useStyles(themeOptions)
    const isMobile = useMobile('sm')
    const [service, setService] = useState<any>()
    const centerMobile: any = isMobile ? { align: 'center' } : {}
    const [timeUntilStart, setTimeUntilStart] = useState<Duration>()
    const [paymentStarted, setPaymentStarted] = useState<boolean>(false)

    useSettings(chainId, stationId, props.history, props.embedded)
    const title = useTitle()

    useEffect(() => {
        if (taskComplete && !feedbackGiven) setFeedbackDialogOpen(true)
    }, [setFeedbackDialogOpen, taskComplete, feedbackGiven])

    // Intervals as minutes
    const interval = {
        checkIn: checkInOpenBefore,
        serviceChange: changeBefore,
        dateChange: changeBefore,
        cancel: cancelBefore,
        prePayment: prePaymentBefore,
    }

    const timeUntilStartMinutes = timeUntilStart?.as('minutes')
    const disabledConditions = {
        prePaymentBefore: !timeUntilStartMinutes || timeUntilStartMinutes < interval.prePayment,
        checkIn:
            !timeUntilStartMinutes ||
            timeUntilStartMinutes > interval.checkIn ||
            timeUntilStartMinutes <= 0,
        serviceChange: !timeUntilStartMinutes || timeUntilStartMinutes < interval.serviceChange,
        dateChange: !timeUntilStartMinutes || timeUntilStartMinutes < interval.dateChange,
        cancel: !timeUntilStartMinutes || timeUntilStartMinutes < interval.cancel,
    }

    const query = new URLSearchParams(location.search)
    const token: string = query.get('token') ?? ''

    const eventStart = DateTime.fromISO(
        get(checkIn, 'checkIn.sale.tasks[0].event.duration.start'),
        {setZone: true}
    )

    const isFullyPaid = checkIn.checkIn?.sale?.totalPrice?.vatIncluded > 0 && checkIn.checkIn?.sale?.remainingPrice?.vatIncluded <= 0
    const isFullyRefunded = checkIn.checkIn?.sale?.totalPrice?.vatIncluded <= 0 && checkIn.checkIn?.sale?.remainingPrice?.vatIncluded <= 0 && checkIn.checkIn.sale.tasks?.length > 0
    const isPaymentInProgress = (isFullyPaid || isFullyRefunded) && (checkIn.checkIn.sale.hasPendingPayment || checkIn.checkIn?.sale?.remainingPrice?.vatIncluded != 0)
    const hasProvisionalUpdate = checkIn.checkIn?.confirmIn > 0

    const isCancelled = checkIn.checkIn?.sale?.tasks?.length < 1 || get(checkIn, 'checkIn.sale.tasks[0].event.eventType') <= 0
    const isCheckedIn = taskState === 3
    const inspectIsReady = taskState === 4
    const certificateIsAvailable = taskCompletedAt && DateTime.utc().diff(taskCompletedAt, 'minute')?.minutes > TASK_COMPLETED_VALID_AFTER_MINUTES

    const [delay, setDelay] = useState<number | null>(1000)
    const paymentState = query.get('paymentState')
    const paymentFailed = paymentState === 'cancel'

    const campaignCodes = (checkIn.checkIn?.sale?.campaignCodes ?? checkIn.checkIn?.reservation?.campaignCodes ?? []).join(';')

    const vehicleInfo = get(checkIn, 'checkIn.sale.tasks[0].vehicle')
    const serviceInfo = get(
        checkIn,
        'checkIn.sale.tasks[0].basket.items[0]',
    )
    const reservedServiceInfo = get(
        checkIn,
        'checkIn.reservation.tasks[0].basket.items[0]',
    )
    const stationInfo = checkIn.checkIn?.station
    const priceInfo = checkIn.checkIn?.prices

    useEffect(() => {
        if (paymentFailed) {
            dispatch(ErrorActions.setError(t('serviceCardContainer.paymentFailed')))
        }
        if (paymentState) {
            props.history.push({
                pathname: '/check-in',
                search: `?token=${token}`
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useInterval(() => {
        if (eventStart) {
            if (checkIn.checkIn) {
                const dateTimes = {
                    local: DateTime.fromISO(checkIn.checkIn.localTimestamp),
                    api: DateTime.fromISO(checkIn.checkIn.apiTimestamp),
                }
                const diff = dateTimes.api.diff(dateTimes.local)
                const millisecondsDiff = diff.as('milliseconds')
                if (millisecondsDiff) {
                    const difference = eventStart.diff(
                        DateTime.local().plus({
                            milliseconds: millisecondsDiff,
                        }),
                    )
                    if (difference !== timeUntilStart)
                        setTimeUntilStart(difference)

                    // Stop after time is out
                    if (difference.as('seconds') <= 0) {
                        setDelay(null)
                    }
                }
            }
        }
    }, delay)

    useInterval(() => {
        if (token && !fetching.checkIn)
            dispatch(CheckInActions.getCheckIn(token, null))
    }, inspectIsReady ? null : 20000)

    useEffect(() => {
        const errorCallback = () => props.history.push('/error')
        if (token && !fetching.checkIn)
            dispatch(CheckInActions.getCheckIn(token, errorCallback))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filteredServices = useMemo(() => {
        return reservation.services?.filter(item => item.name !== serviceInfo?.name) || null
    }, [reservation.services])

    const openGoogleMaps = () => {
        const coordinates = stationInfo?.coordinates

        if (stationMapLink) {
            if (stationMapLink.startsWith('http://') || stationMapLink.startsWith('https://')) {
                window.open(stationMapLink, '_blank')
            } else {
                window.open('https://maps.app.goo.gl/' + stationMapLink, '_blank')
            }
        } else if (coordinates?.latitude && coordinates?.longitude) {
            window.open(`https://www.google.com/maps?q=${coordinates?.latitude},${coordinates?.longitude}`, '_blank')
        } else if (address) {
            window.open(`https://www.google.com/maps?q=${address.line1},${address.zipCode},${address.city}`, '_blank')
        }
    }

    const handleCancelReservation = () => {
        const callback = () =>
            props.history.push(`/chain/${chainId}/${stationId}/cancelled-reservation?token=${token}`)
        dispatch(CheckInActions.cancelReservation(token, [callback]))
    }

    const renderStationInfo = () => (
        <div className={classes.accordionContent}>
            <Typography className={classes.text}>
                {stationInfo?.address?.line1}
            </Typography>
            <Typography className={classes.text}>
                {stationInfo?.address ? `${stationInfo.address?.zipCode} ${stationInfo.address?.city}` : ''}
            </Typography>
            <TextButton onClick={openGoogleMaps}>
                {t('serviceCardContainer.showOnMap')}
            </TextButton>
        </div>
    )

    const renderUserInfo = () => (
        <div className={classes.accordionContent}>
            <Typography className={classes.text}>
                {customerInfo?.phone}
            </Typography>
            <Typography className={classes.text}>
                {vehicleInfo?.registrationNumber}
            </Typography>
            <Typography className={classes.text}>
                {customerInfo?.email}
            </Typography>
            <Typography className={classes.text}>
                {customerInfo?.primaryAddress?.line1}
            </Typography>
            {customerInfo?.primaryAddress && (
                <Typography className={classes.text}>
                    {customerInfo.primaryAddress.zipCode}{' '}
                    {customerInfo.primaryAddress.city}
                </Typography>
            )}
        </div>
    )

    const handleCancel = () => {
        setCancelDialogOpen(false)
    }

    const handleOk = () => {
        setCancelDialogOpen(false)
        handleCancelReservation()
    }

    const handleOpenDialog = () => {
        setCancelDialogOpen(true)
    }

    const handleCheckIn = () => {
        if (!fetching.checkIn) {
            handleCloseCheckInDialog()
            dispatch(CheckInActions.checkIn(token, []))
        }
    }

    const handleCloseDateDialog = () => {
        setDateDialogOpen(false)
    }

    const handleCloseServiceDialog = () => {
        setServiceDialogOpen(false)
    }

    const handleOpenCheckInDialog = () => {
        setCheckInDialog(true)
    }

    const handleCloseCheckInDialog = () => {
        setCheckInDialog(false)
    }

    const renderDialog = () => (
        <Dialog
            aria-labelledby="confirmation-dialog-title"
            open={cancelDialogOpen}
        >
            <DialogTitle id="confirmation-dialog-title">
                {t('serviceCardContainer.confirmCancelReservation')}
            </DialogTitle>
            <DialogContent>
                {t('serviceCardContainer.confirmParagraph')}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    {t('common.no')}
                </Button>
                <Button onClick={handleOk} color="primary">
                    {t('common.yes')}
                </Button>
            </DialogActions>
        </Dialog>
    )

    const renderCheckInDialog = () => {
        return (
            <Dialog
                aria-labelledby="confirmation-dialog-title"
                open={checkInDialog}
            >
                <DialogTitle id="confirmation-dialog-title">
                    {t('serviceCardContainer.confirmCheckIn')}
                </DialogTitle>
                <DialogContent>
                    {t('serviceCardContainer.confirmCheckInContent')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCheckInDialog} color="primary">
                        {t('common.no')}
                    </Button>
                    <Button onClick={handleCheckIn} color="primary">
                        {t('common.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const getInspectDateTimes = (
        dateStart: DateTime | null,
        dateEnd: DateTime | null,
        campaign: string | null,
        callback?: () => void,
    ) => {
        if (dateStart)
            dispatch(ReservationActions.getDateTimes(chainId, campaign, dateStart, dateEnd, [callback]))
    }

    const handleDateSubmit = () => {
        const cb = handleCloseDateDialog
        const dateSubmitOk = () =>
            toast.success(
                t('serviceCardContainer.dateSubmitOk', {
                    time: date
                        .setLocale(locale)
                        .toFormat(`cccc dd.MM.yyyy '${t('common.time_of_day')}' HH:mm`), //eslint-disable-line
                }),
            )
        if (date)
            dispatch(
                CheckInActions.submitReservationChanges(token, [
                    cb,
                    dateSubmitOk,
                ]),
            )
    }

    const handleServiceSubmit = () => {
        const cb = handleCloseServiceDialog
        const serviceSubmitOk = () =>
            toast.success(
                t('serviceCardContainer.serviceSubmitOk', {
                    service: getServiceTranslation('name', service),
                }),
            )
        if (service)
            dispatch(
                CheckInActions.submitReservationChanges(token, [
                    cb,
                    serviceSubmitOk,
                ]),
            )
    }

    const handleDateChange = (date: DateTime) => {
        setDate(date)
        setTimeIsSet(false)
    }

    const handleTimeChange = (date: DateTime) => {
        if (!reservation.fetching.reservation) {
            const callbacks = []
            callbacks.push(() => setDate(date))
            if (!timeIsSet) callbacks.push(() => setTimeIsSet(true))
            dispatch(
                CheckInActions.changeReservation(token, { date }, callbacks),
            )
            // handleDateSubmit(date)
        }
    }

    const handleServiceChange = (service: any) => {
        if (!reservation.fetching.reservation) {
            const callbacks = []
            callbacks.push(() => setService(service))
            dispatch(
                CheckInActions.changeReservation(token, { service }, callbacks),
            )
        }
    }

    const handleRevertUpdate = (handleCloseDialog: () => void) => {
        if (hasProvisionalUpdate) {
            if (!reservation.fetching.reservation) {
                dispatch(CheckInActions.preventReservationChanges(token, [handleCloseDialog]))
            }
        } else {
            handleCloseDialog()
        }
    }

    const setInspectDate = (
        inspectDate: DateTime | null,
        campaignCode: string,
        callback: () => void,
    ) => () => {
        if (inspectDate && chainId) {
            dispatch(ReservationActions.setInspectDate(inspectDate, campaignCode || null, chainId, [callback]))
        }
    }

    const handleOpenServiceDialog = () => {
        const callback = () => setServiceDialogOpen(true)
        const station = get(checkIn, 'checkIn.station')
        if (station)
            dispatch(
                ReservationActions.getServices(chainId, station, campaignCodes, [callback]),
            )
    }

    const renderServiceChange = () => {
        return (
            <Dialog
                open={serviceDialogOpen}
                fullWidth
                onClose={() => handleRevertUpdate(handleCloseServiceDialog)}
                scroll="body"
                aria-labelledby="service-dialog-title"
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleRevertUpdate(handleCloseServiceDialog)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            className={classes.title}
                            id="service-dialog-title"
                        >
                            {t('serviceCardContainer.changeService')}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <ServiceStep
                        forceMobile
                        canGoToIndex={() => true}
                        services={filteredServices}
                        fetching={reservation.fetching}
                        setService={handleServiceChange}
                        activeService={service}
                        goNext={() => null}
                        onGetBestPrices={handleServiceSubmit}
                        contentStyle={classes.dialogContent}
                        nextButtonText={t('common.accept')}
                    />
                </DialogContent>
            </Dialog>
        )
    }

    const renderDateChange = () => {
        return (
            <Dialog
                fullScreen={isMobile}
                open={dateDialogOpen}
                fullWidth
                onClose={() => handleRevertUpdate(handleCloseDateDialog)}
                scroll="body"
                aria-labelledby="date-dialog-title"
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleRevertUpdate(handleCloseDateDialog)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            className={classes.title}
                            id="date-dialog-title"
                        >
                            {t('serviceCardContainer.changeTime')}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DateStep
                        forceMobile={true}
                        nextButtonText={t('common.accept')}
                        disableCheapest
                        date={date}
                        bestPrices={reservation.bestPrices}
                        handleDateChange={handleDateChange}
                        getDateTimes={getInspectDateTimes}
                        dateTimes={reservation.dateTimes}
                        fetching={{...reservation.fetching, ...checkIn.fetching}}
                        handleTimeChange={handleTimeChange}
                        showDatePicker={showDatePicker}
                        timeIsSet={timeIsSet}
                        setInspectDate={setInspectDate}
                        setShowDatePicker={setShowDatePicker}
                        goNext={handleDateSubmit}
                        onGetBestPrices={handleGetBestPrices}
                        canGoToIndex={() => true}
                        contentStyle={classes.dialogContent}
                        disableCampaignCode
                    />
                </DialogContent>
            </Dialog>
        )
    }

    const handleGetBestPrices = (
        service: InspectService,
        campaign: string | null,
        dateStart: string | undefined,
        dateEnd: string | undefined,
        callback?: () => void,
    ) => {
        if (dateStart && dateEnd)
            dispatch(
                ReservationActions.getBestPrices(
                    chainId,
                    service,
                    campaign ?? campaignCodes,
                    DateTime.fromISO(dateStart).toISODate(),
                    DateTime.fromISO(dateEnd).toISODate(),
                    [callback],
                ),
            )
        else
            dispatch(
                ReservationActions.getBestPrices(
                    chainId,
                    service,
                    campaign ?? campaignCodes,
                    undefined,
                    undefined,
                    [callback],
                ),
            )
    }

    const openDateDialog = () => {
        const cb = () => setDateDialogOpen(true)
        const service = serviceInfo
        dispatch(
            ReservationActions.getBestPrices(
                chainId,
                { ...service, id: service.productId },
                campaignCodes,
                undefined,
                undefined,
                [
                    () => getInspectDateTimes(DateTime.local().startOf('day'), DateTime.local().endOf('week').startOf('day'), campaignCodes),
                    cb,
                ],
            ),
        )
    }

    const helperWrapper = (el: JSX.Element | JSX.Element[], helperText: string, showHelper?: boolean) => {
        if (!showHelper) return el
        if (isMobile) return (
            <>
                {el}
                <Typography
                    className={classes.checkInHelpText}
                >
                    {helperText}
                </Typography>
            </>
        )
        else
            return (
                <Tooltip placement="bottom-start" title={helperText} aria-label={helperText}>
                    <div>
                        {el}
                    </div>
                </Tooltip>
            )

    }

    const handlePayment = () => {
        setPaymentStarted(true)
        dispatch(PaymentActions.createPayment(token))
        setTimeout(() => setPaymentStarted(false), 10000)
    }

    const renderReservationInfo = () => {
        const date = DateTime.fromISO(
            get(checkIn, 'checkIn.sale.tasks[0].event.duration.start'),
            {setZone: true}
        ).setLocale(locale)
        const helper = t('serviceCardContainer.changeTimeTooltip', { time: minutesToHoursAndMinutes(interval.dateChange) })
        return (
            <>
                <div className={classes.row}>
                    <Typography className={classes.text}>
                        {
                            date.isValid && date.toFormat('cccc dd.MM.yyyy') // eslint-disable-line
                        }
                    </Typography>
                    {iCal.startTime.isValid && iCal.endTime.isValid &&
                        <Tooltip title={t('serviceCardContainer.createCalendarEvent') as string}>
                            <div>
                                <ICalendarLink
                                    {...iCal}
                                />
                            </div>
                        </Tooltip>
                    }
                </div>
                {date.isValid &&
                    <Typography className={classes.text}>
                        {t('serviceCardContainer.clock')} {date.toFormat('HH:mm')}
                    </Typography>
                }
                {!isFullyPaid && allowChanges &&
                    <div className={classes.row}>
                        <Tooltip arrow placement="bottom-start" title={helper} aria-label={helper}>
                            <div>
                                <TextButton
                                    onClick={openDateDialog}
                                    disabled={disabledConditions.dateChange}
                                >
                                    {t('serviceCardContainer.changeTime')}
                                </TextButton>
                            </div>
                        </Tooltip>
                    </div>
                }
            </>
        )
    }

    const renderServiceInfo = () => {
        if (isPaymentInProgress) {
            return <div><b>
                {isFullyRefunded ? t('serviceCardContainer.refundInProgress') : t('serviceCardContainer.paymentInProgress')}
            </b></div>
        } else if (isFullyRefunded) {
            return <div><b>
                {t('serviceCardContainer.paymentRefunded')}
            </b></div>
        } else if (isFullyPaid) {
            return <div>
                {t('serviceCardContainer.paymentFulfilled',
                    {
                        price: numberToCurrencyString(checkIn.checkIn?.sale?.totalPrice?.vatIncluded),
                        vat: numberToCurrencyString(checkIn.checkIn?.sale?.totalPrice?.vat)
                    })}
            </div>
        } else if (isCancelled) {
            return <div>
                {t('serviceCardContainer.reservationCancelled')}
            </div>
        }
        const helper = t('serviceCardContainer.changeServiceTooltip', { time: minutesToHoursAndMinutes(interval.dateChange) })
        return (
            <div>
                {
                    allowOnlinePayment && <Typography className={classes.accordionText}>
                        {t('serviceCardContainer.prepaidPrice')}{' '}
                        <Currency value={priceInfo?.prepaid?.value ?? checkIn.checkIn?.sale?.totalPrice?.vatIncluded} />
                    </Typography>
                }
                {
                    allowCashPayment && <Typography className={classes.accordionText}>
                        {t('serviceCardContainer.normalPrice')}{' '}
                        <Currency value={priceInfo?.online?.value ?? checkIn.checkIn?.sale?.totalPrice?.vatIncluded} />
                    </Typography>
                }
                {traficomFee > 0 ? (
                    <Typography className={classes.accordionText}>
                        {t('rootContainer.priceIncludesTraficomFee', { price: numberToCurrencyString(traficomFee) })}
                    </Typography>
                ) : null}
                {allowChanges && ! isCheckedIn && ! inspectIsReady &&
                    <div className={classes.row}>
                        <Tooltip arrow placement="bottom-start" title={helper} aria-label={helper}>
                            <div>
                                <TextButton
                                    onClick={handleOpenServiceDialog}
                                    disabled={disabledConditions.serviceChange}
                                >
                                    {t('serviceCardContainer.changeService')}
                                </TextButton>
                            </div>
                        </Tooltip>
                    </div>
                }
            </div>
        )
    }

    const renderCheckIn = () => {
        const helper = t(
            'serviceCardContainer.checkInHelperText', { time: minutesToHoursAndMinutes(interval.checkIn) }
        )
        const paymentHelper = t('serviceCardContainer.paymentHelperText', { time: minutesToHoursAndMinutes(interval.prePayment) })
        const serviceName = getServiceTranslation('name', serviceInfo ?? reservedServiceInfo)

        if (isCancelled) {
            return (
                <>
                    <Grid item xs={12} sm={12} md={6}>
                        <AccordionItem
                            heading={t('serviceCardContainer.reservationDate')}
                            subHeading={t('serviceCardContainer.reservationCancelled')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <AccordionItem
                            heading={t('common.service')}
                            subHeading={serviceName}
                            content={renderServiceInfo()}
                        />
                    </Grid>
                </>
            )
        }
        return (
            <>
                {inspectIsReady ? (
                    <>
                        <Grid item xs={12} sm={12} md={6}>
                            <AccordionItem
                                heading={t('serviceCardContainer.reservationDate')}
                                subHeading={t('serviceCardContainer.inspectReady')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <AccordionItem
                                heading={t('common.service')}
                                subHeading={serviceName}
                                content={renderServiceInfo()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} {...centerMobile}>
                        </Grid>
                    </>
                ) : isCheckedIn ? (
                    <>
                        <Grid item xs={12} sm={12} md={6}>
                            <AccordionItem
                                heading={t('serviceCardContainer.reservationDate')}
                                subHeading={t('serviceCardContainer.checkedIn', {
                                    time: DateTime.fromISO(
                                        get(
                                            checkIn,
                                            'checkIn.sale.tasks[0].event.duration.start',
                                        ),
                                        {setZone: true}
                                    ).toFormat('HH:mm'),
                                })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <AccordionItem
                                heading={t('common.service')}
                                subHeading={serviceName}
                                content={renderServiceInfo()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} {...centerMobile}>
                        </Grid>
                    </>
                ) : checkIn.checkInFailed ? (
                    <>
                        <Grid item xs={12} sm={12} md={6}>
                            <AccordionItem
                                heading={t('serviceCardContainer.reservationDate')}
                                subHeading={t('serviceCardContainer.checkInFailed')}
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} sm={12} md={6}>
                            <AccordionItem
                                heading={t(
                                    'serviceCardContainer.reservationDate',
                                )}
                                content={renderReservationInfo()}
                            />
                        </Grid>
                        {isMobile ? (
                            <>
                                <Grid item xs={12} sm={12} md={6} {...centerMobile}>
                                    {allowCheckIn && <PrimaryButton
                                        disabled={disabledConditions.checkIn}
                                        onClick={handleOpenCheckInDialog}
                                        className={classes.button}
                                    >
                                        {t('serviceCardContainer.checkIn')} MOB
                                    </PrimaryButton>}
                                    {allowCheckIn && disabledConditions.checkIn && (
                                        <Typography
                                            className={classes.checkInHelpText}
                                        >
                                            {t(
                                                'serviceCardContainer.checkInHelperText', { time: minutesToHoursAndMinutes(interval.checkIn) }
                                            )}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <AccordionItem
                                        heading={t('common.service')}
                                        subHeading={serviceName}
                                        content={renderServiceInfo()}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12} sm={12} md={6}>
                                    <AccordionItem
                                        heading={t('common.service')}
                                        subHeading={serviceName}
                                        content={renderServiceInfo()}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    {allowCheckIn && <Tooltip placement="bottom-start" title={helper} aria-label={helper}>
                                        <div>
                                            <PrimaryButton
                                                disabled={disabledConditions.checkIn}
                                                onClick={handleOpenCheckInDialog}
                                                className={classes.button}
                                            >
                                                {t('serviceCardContainer.checkIn')}
                                            </PrimaryButton>
                                        </div>
                                    </Tooltip>}
                                </Grid>
                            </>
                        )}
                    </>
                )
                }
                <Grid item xs={12} sm={12} md={6} {...centerMobile}>
                    {!isFullyPaid && allowOnlinePayment && !checkIn.checkInFailed &&
                        helperWrapper(
                            <PrimaryButton
                                disabled={isFullyPaid || (!inspectIsReady && disabledConditions.prePaymentBefore) || paymentStarted || isPaymentInProgress}
                                onClick={handlePayment}
                                className={classes.button}
                            >
                                {t('serviceCardContainer.payNow')}
                            </PrimaryButton>
                            , paymentHelper, !inspectIsReady && disabledConditions.prePaymentBefore)
                    }
                </Grid>
            </>
        )
    }

    const openDocumentDialog = () => {
        setDocumentDialogOpen(true)
    }

    const handleCloseDocumentDialog = () => {
        setDocumentDialogOpen(false)
    }

    const downloadCertificate = () => {
        dispatch(CheckInActions.getCertificate(token))
    }

    const downloadReceipt = () => {
        dispatch(CheckInActions.getReceipt(token))
    }

    const renderDocumentDialog = () => {
        return (
            <Dialog
                open={documentDialogOpen}
                fullWidth
                onClose={handleCloseDocumentDialog}
                scroll="body"
                aria-labelledby="report-dialog-title"
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseDocumentDialog}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            className={classes.title}
                            id="report-dialog-title"
                        >
                            {t('serviceCardContainer.documents')}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div className={classes.documentDialog}>
                        {inspectIsReady && certificateIsAvailable && <Button variant="text" onClick={downloadCertificate}>{t('serviceCardContainer.downloadCertificate')}</Button>}
                        {(isFullyPaid || isFullyRefunded) && <Button variant="text" onClick={downloadReceipt}>{t('serviceCardContainer.downloadReceipt')}</Button>}
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    const handleFeedbackSubmit = ({ grade, feedbackText }: { grade: number | undefined, feedbackText: string | undefined }) => {
        const callbacks = []
        callbacks.push(() => setFeedbackDialogOpen(false))
        callbacks.push(() => toast.success(
            t('serviceCardContainer.feedbackSubmitOk')
        ))

        if (grade) dispatch(CheckInActions.giveFeedback(grade, feedbackText, token, callbacks))
    }

    const cancelHelper = t('serviceCardContainer.cancelHelperText', { time: minutesToHoursAndMinutes(interval.cancel) })
    const reportHelper = t('serviceCardContainer.reportHelperText')

    if (bookingEnabled === undefined) {
        return (
            <Backdrop className={classes.backdrop} open={true} style={{ backgroundColor: 'white' }}>
                <CircularProgress size={48} color="primary" />
            </Backdrop>
        )
    }

    return (
        <div className={classes.root}>
            <Helmet>
                <html lang={language} />
                <title>{`${title} - ${t('serviceCardContainer.serviceCard')}`}</title>
            </Helmet>
            <Header title={title} display={!isEmbedded} logo={logo} wwwAddress={wwwAddress} />
            <Container
                className={
                    isEmbedded ? classes.containerEmbedded : classes.container
                }
            >
                <Grid
                    container
                    className={classes.accordionContainer}
                    spacing={2}
                >
                    {renderCheckIn()}
                    <Grid item xs={12} sm={12} md={6}>
                        <AccordionItem
                            heading={t('serviceCardContainer.office')}
                            subHeading={stationInfo?.name}
                            content={renderStationInfo()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <AccordionItem
                            heading={t('common.customer')}
                            subHeading={customerInfo?.name}
                            content={renderUserInfo()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid item {...centerMobile}>
                            {allowCancel && !isCheckedIn && !isCancelled && !inspectIsReady && !isPaymentInProgress &&
                                helperWrapper(
                                    <SecondaryButton
                                        isMobile={false}
                                        disabled={disabledConditions.cancel}
                                        fetching={
                                            checkIn.fetching.cancelReservation
                                        }
                                        onClick={handleOpenDialog}
                                        className={classes.button}
                                    >
                                        {t(
                                            'serviceCardContainer.cancelReservation',
                                        )}
                                    </SecondaryButton>,
                                    cancelHelper,
                                    true
                                )
                            }
                        </Grid>
                    </Grid>
                    {(isFullyPaid || (inspectIsReady && certificateIsAvailable) || isFullyRefunded) && !isPaymentInProgress &&
                        <Grid item xs={12} sm={12} md={6}>
                            <Grid item {...centerMobile}>
                                {
                                    helperWrapper(
                                        <PrimaryButton
                                            isMobile={false}
                                            className={classes.button}
                                            onClick={openDocumentDialog}
                                        >
                                            {t('serviceCardContainer.showDocuments')}
                                        </PrimaryButton>,
                                        reportHelper,
                                        true
                                    )
                                }
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Container>
            <FeedbackDialog
                display={feedbackDialogOpen}
                handleCancel={() => setFeedbackDialogOpen(false)}
                handleSubmit={handleFeedbackSubmit}
            />
            {renderDialog()}
            {renderDateChange()}
            {renderServiceChange()}
            {renderCheckInDialog()}
            {renderDocumentDialog()}
            <Backdrop
                className={classes.backdrop}
                open={ui.fetching}
            >
                <CircularProgress size={48} color="primary" />
            </Backdrop>
        </div>
    )
}
