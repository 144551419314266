import {Backdrop, CircularProgress, Link, Typography} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { RootState } from '../Redux'
import { useTranslation } from 'react-i18next'
import { AccordionItem } from '../Components/ServiceCardContainer/AccordionItem'
import { Header } from '../Components/Header'
import { Helmet } from 'react-helmet-async'
import { useSettings, useAppTheme } from '../Utils/hooks'
import { MuiProviders } from '../Utils/routes'
import {getLocale} from '../Utils/helpers'

const useStyles = makeStyles()((theme: Theme) => ({
    backdrop: {
        zIndex: 1000,
    },
    root: {
        fontSize: 24,
        justifyContent: 'center',
        display: 'flex',
    },
    container: {
        margin: theme.spacing(3),
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
    },
    thankYou: {
        fontWeight: 'bold',
    },
}))

interface CancelledReservationContainerProps extends RouteComponentProps {
    embedded: boolean
    themes: any
}

export const CancelledReservationContainer: FC<CancelledReservationContainerProps> = ({
    ...props
}) => {
    const language = getLocale()
    const { classes } = useStyles()
    const theme = useAppTheme()
    const { bookingEnabled, chain, station, embedded, logo, wwwAddress } = useSelector(({ ui }: RootState): any => ({
        bookingEnabled: ui.settings?.booking?.enabled,
        chain: ui.settings?.chain,
        station: ui.settings?.station,
        embedded: ui.options.embedded || props.embedded,
        logo: ui.settings?.station?.logo?.data || ui.settings?.chain?.logo?.data || undefined,
        wwwAddress: ui.settings?.station?.www || ui.settings?.chain?.www || undefined,
    }))
    const { t } = useTranslation()
    const chainIdUrl = (props.match.params as any).chainId
    const stationIdUrl = (props.match.params as any).stationId
    useSettings(chainIdUrl, stationIdUrl, props.history, props.embedded)

    const getTitle = () => {
        const name = station?.name || chain?.name || ''
        if (!name) return ''
        return `${name} - ${t('cancelledReservationContainer.reservationCancelled')}`
    }

    if (bookingEnabled === undefined) {
        return (
            <Backdrop className={classes.backdrop} open={true} style={{ backgroundColor: 'white' }}>
                <CircularProgress size={48} color="primary" />
            </Backdrop>
        )
    }

    return (
        <MuiProviders theme={theme}>
            <div className={classes.root}>
                <div className={classes.container}>
                    <Helmet>
                        <html lang={language} />
                        <title>{getTitle()}</title>
                    </Helmet>
                    <Header
                        title={
                            chain?.name ||
                            station?.name ||
                            ''
                        }
                        display={!embedded}
                        logo={logo}
                        wwwAddress={wwwAddress}
                    />
                    <AccordionItem
                        heading={t(
                            'cancelledReservationContainer.reservationCancelled',
                        )}
                        content={
                            <>
                                <Typography>
                                    {t(
                                        'cancelledReservationContainer.cancelledParagraph',
                                    )}
                                </Typography>
                                <Typography>
                                    <Link href={stationIdUrl ? `/booking/${chainIdUrl}/${stationIdUrl}` : `/booking/${chainIdUrl}`}>
                                        {t(
                                            'cancelledReservationContainer.newReservation',
                                        )}
                                    </Link>
                                </Typography>
                            </>
                        }
                    />
                </div>
            </div>
        </MuiProviders>
    )
}
