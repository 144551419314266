import { call } from '@redux-saga/core/effects'
import { CallbackArray } from './types'
import {InspectService} from '../interfaces'

export const validate = (s: string, r: RegExp): boolean => new RegExp(r).test(s)

export const registerPlateFormatter = (registration: string | null): string => {
    if (registration) {
        if (registration.includes('-')) {
            return registration.trim().toUpperCase()
        } else {
            return registration
                .replace(/^\s*([a-zA-ZÖÄÅöäå]{1,5})\s*([0-9]+)\s*$/, '$1-$2')
                .replace(/^\s*([0-9]{1,5})\s*([a-zA-ZÖÄÅöäå]+)\s*$/, '$1-$2')
                .toUpperCase()
        }
    }

    return ''
}

export const numberToCurrencyString = (value: number, currency?: string, minimumFractionDigits?: number) =>
    value ? value.toLocaleString('de-DE', {
        style: 'currency',
        minimumFractionDigits: value.toFixed(2).endsWith('.00') ? (minimumFractionDigits ?? 2) : 2,
        currency: currency || 'EUR',
    }) : ''

export function* handleCallbacks(callbacks?: CallbackArray) {
    if (callbacks) {
        for (const cb of callbacks) {
            if (cb) {
                yield call(cb)
            }
        }
    }
}

export const getLocale = () => {
    try {
        return localStorage.getItem('LANGUAGE') || 'fi'
    } catch (e) {
        return 'fi'
    }
}

export const storeLocale = (locale: string) => {
    try {
        localStorage.setItem('LANGUAGE', locale)
    } catch (e) {
        // ignored
    }
}

export const getCookieConsent = (): string|null => {
    try {
        return localStorage.getItem('cookieConsent') || null
    } catch (e) {
        return null
    }
}

export const storeCookieConsent = (answer: string) => {
    try {
        localStorage.setItem('cookieConsent', answer)
    } catch (e) {
        // ignored
    }
}

export const getServiceTranslation = (field: string, item: InspectService) => {
    if (! item) {
        return null
    }

    const locale = getLocale()
    if (locale === 'fi') {
        switch (field) {
            case('name'):
                return item.name
            case('additionalInfo'):
                return item.settings?.additionalInfo
            default:
                return null
        }
    } else if (locale === 'sv') {
        switch (field) {
            case('name'):
                return item.settings?.translationSv?.name ?? item.nameSv ?? item.name
            case('additionalInfo'):
                return item.settings?.translationSv?.additionalInfo ?? item.settings?.additionalInfo
            default:
                return null
        }
    }
    return null
}
