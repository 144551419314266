import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    SvgIcon,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React, { FC, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { a11yClick } from '../../Utils/a11yHelpers'
import { useMobile } from '../../Utils/hooks'
import { PrimaryButton } from '../Button'
import TextFieldWrapper from '../Form/TextField'
import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as Icon1 } from '../../Static/Icons/smiley_buttons_1.svg'
import { ReactComponent as Icon2 } from '../../Static/Icons/smiley_buttons_2.svg'
import { ReactComponent as Icon3 } from '../../Static/Icons/smiley_buttons_3.svg'
import { ReactComponent as Icon4 } from '../../Static/Icons/smiley_buttons_4.svg'

const maxWidth = 400

const useStyles = makeStyles()((theme: Theme) => ({
    button: {
        marginTop: theme.spacing(3),
    },
    content: {
        minWidth: 400,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: theme.spacing(2),
    },
    dialog: {
        borderRadius: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
        },
    },
    form: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: maxWidth,
    },
    shade: {
        opacity: 0.5,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: theme.palette.primary.main,
        '& span': {
            margin: 0,
            fontWeight: 700,
        },
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(3),
        justifyContent: 'space-between',
        alignItems: 'space-between',
        width: '100%',
        maxWidth: maxWidth,
    },
    textField: {
        maxWidth: maxWidth,
        width: '100%',
    },
}))

interface FeedbackDialogProps {
    display: boolean
    handleCancel(): void
    handleSubmit({
        grade,
        feedbackText,
    }: {
        grade: number | undefined
        feedbackText: string | undefined
    }): void
}

export const FeedbackDialog: FC<FeedbackDialogProps> = ({
    display,
    handleCancel,
    handleSubmit,
}) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const isMobile = useMobile('sm')
    const [currentGrade, setCurrentGrade] = useState<number | undefined>()
    const grades = [
        { grade: 4, icon: Icon1 },
        { grade: 3, icon: Icon2 },
        { grade: 2, icon: Icon3 },
        { grade: 1, icon: Icon4 },
    ]

    return (
        <Dialog
            PaperProps={{
                classes: { root: classes.dialog },
            }}
            aria-labelledby="feedback-dialog-title"
            open={display}
            fullScreen={isMobile}
        >
            <DialogTitle
                id="feedback-dialog-title"
                classes={{ root: classes.title }}
            >
                <span>{t('serviceCardContainer.feedbackTitle')}</span>
                <IconButton onClick={handleCancel}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Form
                    onSubmit={({ feedbackText }) =>
                        handleSubmit({ grade: currentGrade, feedbackText })
                    }
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <div className={classes.row}>
                                {grades.map(({ grade, icon }) => {
                                    let shade = false
                                    if (currentGrade) {
                                        shade = true
                                    }
                                    if (grade === currentGrade) {
                                        shade = false
                                    }
                                    return (
                                        <IconButton
                                            key={grade}
                                            {...a11yClick(() => setCurrentGrade(grade))}
                                            classes={{ root: shade ? classes.shade : undefined }}
                                        >
                                            <SvgIcon
                                                titleAccess={`${t('serviceCardContainer.grade')} ${grade}`}
                                                fontSize="large"
                                                viewBox={'0 0 80 80'}
                                                component={icon}
                                            />
                                        </IconButton>
                                    )
                                })}
                            </div>
                            <Field name="feedbackText" type="text">
                                {(props) => (
                                    <TextFieldWrapper
                                        {...props}
                                        variant="outlined"
                                        multiline
                                        className={classes.textField}
                                        rows={3}
                                        placeholder={t(
                                            'serviceCardContainer.feedbackText',
                                        )}
                                    />
                                )}
                            </Field>
                            <PrimaryButton
                                type="submit"
                                disabled={!currentGrade}
                                className={classes.button}
                            >
                                {t('common.save')}
                            </PrimaryButton>
                        </form>
                    )}
                />
            </DialogContent>
        </Dialog>
    )
}
