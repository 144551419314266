import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React from 'react'
import { RouteComponentProps } from 'react-router'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        backgroundColor: '#fff',
        height: '100vh',
        padding: theme.spacing(3),
        fontSize: 24,
        justifyContent: 'center',
        display: 'flex',
    },
}))

export const ErrorContainer = (_props: RouteComponentProps) => {
    const { classes } = useStyles()
    return <div className={classes.root}>Virhe, sivua ei löytynyt.</div>
}
