import {
    darken,
    lighten,
    responsiveFontSizes,
} from '@mui/material'
import { createTheme } from '@mui/material/styles'
import {
    deepPurple,
    grey,
} from '@mui/material/colors'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { ThemeCollection } from '../interfaces'
import car from '../Static/cars.jpg'
import konttibg from '../Static/konttibg.jpg'
import {OverridesStyleRules} from '@mui/material/styles/overrides'

export const overrides = (backgroundColor: string): OverridesStyleRules => ({
    MuiCssBaseline: {
        styleOverrides: {
            html: {
                scrollBehavior: 'smooth',
                fontSize: 16,
                height: '100%',
            },
            body: {
                backgroundColor,
                height: '100%',
            },
            '#root': {
                height: '100%',
            },
        },
    },
})

const fonts: TypographyOptions = {
    h1: {
        fontSize: '2.75rem',
    },
    h2: {
        fontSize: '2.5rem',
    },
    h3: {
        fontSize: '2.25rem',
    },
    h4: {
        fontSize: '2rem',
    },
    h5: {
        fontSize: '1.75rem',
    },
    h6: {
        fontSize: '1.5rem',
    },
    subtitle1: {
        fontSize: '1.25rem',
    },
    body1: {
        fontSize: '1rem',
    },
}

// export for testing purposes
export const themes: ThemeCollection = {
    white: createTheme({
        components: overrides('#ffffff'),
        palette: {
            primary: {
                main: deepPurple[700],
            },
            secondary: {
                main: '#01BB40',
                contrastText: '#ffffff'
            },
            background: {
                default: '#fff',
                paper: grey[50],
            },
            text: {
                primary: grey[900],
            },
        },
        typography: {
            ...fonts,
            fontFamily: 'sans-serif',
            caption: {
                fontFamily: 'Arial',
                color: grey[900],
            },
        },
    }),
    default: createTheme({
        components: overrides(darken(deepPurple[700], 0.3)),
        palette: {
            primary: {
                main: deepPurple[700],
            },
            secondary: {
                main: '#01BB40',
                contrastText: '#fff'
            },
            background: {
                default: darken(deepPurple[700], 0.3),
                paper: grey[50],
            },
            text: {
                primary: grey[900],
            },
        },
        typography: {
            ...fonts,
            fontFamily: 'sans-serif',
            caption: {
                fontFamily: 'Arial',
                color: grey[900],
            },
        },
    }),
    dekra: createTheme({
        components: overrides('#ffffff'),
        palette: {
            primary: {
                main: '#007d40',
            },
            secondary: {
                main: '#007d40',
                contrastText: '#fff'
            },
            background: {
                default: '#ffffff',
                paper: grey[50],
            },
            text: {
                primary: grey[900],
            },
        },
        typography: {
            ...fonts,
            fontFamily: 'sans-serif',
            caption: {
                fontFamily: 'Arial',
                color: grey[900],
            },
        },
    }),
    kontti: createTheme({
        components: overrides('#733C75'),
        palette: {
            primary: {
                main: '#733C75',
            },
            secondary: {
                main: '#01BB40',
                contrastText: '#fff'
            },
            background: {
                default: '#733C75',
                paper: grey[50],
            },
            text: {
                primary: grey[900],
            },
        },
        typography: {
            ...fonts,
            fontFamily: 'sans-serif',
            caption: {
                fontFamily: 'Arial',
                color: grey[900],
            },
        },
    }),
    kovalainen: createTheme({
        components: overrides('#ffffff'),
        palette: {
            primary: {
                main: '#5a5e59',
                contrastText: '#e8e731'
            },
            secondary: {
                main: '#e8e731',
                contrastText: '#5a5e59'
            },
            background: {
                default: '#ffffff',
                paper: grey[50],
            },
            text: {
                primary: '#5a5e59',
                secondary: '#5a5e59'
            },
        },
        typography: {
            ...fonts,
            fontFamily: 'sans-serif',
            caption: {
                fontFamily: 'Arial',
                color: '#5a5e59',
            },
        },
    }),
    pluskatsastus: createTheme({
        components: overrides('#ffffff'),
        palette: {
            primary: {
                main: '#c10933',
                contrastText: '#fff',
            },
            secondary: {
                main: '#c10933',
                contrastText: '#fff'
            },
            background: {
                default: '#ffffff',
                paper: grey[50],
            },
            text: {
                primary: grey[900],
            },
        },
        typography: {
            ...fonts,
            fontFamily: 'sans-serif',
            caption: {
                fontFamily: 'Arial',
                color: grey[900],
            },
        },
    }),
    qkatsastus: createTheme({
        components: overrides('#ffffff'),
        palette: {
            primary: {
                main: '#195861',
                contrastText: '#f0c11a'
            },
            secondary: {
                main: '#f0c11a',
                contrastText: '#195861'
            },
            background: {
                default: '#ffffff',
                paper: grey[50],
            },
            text: {
                primary: '#195861',
                secondary: '#195861'
            },
        },
        typography: {
            ...fonts,
            fontFamily: 'sans-serif',
            caption: {
                fontFamily: 'Arial',
                color: '#195861',
            },
        },
    }),
}

export interface ThemeType {
    backgroundColor?: string | null
    backgroundImage?: string | null
    backgroundFilter?: string
    backgroundImageOverlay?: boolean // true to overlay image with additional layer
    backgroundBlendMode?: 'multiply'
    backgroundOpacity?: number
    headerImage: string | null
    headerBackgroundColor?: string
    headerTextColor?: string
    headerStepTextColor?: string
    headerStepIconFill?: string
    footerBackgroundColor?: string
    footerTextColor?: string
    pricesTextActiveColor?: string
    pricesTextColor?: string
    pricesMinColor?: string
    pricesLowColor?: string
    priceTagTextColor?: string
    priceTagHeaderColor?: string
    priceTagHeaderActiveColor?: string
    priceTagMainColor?: string
    priceTagMainActiveColor?: string
    infoFormTextColor?: string
    secondaryButtonColor?: string
    secondaryButtonTextColor?: string
    stepLabelTextColor?: string
    transparentPaper?: boolean
}

/* Require and allow only same keys used in themes */
type ThemeAsset = Record<keyof ThemeCollection, ThemeType>

export const themeAssets: ThemeAsset = {
    default: {
        backgroundImage: car,
        backgroundFilter: 'blur(2px) grayscale(100%)',
        transparentPaper: true,
        headerImage: null,
    },
    dekra: {
        backgroundColor: '#ffffff',
        backgroundImage: null,
        transparentPaper: true,
        headerImage: null,
        headerBackgroundColor: '#ffffff',
        headerTextColor: '#000000',
        headerStepIconFill: '#007d40',
        headerStepTextColor: '#ffffff',
        footerBackgroundColor: '#000000',
    },
    white: {
        backgroundColor: '#ffffff',
        backgroundImage: null,
        transparentPaper: true,
        headerImage: null,
    },
    kontti: {
        backgroundImage: konttibg,
        backgroundImageOverlay: true,
        backgroundBlendMode: 'multiply',
        backgroundOpacity: 0.7,
        backgroundFilter: undefined,
        transparentPaper: true,
        headerImage: null,
    },
    kovalainen: {
        backgroundColor: '#ffffff',
        backgroundImage: null,
        transparentPaper: true,
        headerImage: null,
        pricesTextActiveColor: '#5a5e59',
        stepLabelTextColor: '#5a5e59',
        secondaryButtonTextColor: '#5a5e59',
    },
    pluskatsastus: {
        backgroundColor: '#ffffff',
        backgroundImage: null,
        transparentPaper: true,
        headerImage: null,
        headerBackgroundColor: '#ffffff',
        headerTextColor: '#000000',
        headerStepIconFill: '#c10933',
        headerStepTextColor: '#ffffff',
        footerBackgroundColor: '#748790',
        footerTextColor: '#ffffff',
        pricesTextColor: '#000000',
        pricesMinColor: '#01BB40',
        pricesLowColor: lighten('#01BB40', 0.5),
        priceTagTextColor: '#000000',
        priceTagHeaderColor: '#e0e0e0',
        priceTagHeaderActiveColor: darken('#e0e0e0', 0.1),
        priceTagMainColor: '#c6c6c6',
        priceTagMainActiveColor: darken('#c6c6c6', 0.1),
        infoFormTextColor: '#000000',
    },
    qkatsastus: {
        backgroundColor: '#ffffff',
        backgroundImage: null,
        transparentPaper: true,
        headerImage: null,
    }
}

// extend themes with responsive font sizes
const responsiveThemes: ThemeCollection = Object.entries(themes).reduce(
    (acc, [key, theme]) => ({
        ...acc,
        [key]: responsiveFontSizes(theme),
    }),
    {} as any,
)

export default responsiveThemes
