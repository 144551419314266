import {
    Button,
    CircularProgress,
    Link,
    Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { CSSProperties } from '@mui/styles'
import React, { FC } from 'react'
import { ThemeType } from '../Utils/themes'
import { useAppThemeOptions } from '../Utils/hooks'

interface ButtonProps {
    id?: string
    inactive?: boolean
    isMobile?: boolean
    disabled?: boolean
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
    className?: string
    fetching?: boolean
    type?: 'button' | 'reset' | 'submit'
    form?: string
    classes?: any
    style?: CSSProperties
    children: any
}

const useStyles = makeStyles<ThemeType>()((theme: Theme, options?: ThemeType) => ({
    primaryButton: {
        fontWeight: 700,
        borderRadius: 24,
        color: theme.palette.secondary.contrastText,
        fontSize: theme.typography.pxToRem(16),
        width: 250,
        minHeight: 40,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    inactiveButton: {
        backgroundColor: theme.palette.grey[400],
        '&:hover, &:active': {
            backgroundColor: theme.palette.grey[500],
        }
    },
    secondaryButton: {
        borderRadius: 24,
        backgroundColor: options?.secondaryButtonColor ?? '#fff',
        boxSizing: 'border-box',
        border: `2px solid ${options?.secondaryButtonTextColor ?? theme.palette.secondary.main}`,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 700,
        color: options?.secondaryButtonTextColor ?? theme.palette.secondary.main,
        marginTop: theme.spacing(3),
        width: 250,
        minHeight: 40,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            maxWidth: '100%',
        },
        '&:hover, &:active': {
            backgroundColor: options?.secondaryButtonTextColor ?? theme.palette.secondary.main,
            color: options?.secondaryButtonColor ?? '#fff',
        },
    },
    disabledSecondaryButton: {
        border: 0,
    },
    textButton: {
        color: theme.palette.secondary.main,
        textAlign: 'left',
        display: 'inline-block',
        width: 'fit-content',
    },
    disabledTextButton: {
        textDecoration: 'none',
        cursor: 'unset',
        color: `${theme.palette.grey[500]} !important`,
        '&:hover, &:active': {
            cursor: 'unset',
            textDecoration: 'none',
        },
    },
    textButtonText: {
        color: theme.palette.secondary.main,
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(14),
    },
    disabledTextButtonText: {
        cursor: 'unset',
        color: `${theme.palette.grey[500]} !important`,
        textDecoration: 'none',
    },
}))

const CustomButton: FC<ButtonProps> = ({
    id,
    isMobile,
    onClick,
    classes,
    disabled,
    children,
    className,
    fetching,
    form,
    type,
    style,
}) => {
    return (
        <Button
            id={id}
            variant="contained"
            color="secondary"
            className={className}
            fullWidth={isMobile}
            disabled={fetching || disabled}
            classes={classes}
            onClick={onClick}
            type={type}
            form={form}
            style={{...style}}
        >
            <CircularProgress size={24} style={{display: fetching ? null : 'none', position: 'absolute'}} />
            {children}
        </Button>
    )
}

export const PrimaryButton: FC<ButtonProps> = ({
    className,
    children,
    inactive,
    ...props
}) => {
    const { classes } = useStyles(undefined)
    const styles = `${classes.primaryButton} ${className ?? null} ${inactive ? classes.inactiveButton : null}`
    return (
        <CustomButton {...props} className={styles}>
            {children}
        </CustomButton>
    )
}

export const SecondaryButton: FC<ButtonProps> = ({
    className,
    children,
    inactive,
    ...props
}) => {
    const themeOptions = useAppThemeOptions()
    const { classes } = useStyles(themeOptions)
    const styles = `${classes.secondaryButton} ${className ?? null} ${inactive ? classes.inactiveButton : null} ${props.disabled ? classes.disabledSecondaryButton : null}`
    return (
        <CustomButton {...props} className={styles}>
            {children}
        </CustomButton>
    )
}

export const TextButton: FC<any> = ({ children, disabled, onClick, ...props }) => {
    const { classes } = useStyles(undefined)
    return (
        <Link
            component="button"
            {...props}
            onClick={disabled ? undefined : onClick}
            className={
                disabled
                    ? `${classes.textButton} ${classes.disabledTextButton}`
                    : classes.textButton
            }
        >
            <Typography
                className={
                    disabled
                        ? `${classes.textButtonText} ${classes.disabledTextButtonText}`
                        : classes.textButtonText
                }
            >
                {children}
            </Typography>
        </Link>
    )
}
