import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import TextField from '@mui/material/TextField'

const TextFieldWrapper: FC<FieldRenderProps<any>> = ({
    input: { name, onChange, value, ...restInput },
    meta,
    inputProps,
    className,
    placeholder,
    classes,
    variant,
    ...rest
}) => {
    const showError = !meta.active && meta.error && meta.touched
    const combinedInputProps = { ...restInput, ...inputProps }
    return (
        <TextField
            value={value}
            {...rest}
            label={placeholder}
            name={name}
            variant={variant}
            className={className}
            classes={classes}
            helperText={showError ? meta.error || meta.submitError : undefined}
            error={showError}
            inputProps={combinedInputProps}
            onChange={onChange}
        />
    )
}

export default TextFieldWrapper
