import { a11y } from './const'

interface a11yEventProps {
    onClick(): void
    onKeyPress(): void
}

type EventCall = (e?: any, ...params: any[]) => void

/**
 * Adds onClick and onKeyPress events with callback function.
 * <T {...a11yClick(f)} /> is equivalent to
 * <T onClick={f} onKeyPress={f} />
 * @param f Callback function
 */
export const a11yClick = (f: EventCall, ...params: any[]): a11yEventProps =>
    params
        ? {
            onClick: (e: any) => f(e, ...params),
            onKeyPress: (e: any) => f(e, ...params),
        }
        : {
            onClick: f,
            onKeyPress: f,
        }

type AnyEvent = MouseEvent | KeyboardEvent

/**
 * Makes event more accessible by calling callback function from mouse and keyboard events (enter and space).
 * @param e Event (MouseEvent or KeyboardEvent)
 * @param call Event callback function
 */
export const a11yEventCall = (
    e: AnyEvent | undefined,
    call: EventCall,
    ...params: any[]
): void => {
    const callback = () => call(e, ...params)
    if (e?.type === 'keypress') {
        switch (e.which) {
            case a11y.KEY_PRESS.ENTER:
                return callback()
            case a11y.KEY_PRESS.SPACE:
                return callback()
            default:
                return
        }
    } else {
        return callback()
    }
}
