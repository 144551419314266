export const a11y = {
    KEY_PRESS: {
        ENTER: 13,
        SPACE: 32,
    },
}

export const dbDateFormat = 'YYYY-MM-DD HH:mm:ss' as const

export const TASK_COMPLETED_VALID_AFTER_MINUTES = 15

export const TRAFICOM_FEE_CATEGORY_ID = 1

export const NEW_REGISTRATION = ''

export const ATJ_VEHICLE_TYPE_CAR = 1

export const VEHICLE_SEARCH_TYPE_DORIS = 2

export const atjVehicleTypes = [
    { id: 1, name: 'atjVehicleType_car' },
    { id: 4, name: 'atjVehicleType_atv' },
    { id: 2, name: 'atjVehicleType_motorbike' },
    { id: 0, name: 'atjVehicleType_moped' },
    { id: 5, name: 'atjVehicleType_trailer' },
    { id: 3, name: 'atjVehicleType_tractor' },
]

export enum GTMEvent {
    Initialization = 'initialization',
    Reservation = 'reservation',
}
