import { Grid, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React, { FC, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { SearchSubmitEvent } from '../../Containers/RootContainer'
import { InspectStation } from '../../interfaces'
import { ReservationState } from '../../Redux/reservationRedux'
import { a11yClick } from '../../Utils/a11yHelpers'
import { useMobile } from '../../Utils/hooks'
import { PrimaryButton, SecondaryButton } from '../Button'
import { SearchForm } from './SearchForm'

const useStyles = makeStyles()((theme: Theme) => ({
    contentEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    imageContainer: {
        height: 150,
        borderRadius: 4,
        width: 150,
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    station: {
        outline: 'none',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 8,
        boxShadow: theme.shadows[3],
        margin: theme.spacing(0.5, 0),
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
        '&:active, &:focus': {
            boxShadow: `0 0 0 1px ${theme.palette.secondary.main}`,
        },
    },
    stationTexts: {
        marginLeft: theme.spacing(3),
        width: 300,
        [theme.breakpoints.down('sm')]: {
            width: 250,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
            width: 200,
        },
    },
    stationText: {
        color: theme.palette.grey[900],
    },
    stationTextActive: {
        color: '#fff',
    },
    stationName: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    stationActive: {
        outline: 'none',
        boxShadow: `0 0 0 3px ${theme.palette.secondary.main}`,
        '&:hover, &:active, &:focus': {
            boxShadow: `0 0 0 3px ${theme.palette.secondary.main}`,
        },
    },
    stationContainer: {
        display: 'grid',
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    longButton: {
        width: '300px !important',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
        },
    },
    nextButton: {
        borderRadius: 24,
        fontSize: theme.typography.pxToRem(16),
        marginTop: theme.spacing(3),
        width: 250,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            maxWidth: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
        '&:hover': {
            fontWeight: 'bold',
        },
    },
    spaceBetween: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    zeroMargin: {
        margin: 0,
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    marginTop: {
        marginTop: theme.spacing(1),
    },
}))

interface StationStepProps {
    canGoToIndex: any
    goNext: any
    getStations: any
    fetching: ReservationState['fetching']
    stations: InspectStation[] | null
    filteredStations: InspectStation[] | null
    onSubmitSearch: any
    form: any
    inputId: string
    setStation: any
    contentStyle: string
    activeStation: any
    stationIdUrl: string | undefined
}

enum ViewStates {
    All = 'all',
    Filtered = 'filtered',
}

export const StationStep: FC<StationStepProps> = ({
    canGoToIndex,
    goNext,
    getStations,
    filteredStations,
    fetching,
    stations,
    onSubmitSearch,
    form,
    inputId,
    setStation,
    contentStyle,
    activeStation,
    stationIdUrl,
}) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const [view, setView] = useState<ViewStates>(ViewStates.All)
    const isMobile = useMobile('sm')
    const mappableStations =
        view === ViewStates.All
            ? stations
            : view === ViewStates.Filtered && filteredStations
    const clearFilters = () => {
        setView(ViewStates.All)
        form?.reset()
    }

    const oneStationChain = stations && stations.length === 1

    useEffect(() => {
        if (oneStationChain) setStation((stations as any)[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneStationChain])

    return (
        <div className={contentStyle}>
            {!oneStationChain &&
                <div className={classes.spaceBetween}>
                    <Form
                        onSubmit={(e: SearchSubmitEvent) => {
                            onSubmitSearch(e, () => setView(ViewStates.Filtered))
                        }}
                        render={({ handleSubmit, values, ...props }) => {
                            form = props.form
                            return (
                                <SearchForm
                                    inputId={inputId}
                                    {...props}
                                    handleSubmit={handleSubmit}
                                    values={values}
                                    fetching={fetching.coordinates}
                                />
                            )
                        }}
                    />
                    {view !== ViewStates.All && (
                        <SecondaryButton
                            isMobile={isMobile}
                            className={
                                !isMobile
                                    ? classes.zeroMargin
                                    : `${classes.zeroMargin} ${classes.marginBottom} ${classes.longButton}`
                            }
                            disabled={false}
                            onClick={clearFilters}
                        >
                            {t('rootContainer.showAll')}
                        </SecondaryButton>
                    )}
                    <PrimaryButton
                        isMobile={isMobile}
                        className={`${classes.nextButton} ${classes.zeroMargin} ${classes.longButton}`}
                        disabled={false}
                        onClick={() => getStations(() => setView(ViewStates.Filtered))}
                        fetching={fetching.filteredStations}
                    >
                        {t('rootContainer.getStations')}
                    </PrimaryButton>
                </div>
            }
            <div
                aria-expanded={!!stations?.length}
                className={classes.stationContainer}
            >
                <Grid spacing={2} container>
                    {mappableStations &&
                        mappableStations.filter(item => {
                            if (stationIdUrl) {
                                return item.id === +stationIdUrl
                            } else return true
                        }).map((station: InspectStation) => {
                            const {
                                id,
                                name,
                                address,
                                phone,
                                email,
                                openingHours,
                            } = station
                            const isActive = activeStation?.id === id
                            return (
                                <Grid item xs={12} md={6} key={id}>
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        {...a11yClick(() => {
                                            oneStationChain ? null : setStation(station)
                                        })}
                                        className={
                                            isActive
                                                ? `${classes.station} ${classes.stationActive}`
                                                : classes.station
                                        }
                                    >
                                        {Array.isArray(station.images) && (
                                            <div
                                                className={
                                                    classes.imageContainer
                                                }
                                            >
                                                <img
                                                    src={
                                                        station.images[0]?.data
                                                    }
                                                    alt=""
                                                    className={
                                                        classes.imageContainer
                                                    }
                                                />
                                            </div>
                                        )}
                                        <div className={classes.stationTexts}>
                                            <Typography
                                                className={classes.stationName}
                                            >
                                                {name}
                                            </Typography>
                                            <Typography
                                                className={classes.stationText}
                                            >
                                                {address?.line1 ?? ''}
                                            </Typography>
                                            {address?.line2 && (
                                                <Typography
                                                    className={
                                                        classes.stationText
                                                    }
                                                >
                                                    {address.line2}
                                                </Typography>
                                            )}
                                            <Typography
                                                className={classes.stationText}
                                            >{address?.zipCode ?? ''} {address?.city ?? ''}</Typography>
                                            <Typography
                                                className={`${classes.stationText} ${classes.marginTop}`}
                                            >
                                                {phone}
                                            </Typography>
                                            <Typography
                                                className={classes.stationText}
                                            >
                                                {email}
                                            </Typography>
                                            {openingHours && (
                                                <Typography
                                                    className={
                                                        classes.stationText
                                                    }
                                                >
                                                    {openingHours}
                                                </Typography>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            )
                        })}
                </Grid>
            </div>
            <div className={classes.contentEnd}>
                <PrimaryButton
                    id="station-next"
                    disabled={!canGoToIndex(1)}
                    onClick={goNext}
                    className={classes.nextButton}
                    fetching={fetching.services}
                >
                    {t('common.next')}
                </PrimaryButton>
            </div>
        </div>
    )
}
