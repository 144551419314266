import React, {forwardRef} from 'react'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import {useMobile, useAppThemeOptions} from '../../Utils/hooks'
import { a11yClick } from '../../Utils/a11yHelpers'
import { StyledStepConnector } from './StyledConnector'
import { ThemeType } from '../../Utils/themes'

const useStyles = makeStyles<ThemeType>()((theme: Theme, options: ThemeType) => {
    const transition = theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    })
    return {
        container: {
            backgroundColor: options.headerBackgroundColor ?? theme.palette.primary.dark,
            display: 'flex',
            position: 'sticky',
            zIndex: 1000,
            top: 64,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            transition,
            boxShadow: '0px 0px 8px rgba(0,0,0,0.2)',
        },
        root: {
            opacity: 1,
            backgroundColor: options.headerBackgroundColor ?? theme.palette.primary.dark,
            width: '100%',
            maxWidth: 900,
            padding: theme.spacing(1, 1, 0),
            position: 'sticky',
            transition,
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            minHeight: 36,
            fontSize: theme.typography.caption.fontSize,
            marginBottom: theme.spacing(1),
            color: options.headerTextColor ?? '#fff',
            fontWeight: 'bold',
        },
        stepIcon: {
            color: `${options.headerStepIconFill ?? '#fff'} !important`,
        },
        activeStepIcon: {
            color: `${options.headerStepIconFill ?? '#fff'} !important`,
        },
        step: {
            padding: 0,
        },
        stepText: {
            fill: options.headerStepTextColor ?? theme.palette.primary.main,
            fontWeight: 'bold',
        },
    }
})

interface HeaderStepperProps {
    steps: string[]
    activeStep: number
    display: boolean
    canGoToIndex: (index: number) => boolean | undefined
    selectStep: (index: number) => void
}

export const HeaderStepper = forwardRef<any, HeaderStepperProps>(
    ({ steps, activeStep, display, canGoToIndex, selectStep }, ref) => {
        const themeOptions = useAppThemeOptions()
        const { classes } = useStyles(themeOptions)
        const isMobile = useMobile('sm')
        const { t } = useTranslation()
        if (!display || isMobile) return null
        return (
            <div className={classes.container} ref={ref}>
                <Stepper
                    connector={<StyledStepConnector />}
                    activeStep={activeStep}
                    alternativeLabel
                    className={classes.root}
                >
                    {steps.map((label: string, index: number) => (
                        <Step
                            classes={{ root: classes.step }}
                            key={label}
                            {...a11yClick(() =>
                                index !== activeStep
                                    ? canGoToIndex(index) && selectStep(index)
                                    : undefined,
                            )}
                        >
                            <StepLabel
                                StepIconProps={{
                                    classes: {
                                        active: classes.activeStepIcon,
                                        completed: classes.activeStepIcon,
                                        root: classes.stepIcon,
                                        text: classes.stepText,
                                    },
                                }}
                            >
                                <Typography
                                    noWrap={isMobile}
                                    className={classes.instructions}
                                >
                                    {/\d/.test(label) ? label : t(label)}
                                </Typography>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
        )
    },
)

HeaderStepper.displayName = 'HeaderStepper'
