import {
    CircularProgress,
    IconButton,
    lighten,
    Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { DateTime } from 'luxon'
import React, {
    FC,
    useMemo,
} from 'react'
import ArrowLeftIcon from '@mui/icons-material/ArrowBackIos'
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos'
import { useTranslation } from 'react-i18next'
import { InspectService, PriceAndTime } from '../../interfaces'
import { a11yClick } from '../../Utils/a11yHelpers'
import { useMobile, useAppThemeOptions } from '../../Utils/hooks'
import { numberToCurrencyString } from '../../Utils/helpers'
import { useSelector } from 'react-redux'
import { RootState } from '../../Redux'
import { ThemeType } from '../../Utils/themes'

interface DatePickerProps {
    forceMobile?: boolean
    value: DateTime | null
    onChange(date: DateTime): void
    locale?: string
    dates: DateTime[]
    prices?: PriceAndTime[] | null
    service: InspectService | null
    fixedPrice?: number | null
    onGetBestPrices(
        service: InspectService,
        dateStart: string | undefined,
        dateEnd: string | undefined,
        callback?: () => void,
    ): void
}

interface StyleProps extends ThemeType {
    margin: number
}

const useStyles = makeStyles<StyleProps>()((theme: Theme, props: StyleProps) => ({
    additionalInfo: {
        marginTop: 0,
        width: '100%',
        padding: theme.spacing(2, 0),
        backgroundColor: props.priceTagMainColor ?? theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1, 0),
        },
    },
    activeAdditionalInfo: {
        backgroundColor: props.priceTagMainActiveColor ?? theme.palette.secondary.main,
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
        width: '100%',
    },
    column: {
        border: 0,
        margin: `0px ${props.margin}px`,
        outline: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 1px',
        },
        '&:hover, &:active, &:focus': {
            boxShadow: '0px 0px 2px 2px rgba(90,90,90,1)',
        },
    },
    columnDisabled: {
        border: 0,
        margin: `0px ${props.margin}px`,
        outline: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 1px',
        },
    },
    activeItem: {
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: `${props.priceTagHeaderActiveColor ?? theme.palette.secondary.light} !important`,
    },
    dateTexts: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dateText: {
        color: props.pricesTextColor ?? theme.palette.primary.main,
        textTransform: 'capitalize',
        marginBottom: 0,
        fontSize: theme.typography.pxToRem(16),
    },
    dateTextBold: {
        color: props.pricesTextColor ?? theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 'bold',
    },
    dateRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '100%',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 0, 2, 0),
    },
    icon: {},
    iconButton: {},
    disabledItem: {
        backgroundColor: props.priceTagHeaderColor ?? lighten(theme.palette.primary.light, 0.15),
        opacity: 0.5,
        display: 'flex',
        padding: theme.spacing(1, 0),
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    item: {
        backgroundColor: props.priceTagHeaderColor ?? lighten(theme.palette.primary.light, 0.15),
        display: 'flex',
        padding: theme.spacing(1, 0),
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    itemInfo: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(16),
        color: props.priceTagTextColor ?? '#fff',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 600,
        },
    },
    itemInfoBold: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(16),
        color: props.priceTagTextColor ?? '#fff',
        height: 20,
        textAlign: 'center',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            height: 16,
            fontSize: theme.typography.pxToRem(12),
        },
    },
    lockedPrice: {
        zIndex: 1,
        opacity: 0.5,
    },
    root: {
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

export const DatePicker: FC<DatePickerProps> = ({
    locale = 'fi',
    forceMobile,
    value,
    onChange,
    prices,
    dates,
    onGetBestPrices,
    service,
    fixedPrice,
}) => {
    const fetching = useSelector((state: RootState) => state.reservation.fetching.bestPrices)
    const isMobile = forceMobile || useMobile('md')
    const showPrices = useSelector((state: RootState) => state.ui.settings?.booking.options.showCalendarPrices)
    const margin = isMobile ? 1 : 4
    const themeOptions = useAppThemeOptions()
    const { classes } = useStyles({ margin, ...themeOptions })
    const weekStart = dates[0]
    const dayPrices = useMemo(() => {
        const getPrice = (date: DateTime) => {
            return prices?.find(
                (item) =>
                    DateTime.fromISO(item.time, {setZone: true}).toISODate() ===
                    date.toISODate(),
            )?.price.value
        }
        return dates.map((date) => ({ date, price: getPrice(date) }))
    }, [dates, prices])

    const { t } = useTranslation()

    const getMorePrices = (date: DateTime, callback: any) => {
        if (service) onGetBestPrices(service, date.toISO(), date.endOf('week').toISO(), callback)
    }

    const scrollLeft = () => {
        getMorePrices(weekStart.minus({weeks: 1}), undefined)
    }

    const scrollRight = () => {
        getMorePrices(weekStart.plus({weeks: 1}), undefined)
    }

    const currentDate = {
        week: weekStart.weekNumber,
        month: weekStart.setLocale(locale).monthLong,
        year: weekStart.weekYear,
    }

    const renderDates = () => {
        return [
            ...dayPrices.map(({ date, price }) => {
                const isActive = isMobile && date.toISODate() === value?.toISODate()
                const isBefore = date < DateTime.local().startOf('day')
                const isCurrentWeek = date.weekNumber === currentDate.week
                const isDisabled = isBefore
                return (
                    <div
                        role="button"
                        tabIndex={!isBefore && isCurrentWeek ? 0 : undefined}
                        className={isDisabled ? classes.columnDisabled : classes.column}
                        key={date.toString()}
                        {...a11yClick(() => isDisabled || ! isMobile ? null : onChange(date))}
                    >
                        <div
                            className={
                                isActive
                                    ? `${classes.item} ${classes.activeItem}`
                                    : (isBefore && !price) || fetching
                                        ? classes.disabledItem
                                        : classes.item
                            }
                        >
                            {
                                isMobile && <div className={classes.row}>
                                    <Typography noWrap className={classes.itemInfo}>
                                        {date.setLocale(locale).toFormat('ccc')}
                                    </Typography>
                                </div>
                            }
                            <div className={classes.row}>
                                <Typography noWrap className={classes.itemInfo}>
                                    {date
                                        .setLocale(locale)
                                        .toFormat(
                                            isMobile ? 'dd.MM' : 'ccc dd.MM',
                                        )}
                                </Typography>
                            </div>
                        </div>
                        {showPrices && (
                            <div
                                className={
                                    isBefore || fetching
                                        ? `${classes.additionalInfo} ${classes.lockedPrice}`
                                        : isActive
                                            ? `${classes.additionalInfo} ${classes.activeAdditionalInfo}`
                                            : classes.additionalInfo
                                }
                            >
                                {isMobile ? <div className={classes.row}>
                                    <Typography className={classes.itemInfoBold}>
                                        {!isBefore && !fetching && price ? <span>{t('rootContainer.startingPrice_abbr')}</span> : null}
                                    </Typography>
                                </div> : null}
                                <div className={classes.row}>
                                    <Typography className={classes.itemInfoBold}>
                                        {!isBefore && !fetching && price ? <span>
                                            {!isMobile ? (t('rootContainer.startingPrice_abbr') + ' ') : ''}{numberToCurrencyString(fixedPrice ?? price, 'EUR', 0)}
                                        </span> : null}
                                    </Typography>
                                </div>
                            </div>
                        )}
                    </div>
                )
            }),
        ]
    }

    const leftDisabled = fetching || weekStart <= DateTime.local().startOf('day')
    const rightDisabled = fetching

    return (
        <div className={classes.root}>
            <div className={classes.dateRow}>
                <IconButton
                    className={classes.iconButton}
                    onClick={scrollLeft}
                    disabled={leftDisabled}
                >
                    <ArrowLeftIcon
                        color={leftDisabled ? 'disabled' : 'primary'}
                        className={classes.icon}
                    />
                </IconButton>
                <div className={classes.dateTexts}>
                    <Typography paragraph className={classes.dateText} noWrap>
                        {`${currentDate.month} ${currentDate.year}`}
                    </Typography>
                    <Typography
                        component="div"
                        className={classes.dateTextBold}
                        noWrap
                    >
                        {fetching ? <CircularProgress size={20} /> : `${t('common.week')} ${currentDate.week}`}
                    </Typography>
                </div>
                <IconButton
                    className={classes.iconButton}
                    onClick={scrollRight}
                    disabled={rightDisabled}
                >
                    <ArrowRightIcon
                        color={rightDisabled ? 'disabled' : 'primary'}
                        className={classes.icon}
                    />
                </IconButton>
            </div>
            <div className={classes.row}>
                <div className={classes.container}>
                    {renderDates()}
                </div>
            </div>
        </div>
    )
}
