import apisauce, {
    CancelToken as CancelTokenSource,
} from 'apisauce'
import { Coordinates, ServiceDateParams } from '../interfaces'

const _CancelToken = CancelTokenSource.source()
type CancelToken = typeof _CancelToken.token

const create = () => {
    if (process.env.NODE_ENV === 'development') {
        if (!process.env.REACT_APP_API_PORT)
            throw Error('Missing REACT_APP_API_PORT environment variable')
        if (!process.env.REACT_APP_LOCALHOST_URL)
            throw Error('Missing REACT_APP_LOCALHOST_URL environment variable')
    }
    const baseURL =
        process.env.NODE_ENV === 'development'
            ? `https://${process.env.REACT_APP_LOCALHOST_URL}:${process.env.REACT_APP_API_PORT}`
            : `${window.location.origin}/api`
    const api = apisauce.create({
        baseURL,
        timeout: 30000,
    })
    return {
        postError: async (error: any, url: any) => {
            const data = {
                url,
                error: JSON.stringify(error?.error ?? {}),
                message: (error?.message ?? '-') + ';' + (error?.error?.message ?? '-'),
                trace: error?.stack ?? '-',
                stack: error?.error?.stack ?? '-',
                userAgent: error?.userAgent ?? '?',
                info: JSON.stringify(Object.getOwnPropertyNames(error ?? {})),
            }

            return process.env.NODE_ENV === 'development' ? null : await api.post('log/error', data)
        },
        postFinalReservation: async (reservation: any) =>
            await api.post('final-reservation', reservation),
        getSettings: async (chainId: number, stationId: number | null, embedded: boolean) =>
            await api.get(
                `settings/chain/${chainId}${
                    stationId ? '/station/' + stationId : ''
                }${embedded ? '?embed' : ''}`,
            ),
        getChainSettingsByToken: async (token: string, embedded: boolean) =>
            await api.get(`settings/token/${token}/chain${embedded ? '?embed' : ''}`),
        getStationSettingsByToken: async (token: string, embedded: boolean) =>
            await api.get(`settings/token/${token}/station${embedded ? '?embed' : ''}`),
        postReservation: async (reservation: any) =>
            await api.post('reservation', reservation),
        putReservation: async (reservation: any) =>
            await api.put('reservation', reservation),
        discardReservation: async (reservation: any) =>
            await api.put('discard-reservation', reservation),
        getRegisterInfo: async ({ registerNumber, vehicleType, vehicleClass, vehicleGroup, engineType, searchType, stationId, chainId }: any) =>
            await api.post(
                `/chains/${chainId}/stations/${stationId}/vehicles/search`,
                { registrationNumber: registerNumber, vehicleType, vehicleClass, vehicleGroup, engineType, source: searchType }
            ),
        getStations: async (
            {
                coordinates,
                chainId,
                filter,
                stationId,
            }: {
                coordinates?: Coordinates
                chainId: number | string
                filter?: string
                stationId?: number | string
            },
            cancelToken: CancelToken,
        ) =>
            await api.get(
                `chain/${chainId}/stations${stationId ? '/' + stationId : ''}`,
                { filter, ...coordinates },
                {
                    cancelToken,
                },
            ),
        getServices: async (
            {
                stationId,
                vehicleGroupId,
                campaign,
                fuel,
                chainId,
            }: {
                stationId: number
                vehicleGroupId: number
                campaign: string | null
                fuel: string | null,
                chainId: string | number
            },
            cancelToken: CancelToken,
        ) => {
            return await api.get(
                `chains/${chainId}/stations/${stationId}/services`,
                { campaign, fuel, vehicleGroupId },
                { cancelToken },
            )
        },
        getDates: async ({
            dateParams,
            chainId,
        }: {
            dateParams: ServiceDateParams
            chainId: number
        }) => await api.get(`chain/${chainId}/service-dates`, dateParams),
        getBestPriceDates: async ({
            dateParams,
            chainId,
        }: {
            dateParams: ServiceDateParams
            chainId: number
        }) =>
            await api.get(
                `chain/${chainId}/service-dates/best-prices`,
                dateParams,
            ),
        getGeocodes: async (address: string) =>
            await api.get('geocode', { address }),
        getCheckIn: async (token: string) =>
            await api.get(`service-card/${token}`),
        cancelReservation: async (token: string) =>
            await api.put(`service-card/cancel/${token}`),
        checkIn: async (token: string) =>
            await api.put(`service-card/check-in/${token}`),
        changeReservation: async ({
            token,
            modifications,
        }: {
            token: string
            modifications: any
        }) => await api.put(`service-card/${token}`, modifications),
        createPayment: async ({
            token,
            quick,
        }: {
            token: string
            quick?: boolean
        }) => await api.post(`online-payment/create/${token}`, { quick }),
        handlePaymentResult: async ({
            result,
            query,
        }: {
            result: any
            query: string
        }) => await api.get(`online-payment/result/${result}${query}`),
        confirmReservationChanges: async ({ token }: { token: string }) =>
            await api.post(`service-card/confirm/${token}`),
        revertReservationChanges: async ({ token }: { token: string }) =>
            await api.post(`service-card/revert/${token}`),
        giveFeedback: async ({ token, feedbackText, grade }: any) =>
            await api.post(`service-card/feedback/${token}`, {
                feedbackText,
                grade,
            }),
        getReceipt: async (token: string) =>
            await api.get(`service-card/${token}/receipt`, undefined, {
                responseType: 'arraybuffer',
            }),
        getCertificate: async (token: string) =>
            await api.get(`service-card/${token}/certificate`, undefined, {
                responseType: 'arraybuffer',
            }),
    }
}

export type ApiType = ReturnType<typeof create>

export default {
    create,
}
