import { createStore, applyMiddleware, compose, Store, Action, Reducer, CombinedState } from 'redux'
import createSagaMiddleware, { Saga, SagaMiddleware, Task } from 'redux-saga'

interface StoreType {
    store: Store
    sagasManager: Task
    sagaMiddleware: SagaMiddleware
}

// creates the store
export default (rootReducer: Reducer<CombinedState<any>, Action<any>>, rootSaga: Saga): StoreType => {
    /* ------------- Redux Configuration ------------- */

    const middleware = []
    const enhancers = []

    /* ------------- Saga Middleware ------------- */

    const sagaMiddleware = createSagaMiddleware()
    middleware.push(sagaMiddleware)

    /* ------------- Assemble Middleware ------------- */

    enhancers.push(applyMiddleware(...middleware))

    if (process.env.NODE_ENV !== 'production') {
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
            enhancers.push((window as any).__REDUX_DEVTOOLS_EXTENSION__())
    }

    const store = createStore(rootReducer, compose(...enhancers))

    // kick off root saga
    const sagasManager = sagaMiddleware.run(rootSaga)

    return {
        store,
        sagasManager,
        sagaMiddleware,
    }
}
