import React from 'react'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()((theme: Theme) => ({
    heading: {
        marginTop: 0,
        color: theme.palette.primary.main,
    },
}))

export const InfoSection = ({text}) => {
    const { t } = useTranslation()
    const { classes } = useStyles()

    return (
        <>
            <h2 className={classes.heading}>
                {t('common.reservationInfo')}
            </h2>
            <p>{text}</p>
        </>
    )
}
