import { Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { DateTime } from 'luxon'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInterval } from '../../Utils/hooks'
import { useDispatch } from 'react-redux'
import ReservationActions from '../../Redux/reservationRedux'

const useStyles = makeStyles()((theme: Theme) => ({
    timeLeft: {
        color: '#fff',
        fontSize: theme.typography.pxToRem(12),
        textAlign: 'center',
    },
    timeLeftBold: {
        fontWeight: 'bold',
        color: '#fff',
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(12),
    },
    timeLeftContainer: {
        backgroundColor: theme.palette.primary.light,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(1),
        marginTop: theme.spacing(-1),
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
    },
    grid: {
        height: '100%',
        padding: theme.spacing(1),
    },
}))

interface EndTimeType {
    confirmBefore: string|null
    confirmIn: number|null
    endTime: DateTime|null
}

export const Timer: FC<any> = ({
    confirmBefore,
    confirmIn,
    display,
    reservation,
}) => {
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [end, setEnd] = useState<EndTimeType>({confirmBefore: null, confirmIn: null, endTime: null})
    const [timeLeft, setTimeLeft] = useState<string | null>(null)

    useEffect(() => {
        if (confirmBefore && confirmIn && (end.confirmBefore !== confirmBefore || end.confirmIn !== confirmIn)) {
            setEnd({
                confirmBefore: confirmBefore,
                confirmIn: confirmIn,
                endTime: DateTime.now().plus({seconds: confirmIn})
            })
        }
    }, [confirmBefore, confirmIn, end])

    useInterval(() => {
        const now = DateTime.now()

        if (end.endTime) {
            const diff = end.endTime.diff(now, [
                'minutes',
                'seconds',
                'milliseconds',
            ])
            if (diff.as('milliseconds') <= 0) {
                if (timeLeft && reservation?.reservation?.chainId) {
                    dispatch(ReservationActions.discardReservation(reservation.reservation.chainId, [() => window.location.reload()]))
                    setTimeLeft(null)
                }
            } else {
                setTimeLeft(diff.toFormat('mm:ss'))
            }
        }
    }, 1000)

    if (!timeLeft || !display) return null
    return (
        <div className={classes.timeLeftContainer}>
            <Typography className={classes.timeLeft} noWrap>
                {t('rootContainer.timeLeft')}
            </Typography>
            <Typography noWrap className={classes.timeLeftBold}>
                {timeLeft}
            </Typography>
        </div>
    )
}
