import React, { StrictMode } from 'react'
import * as ReactDOMClient from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n/i18n'
import api from './Services/api'

const handleErrorEvent = (event) => {
    try {
        api.create().postError({message: event.message, error: event.error, stack: 'error at ' + event.filename + ':' + event.lineno + ':' + event.colno, userAgent: window.navigator?.userAgent}, window.location.href)
    } catch (e) {
        // ignore
    }
}

window.addEventListener('error', event => handleErrorEvent(event))

ReactDOMClient.createRoot(document.getElementById('root')).render(
    <StrictMode>
        <App />
    </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
