import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Select } from '@mui/material'

export const SelectWrapper: FC<FieldRenderProps<any>> = ({
    input: { name, onChange, value, ...restInput },
    meta,
    inputProps,
    children,
    className,
    classes,
    ...rest
}): JSX.Element => {
    const showError = !meta.active && meta.error && meta.touched
    const combinedInputProps = { ...restInput, ...inputProps }
    return (
        <Select
            value={value}
            {...rest}
            name={name}
            className={className}
            classes={classes}
            error={showError}
            inputProps={combinedInputProps}
            onChange={onChange}
        >
            {children}
        </Select>
    )
}

export default SelectWrapper
