import React, { FC } from 'react'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { get } from 'lodash'
import { themeAssets } from '../../Utils/themes'

interface HeaderImageProps {
    themeName: string
    hasBackground: boolean
    display: boolean
}

interface ThemeProps {
    hasBackground: boolean
}

const useStyles = makeStyles<ThemeProps>()((theme: Theme, {hasBackground}: ThemeProps) => ({
    image: {
        width: '100%',
        maxHeight: 400,
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 200,
        },
    },
    imagePlaceholder: {
        height: hasBackground ? 80 : 0,
    },
}))

export const HeaderImage: FC<HeaderImageProps> = ({
    themeName,
    display,
    hasBackground,
}) => {
    const { classes } = useStyles({ hasBackground })
    if (!display || hasBackground) return null
    const image = get(themeAssets, `${themeName}.headerImage`, '') as string
    return <img src={image} alt="" className={classes.image} />
}
