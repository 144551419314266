import i18n, { ResourceLanguage, TFunction } from 'i18next'
import { initReactI18next } from 'react-i18next'
import fiTranslations from './fi'
import svTranslations from './sv'

export const initi18n = (
    testMode?: boolean,
    testContent?: ResourceLanguage,
): Promise<TFunction> => {
    let fi: ResourceLanguage | undefined = fiTranslations
    let sv: ResourceLanguage | undefined = svTranslations

    if (testMode) {
        fi = testContent
        sv = testContent
    }

    if (!fi || !sv) throw Error('Invalid i18n translations')

    return i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources: {
                fi,
                sv,
            },
            lng: 'fi',
            debug: false,
            fallbackLng: 'fi',
            interpolation: {
                escapeValue: false,
            },
        })
}

export default initi18n()
