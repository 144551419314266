import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { FormApi } from 'final-form'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    InfoSubmitEvent,
    SearchSubmitEvent,
    RegisterInfoSubmitEvent,
    RegisterSubmitEvent,
} from '../../Containers/RootContainer'
import {InspectDates, InspectService, InspectStation, PrefillValues} from '../../interfaces'
import { DateTime } from 'luxon'
import { RootState } from '../../Redux'
import { useDispatch, useSelector } from 'react-redux'
import { RegisterStep } from './RegisterStep'
import { StationStep } from './StationStep'
import { InfoStep } from './InfoStep'
import { DateStep } from './DateStep'
import { ServiceStep } from './ServiceStep'
import { CallbackArray } from '../../Utils/types'
import ReservationActions from '../../Redux/reservationRedux'

const useStyles = makeStyles()((theme: Theme) => ({
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(3),
        padding: theme.spacing(0, 2, 2),
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 0, 1),
            flexWrap: 'nowrap',
        },
    },
}))

export interface DatePrices {
    time: string
    prices: InspectDates['prices']
}

interface ContentProps {
    inputId: string
    activeStep?: number
    activeStation?: InspectStation
    activeService?: InspectService
    step?: number
    display: boolean
    prefill: PrefillValues
    canGoToIndex: (index: number) => boolean | undefined
    getStationsByLocation(callback?: () => void): void
    validateRegisterNumber(item: string): boolean
    onSubmitSearch(e: SearchSubmitEvent, ...params: any): void
    onSubmitRegisterPlate(e: RegisterSubmitEvent, ...params: any): void
    onSubmitRegisterInfo(e: RegisterInfoSubmitEvent, ...params: any): void
    setStation(station: InspectStation): void
    setService(service: InspectService): void
    goNext(e?: any, callback?: () => void): void
    goToStep(step: number)
    handleGetServices(callback?: () => void): void
    getInspectDateTimes(dateStart: DateTime | null, dateEnd: DateTime | null, campaign: string | null, callback?: () => void): void
    setInspectDate(date: DateTime | null, campaignCode: string, callback: () => void | CallbackArray): () => void
    setReservationCampaignCode: (campaignCode: string, callback: () => void | CallbackArray) => void
    onSubmitInfo(event: any): void
    onGetBestPrices(
        service: InspectService,
        campaign: string | null,
        dateStart: string | undefined,
        dateEnd: string | undefined,
        callback?: () => void,
    ): void
    getAllStations(callback?: (e?: any) => void): void
    stationIdUrl?: string
    getSettings: (chainId: number, stationId?: number | null, callbacks?: CallbackArray) => void
}

export const Content: FC<ContentProps> = ({
    prefill,
    inputId,
    activeStep,
    activeStation,
    activeService,
    step,
    display,
    canGoToIndex,
    getStationsByLocation,
    validateRegisterNumber,
    onSubmitSearch,
    onSubmitRegisterPlate,
    onSubmitRegisterInfo,
    setStation,
    setService,
    handleGetServices,
    getInspectDateTimes,
    setInspectDate,
    setReservationCampaignCode,
    goNext,
    goToStep,
    onSubmitInfo,
    onGetBestPrices,
    stationIdUrl,
    getSettings,
}): JSX.Element | null => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const [date, setDate] = useState<DateTime|null>(null)
    const [timeIsSet, setTimeIsSet] = useState(false)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const dispatch = useDispatch()

    const {
        reservation: {
            service: inspectService,
            inspectDate,
            station,
            bestPrices,
            reservation,
            userInformation,
            fetching,
            stations,
            filteredStations,
            registerInfo,
            services,
            dateTimes,
        },
        ui,
        chainId,
    } = useSelector(({ reservation, ui }: RootState) => ({
        reservation,
        ui,
        chainId: ui.settings?.chain?.id,
    }))

    useEffect(() => {
        if (ui.settings?.station) setStation(ui.settings?.station)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui.settings])

    useEffect(() => {
        const selectedStationId = prefill.enabled ? (stationIdUrl ? Number.parseInt(stationIdUrl) : prefill.stationId) : null
        if (step === 0 && activeStep === 0 && stations?.length > 0 && selectedStationId) {
            const selectedStation = stations.find(s => s.id === selectedStationId)
            if (selectedStation) {
                setStation(selectedStation)
                if (ui.settings?.station?.id === selectedStationId) {
                    goToStep(1)
                } else {
                    getSettings(chainId, selectedStationId, [() => goToStep(1)])
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stations])

    useEffect(() => setDate(null), [activeService])

    const handleDateChange = useCallback(
        (dateStart: DateTime) => {
            setDate(dateStart)
            setTimeIsSet(false)
        },
        [setDate, setTimeIsSet],
    )

    const handleTimeChange = (date: DateTime) => {
        if (!fetching.reservation) {
            const callbacks = []
            callbacks.push(() => setDate(date))
            if (!timeIsSet) callbacks.push(() => setTimeIsSet(true))
            if (chainId) dispatch(ReservationActions.postReservation(date, chainId, undefined, callbacks))
        }
    }

    const getPrice = () => {
        if (reservation) {
            const { prices } = reservation
            if (userInformation?.payment === 'payNow') return prices.prepaid
            if (userInformation?.payment === 'invoice') return prices.online
            if (userInformation?.payment === 'payAtStation')return prices.online
            return null
        }
        return null
    }

    const validate = {
        register: (item: string | null) =>
            item ? !validateRegisterNumber(item) && t('errors.register') : '',
        firstName: (item: string | null) => {
            if (item) return item.length < 2 ? t('errors.firstName') : ''
            return t('errors.required')
        },
        lastName: (item: string | null) => {
            if (item) return item.length < 2 ? t('errors.lastName') : ''
            return t('errors.required')
        },
        email: (item: string | null) => {
            if (item)
                return !new RegExp(/^\S+@\S+\.\S+$/).test(item)
                    ? t('errors.email')
                    : ''
            return t('errors.required')
        },
        phone: (item: string | null) => {
            if (item)
                return !new RegExp(/[0-9]{2}-?[0-9]{1}-?[0-9]{1}/).test(item)
                    ? t('errors.phone')
                    : ''
            return t('errors.required')
        },
        postalCode: (item: string | null) => {
            if (item)
                return !new RegExp(/^[0-9]*$/).test(item) || item.length !== 5
                    ? t('errors.postalCode')
                    : ''
            return ''
        },
        postalAddress: (item: string | null) => {
            if (item) return item.length < 3 ? t('errors.postalAddress') : ''
            return ''
        },
        postOffice: (item: string | null) => {
            if (item) return item.length < 2 ? t('errors.postOffice') : ''
            return ''
        },
        manualRegisterForm: (values) => {
            const errors: any = {}
            if (!validateRegisterNumber(values.registrationNumber)) {
                errors.registrationNumber = t('errors.register')
            }
            return errors
        }
    }

    const handleSubmitNewRegistration = (e: any) => {
        const relevantData: any = {
            fuel: e.fuel,
            vehicleGroup: e.vehicleGroup,
        }
        onSubmitRegisterInfo(relevantData)
    }

    const handleGetStationSettings = (callback: () => void) => {
        if (!stationIdUrl) {
            getSettings(chainId, activeStation?.id, [callback])
        } else {
            callback()
        }
    }

    type FormType =
        | FormApi<RegisterSubmitEvent>
        | FormApi<SearchSubmitEvent>
        | FormApi<InfoSubmitEvent>
        | FormApi<RegisterInfoSubmitEvent>
        | null

    const form: FormType = null

    if (!display) return null

    switch (step) {
        case 0:
            return (
                <StationStep
                    stationIdUrl={stationIdUrl}
                    filteredStations={filteredStations}
                    contentStyle={classes.content}
                    canGoToIndex={canGoToIndex}
                    goNext={() => handleGetStationSettings(goNext)}
                    getStations={getStationsByLocation}
                    fetching={fetching}
                    form={form}
                    onSubmitSearch={onSubmitSearch}
                    stations={stations}
                    inputId={inputId}
                    setStation={setStation}
                    activeStation={activeStation}
                />
            )
        case 1:
            return (
                <RegisterStep
                    prefill={prefill}
                    form={form}
                    inputId={inputId}
                    fetching={fetching}
                    validate={validate}
                    onSubmitNewRegistration={handleSubmitNewRegistration}
                    registerInfo={registerInfo}
                    validateRegisterNumber={validateRegisterNumber}
                    onSubmitRegisterPlate={onSubmitRegisterPlate}
                    canGoToIndex={canGoToIndex}
                    goNext={goNext}
                    onSubmitRegisterInfo={onSubmitRegisterInfo}
                    handleGetServices={handleGetServices}
                />
            )
        case 2:
            return (
                <ServiceStep
                    canGoToIndex={canGoToIndex}
                    services={services}
                    fetching={fetching}
                    setService={setService}
                    activeService={activeService}
                    goNext={goNext}
                    onGetBestPrices={onGetBestPrices}
                    contentStyle={classes.content}
                    setReservationCampaignCode={setReservationCampaignCode}
                    handleGetServices={handleGetServices}
                />
            )
        case 3:
            return (
                <DateStep
                    onGetBestPrices={onGetBestPrices}
                    date={date}
                    bestPrices={bestPrices}
                    handleDateChange={handleDateChange}
                    getDateTimes={getInspectDateTimes}
                    dateTimes={dateTimes}
                    fetching={fetching}
                    handleTimeChange={handleTimeChange}
                    showDatePicker={showDatePicker}
                    timeIsSet={timeIsSet}
                    setInspectDate={setInspectDate}
                    setShowDatePicker={setShowDatePicker}
                    goNext={goNext}
                    canGoToIndex={canGoToIndex}
                    contentStyle={classes.content}
                    setReservationCampaignCode={setReservationCampaignCode}
                />
            )
        case 4:
            return (
                <InfoStep
                    price={getPrice()?.value}
                    station={station}
                    inspectService={inspectService}
                    prices={reservation?.prices}
                    onSubmitInfo={onSubmitInfo}
                    inputId={inputId}
                    validate={validate}
                    userInformation={userInformation}
                    fetching={fetching}
                    registerInfo={registerInfo}
                    inspectDate={inspectDate}
                    contentStyle={classes.content}
                    prefill={prefill}
                />
            )
        default:
            return null
    }
}

export default Content
