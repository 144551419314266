import { Link, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeType } from '../Utils/themes'
import { useAppThemeOptions } from '../Utils/hooks'

const useStyles = makeStyles<ThemeType>()((theme: Theme, options: ThemeType) => ({
    footer: {
        padding: theme.spacing(3),
        backgroundColor: options.footerBackgroundColor ?? theme.palette.primary.dark,
        minHeight: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
    footerText: {
        color: options.footerTextColor ?? '#fff',
    },
    row: {
        '&:first-of-type': {
            marginTop: 0,
        },
        marginTop: theme.spacing(1),
    }
}))

interface FooterProps {
    embedded: boolean
    privacyPolicyUrl: string
    registerDescriptionUrl: string
    termsOfUseUrl: string
}

export const Footer: FC<FooterProps> = ({ embedded, privacyPolicyUrl, termsOfUseUrl, registerDescriptionUrl }) => {
    const themeOptions = useAppThemeOptions()
    const { classes } = useStyles(themeOptions)
    const { t } = useTranslation()
    if (embedded) return null

    const createValidLink = (link: string) => {
        if (!link) return link
        if (link.substring(0, 7) === 'http://' || link.substring(0, 8) === 'https://') {
            return link
        }
        else return 'http://' + link
    }
    return (
        <div className={classes.footer}>
            <div className={classes.row}>
                <Link href={createValidLink(privacyPolicyUrl)} underline="none" target="_blank">
                    <Typography className={classes.footerText}>
                        {t('common.privacyPolicy')}
                    </Typography>
                </Link>
            </div>
            <div className={classes.row}>
                <Link href={createValidLink(registerDescriptionUrl)} underline="none" target="_blank">
                    <Typography className={classes.footerText}>
                        {t('common.registerDescription')}
                    </Typography>
                </Link>
            </div>
            <div className={classes.row}>
                <Link href={createValidLink(termsOfUseUrl)} underline="none" target="_blank">
                    <Typography className={classes.footerText}>
                        {t('common.termsOfUse')}
                    </Typography>
                </Link>
            </div>
        </div>
    )
}
