import { ResourceLanguage } from 'i18next'

const translation = {
    common: {
        atjVehicleType_car: 'Bil',
        atjVehicleType_atv: 'Terrängfordon',
        atjVehicleType_motorbike: 'Motorcykel',
        atjVehicleType_moped: 'Moped',
        atjVehicleType_trailer: 'Släpvagn',
        atjVehicleType_tractor: 'Traktor/arbetsmaskin',
        privacyPolicy: 'Sekretesspolicy',
        termsOfUse: 'Användningsvillkor',
        registerDescription: 'Integritetsskyddspolicy',
        save: 'Spara',
        yes: 'Ja',
        no: 'Nej',
        ok: 'Godkänn',
        cancel: 'Avbryt',
        campaignCode: 'Kampanjkod',
        customer: 'Kund',
        accept: 'Godkänn',
        price: 'Pris',
        paymentType: 'Betalningssätt',
        personReserving: 'Person som bokar',
        vehicle: 'Fordon',
        station: 'Station',
        service: 'Service',
        time: 'Tid',
        time_of_day: 'klockan',
        time_of_day_abbr: 'kl',
        fuel: 'Bränsle',
        fuel_diesel: 'Diesel',
        fuel_diesel_electricity: 'Diesel/El',
        fuel_electricity: 'El',
        fuel_gas: 'Gas',
        fuel_no_engine: 'Motorlöst fordon',
        fuel_petrol: 'Bensin',
        fuel_petrol_electricity: 'Bensin/El',
        fuel_unknown: '-',
        manual_vehicle_group_car: 'Personbil',
        manual_vehicle_group_van: 'Paketbil',
        manual_vehicle_group_camper: 'Husbil',
        manual_vehicle_group_truck_light: 'Lätt lastbil med hydrauliska bromsar',
        manual_vehicle_group_truck_max12t: 'Lastbil max. 12 000 kg',
        manual_vehicle_group_truck_over12t: 'Lastbil över 12 000 kg',
        manual_vehicle_group_bus_max5t: 'Buss max. 5000 kg',
        manual_vehicle_group_bus_over5t: 'Buss över 5000 kg',
        manual_vehicle_group_moped: 'Moped',
        manual_vehicle_group_mopedcar: 'Mopedbil',
        manual_vehicle_group_atv: 'Fyrhjuling',
        manual_vehicle_group_trailer_max3500: 'Släpvagn max. 3500 kg',
        manual_vehicle_group_trailer_max10t: 'Släpvagn max. 10 000 kg',
        manual_vehicle_group_trailer_over10t: 'Släpvagn över 10 000 kg',
        reservation: 'Bokning',
        reservationInfo: 'Bokningsinfo',
        registrationDate: 'Ibruktagningsdatum',
        vehicleGroup: 'Fordonsklass',
        vehicleType: 'Fordonstyp',
        postalAddress: 'Gatuadress',
        postalCode: 'Postnummer',
        postOffice: 'Ort',
        week: 'Vecka',
        choose: 'Välj',
        submit: 'Sänd',
        back: 'Föregående',
        next: 'Nästa',
        continue: 'Fortsätt',
        firstName: 'Förnamn',
        lastName: 'Efternamn',
        email: 'E-post',
        phone: 'Telefonnummer',
        registrationNumber: 'Registernummer',
    },
    errors: {
        postOffice: 'Felaktig adressort',
        postalAddress: 'Felaktig gatuadress',
        postalCode: 'Felaktig postnummer',
        register: 'Felaktig registernummer',
        firstName: 'Felaktigt förnamn',
        lastName: 'Felaktigt efternamn',
        email: 'Felaktig e-post',
        phone: 'Felaktig telefonnummer',
        timeNotAvailable: 'Tiden du valt kan inte bokas',
        paymentFailed: 'Betalningen misslyckades',
        cancelFailed: 'Avbokningen misslyckades. Kontakta stationen.',
        required: 'Obligatorisk information',
    },
    cancelledReservationContainer: {
        reservationCancelled: 'Er bokning är annullerad',
        cancelledParagraph: 'Er bokning är annullerad.',
        newReservation: 'Ni kan göra en ny bokning här',
    },
    reservationDoneContainer: {
        thankYou: 'Tack för Er bokning!',
        thankYouParagraph: 'Bokningsbekräftelsen skickas per sms med en link till servicekortet, där du kan granska och ändra din bokning.',
        serviceCardFromHere: 'Härifrån kommer du också till servicekortet.',
        paymentFailed: 'Betalningen misslyckades',
        paymentFailedParagraph: 'Din bokning lyckades iallafall. Bekräftelsen kommer per sms, samt linken till servicekortet, där du kan granska och ändra din bokning. Du kan även försöka att betala från servicekortet.',
    },
    serviceCardContainer: {
        serviceCard: 'Servicekort',
        reportHelperText: 'Besiktningsrapporten och kvittot laddas ner här.',
        feedbackSubmitOk: 'Tack för Er feedback!',
        createCalendarEvent: 'Gör en bokning här',
        feedbackText: 'Skriftlig feedback',
        feedbackTitle: 'Ge feedback',
        confirmParagraph: 'Vid avbokning godkänns avbokningsvillkoren',
        changeTimeTooltip: 'Ändring av tiden är möjligt före bokningen börjas',
        changeServiceTooltip: 'Ändring av tjänsterna är möjligt före bokningen börjas',
        checkInHelperText: 'Anmälningen öppnar {{time}} före bokningen börjar',
        cancelHelperText: 'Det är möjligt att avboka bokningen{{time}}  före den bokade tiden börjar',
        paymentHelperText: 'Förhandsbetalning är möjlig {{time}} före bokningen börjar',
        paymentFailed: 'Betalningen misslyckades',
        dateSubmitOk: 'Besiktningstiden ändrad: {{time}}',
        serviceSubmitOk: 'Ändrad tjänst: {{service}}',
        changeService: 'Ändra tjänst',
        changeTime: 'Byt tid',
        clock: 'Klockan',
        grade: 'Betyg',
        inspection: 'Besiktning',
        inspectReady: 'Besiktningen är gjord',
        reservationDate: 'Er bokade tid',
        reservationCancelled: 'Er bokning är annullerad',
        checkedIn: 'Du är anmäld. Bokningen börjar {{time}}',
        checkInFailed: 'Självbetjäningsanmälan misslyckades, anmäl Er vid stationen före besiktningen.',
        confirmCancelReservation: 'Vill Ni avboka Er reservation?',
        paymentFulfilled: 'Betalning genomförd ({{price}} inkl moms {{vat}})',
        paymentRefunded: 'Återbetalat',
        prepaidPrice: 'Betalning online',
        normalPrice: 'Betalning vid besiktnings stationen',
        servicePrice: 'Tjänstens pris',
        confirmCheckIn: 'Vill du anmäla?',
        confirmCheckInContent: 'Den bokade besiktningen godkänns här.',
        changeReservation: 'Ändra bokning',
        cancelReservation: 'Avboka',
        office: 'Station',
        checkIn: 'Anmäl Er',
        payNow: 'Betala nu',
        showDocuments: 'Visa dokument',
        documents: 'Dokument',
        showOnMap: 'Visa på karta',
        downloadReceipt: 'Ladda ner kvittans',
        downloadCertificate: 'Ladda ner besiktningsintyg',
    },
    rootContainer: {
        checkInfo: {
            heading: 'Obligatorisk information',
            acceptTermsOfUse: 'Jag godkänner tjänstens användningsvillkor',
            vehicleOwnership: 'Jag är fordonets ägare/innehavare',
        },
        inviteAddressInfo: 'Fyll även i adressuppgifter. Med adressuppgifterna försäkrar vi oss om att besiktningskallelsen far till rätt fordon och i rätt tid. Som hjälpmedel ändvänds Trafocoms trafikregister.',
        onlineCheckOutInfo: 'Då du i slutet trycker på knappen spara, överförs du till sidan betalningstjänst',
        contactStation: 'Kontakta stationen (tel. {{ phone }})',
        couldNotFindRegister: 'Registernummer hittades inte!',
        iAccept: 'Jag godkänner tjänstens ',
        inputCampaignCode: 'Ange kampanjkod',
        showAll: 'Visa alla',
        noAnswer: 'Inget svar',
        timeLeft: 'Bokningen är i kraft till',
        showCheapest: 'Visa förmångligaste',
        freeTimes: 'Lediga tider',
        noFreeTimes: 'Inga lediga tider',
        specialOffer: 'Specialerbjudande',
        selected: 'Vald',
        startingPrice: 'Från',
        startingPrice_abbr: 'från',
        invoicePrice: 'Collector-räkning',
        prepaidPrice: 'Förhandsbokningens pris',
        reservable: 'Kan bokas',
        youAreReserving: 'Ni bokar',
        choosePaymentMethod: 'Välj betalningssätt',
        payNow: 'Betala nu (Paytrail): {{price}}',
        payWithInvoice: 'Betala via räkning: {{price}}',
        payAtStation: 'Betala vid stationen: {{price}}',
        nextInspectionBefore: 'Sista besiktningedagen',
        nextAfterInspectionBefore: 'Sista eftergranskningedagen',
        ownerOrOccupant: 'Jag är fordonets',
        ownerOccupant: 'Ägare / Innehavare',
        other: 'Annan',
        allowOffers: 'Jag vill ha mera information om erbjudanden',
        allowSendingReminders: 'Får skicka påmminnelse till mig i fortsättningen om kommande besiktningar',
        viaTxt: 'Sms',
        viaEmail: 'E-post',
        noStationsFound: 'Det finns inga stationen på detta område',
        inputRegistrationNumber: 'Fyll i registernummer',
        inputRegistrationInfo: 'Fyll i fordonets uppgifter',
        chooseStation: 'Välj station',
        chooseService: 'Välj tjänst',
        chooseDatetime: 'Välj tid',
        fillInformation: 'Fyll i uppgifter',
        accept: 'Godkänn',
        reserve: 'Boka',
        vehicleInfo: 'Fordonets uppgifter',
        getRegisterInfo: 'Hämta uppgifter',
        appBarTitle: 'Besiktningsstation Ab',
        registerNumber: 'Registernummer',
        getStations: 'Sök besiktnings station från min position',
        registrationDate: 'Registrerad',
        manufacturer: 'Tillverkare',
        model: 'Modell',
        findDate: 'Sök en annan tid',
        cheapestPrices: 'Billigaste priserna',
        showMore: 'Visa mera',
        unregisteredVehicle: 'Oregistrerat fordon',
        cookieConsent: {
            title: 'Denna webbplats använder cookies',
            disclaimer: 'Vi använder spårnings- och prestanda cookies.Dessa cookies används för att samla in information för att analysera trafiken på vår webbplats och hur våra besökare använder den. Dessa cookies kan till exempel spåra hur länge du spenderar på webbplatsen eller vilka sidor du besöker vilket hjälper oss att förstå hur vi kan förbättra vår webbplats för dig. Informationen som samlas in genom dessa spårnings- och prestanda cookies identifierar ingen enskild besökare.',
            accept: 'Jag accepterar',
            refuse: 'Jag avböjer',
        },
    },
}

const _export: ResourceLanguage = { translation }

export default _export
