import { CircularProgress, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { DateTime } from 'luxon'
import React, { FC } from 'react'
import { PriceAndTime } from '../../interfaces'
import { a11yClick } from '../../Utils/a11yHelpers'
import { Currency } from '../Currency'
import { ThemeType} from '../../Utils/themes'
import { useAppThemeOptions } from '../../Utils/hooks'

interface AffordablePricesProps {
    dates?: PriceAndTime[]
    locale?: string
    selectedTime?: PriceAndTime
    selectTime(item: PriceAndTime): void
    fetching: boolean
    fixedPrice?: number | null
}

const useStyles = makeStyles<ThemeType>()((theme: Theme, options: ThemeType) => ({
    activeRow: {
        backgroundColor: `${theme.palette.secondary.light} !important`,
    },
    activePriceContainer: {
        backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    activeDateTime: {
        color: `${options.pricesTextActiveColor ?? '#fff'} !important`,
    },
    container: {
        width: '100%',
    },
    dateContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    dateTime: {
        color: options.pricesTextColor ?? theme.palette.primary.main,
    },
    date: {
        fontWeight: 'bold',
        color: options.pricesTextColor ?? theme.palette.primary.main,
        marginLeft: theme.spacing(4),
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: 0,
        outline: 0,
        padding: theme.spacing(1, 2),
        margin: theme.spacing(1, 0),
        boxShadow: theme.shadows[3],
        borderRadius: 8,
        backgroundColor: '#fff',
        '&:hover, &:active, &:focus': {
            boxShadow: '0px 0px 2px 2px rgba(90,90,90,1)',
        },
    },
    price: {
        color: '#fff',
        fontWeight: 'bold',
    },
    activePrice: {
        color: options.pricesTextActiveColor ?? '#fff',
    },
    priceContainer: {
        backgroundColor: theme.palette.primary.light,
        marginTop: theme.spacing(-1),
        marginRight: theme.spacing(-2),
        marginBottom: theme.spacing(-1),
        padding: theme.spacing(1, 2),
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}))

export const AffordablePrices: FC<AffordablePricesProps> = ({
    dates,
    locale,
    selectedTime,
    selectTime,
    fetching,
    fixedPrice,
}) => {
    const themeOptions = useAppThemeOptions()
    const { classes } = useStyles(themeOptions)
    const isActive = (item: PriceAndTime) => {
        return item === selectedTime
    }
    if (fetching) return <CircularProgress size={48} />
    if (!dates) return null
    return (
        <div className={classes.container}>
            {dates
                .slice(0, 3)
                .map((item) => {
                    const active = isActive(item)
                    return (
                        <div
                            tabIndex={0}
                            role="button"
                            {...a11yClick(() => selectTime(item))}
                            key={item.time}
                            className={
                                active
                                    ? `${classes.row} ${classes.activeRow}`
                                    : classes.row
                            }
                        >
                            <div className={classes.dateContainer}>
                                <div>
                                    <Typography
                                        className={
                                            active
                                                ? classes.activeDateTime
                                                : classes.dateTime
                                        }
                                    >
                                        {DateTime.fromISO(item.time, {setZone: true}).toFormat(
                                            'HH:mm',
                                        )}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography
                                        className={
                                            active
                                                ? `${classes.date} ${classes.activeDateTime}`
                                                : classes.date
                                        }
                                    >
                                        {DateTime.fromISO(item.time, {
                                            setZone: true,
                                            locale: locale,
                                        }).toFormat('cccc dd.MM')}
                                    </Typography>
                                </div>
                            </div>
                            <div
                                className={
                                    active
                                        ? `${classes.priceContainer} ${classes.activePriceContainer}`
                                        : classes.priceContainer
                                }
                            >
                                <Typography className={active ? `${classes.price} ${classes.activePrice}` : classes.price} noWrap>
                                    <Currency value={fixedPrice ?? item?.price?.value} />
                                </Typography>
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}
