import { createReducer, createActions } from 'reduxsauce'
import { Action } from 'redux'
import { ReducerTypes } from './utils'
import { CallbackArray } from '../Utils/types'
import {ThemeType} from '../Utils/themes'

type ActionTypes = {
    SET_UI_OPTIONS: string
    GET_SETTINGS: string
    GET_SETTINGS_BY_TOKEN: string
    GET_SETTINGS_SUCCESS: string
    GET_SETTINGS_FAILURE: string
    SAVE_CHAIN_INFO_TEXT: string
}

const actions = {
    setUiOptions: ['options'],
    getSettings: ['chainId', 'stationId', 'history', 'embedded', 'callbacks'],
    getSettingsByToken: ['token', 'history', 'embedded', 'callbacks'],
    getSettingsSuccess: ['settings', 'chainGoogleAnalyticsId', 'chainGtmId'],
    getSettingsFailure: null,
    saveChainInfoText: ['chainInfoText'],
}

type AnyCreators = {
    [key in keyof typeof actions]: (...params: any[]) => Action<any>
}

interface Creators extends AnyCreators {
    getSettings: (chainId: string, stationId: string | null, history: any, embedded: boolean, callbacks?: CallbackArray) => Action<any>
    getSettingsByToken: (token: string, history: any, embedded: boolean, callbacks?: CallbackArray) => Action<any>
}

const { Types, Creators } = createActions<ActionTypes, Creators>(actions)
export const UITypes = Types
export default Creators

export interface UIState {
    fetching: boolean
    chainInfoText: string | null
    chainGtmId: string | null
    chainGoogleAnalyticsId: string | null
    settings: {
        booking: {
            type: number
            atjEnabled: boolean
            atjLimit: boolean
            bookAfter: any
            dynamicPricing: any
            enabled: any
            options: {
                [key: string]: any
                allowCashPayment: boolean | null
                allowOnlinePayment: boolean | null
                askMarketing: string[] | null
                askOwnership: boolean | null
                askReminder: string[] | null
                showBestPrices: boolean
                showCalendarPrices: boolean
                theme: string
                embedBackgroundColor: string
                termsOfUseUrl: string | null
                vehicleMaxTotalMassM1: number | null
                vehicleMaxTotalMassMessage: string | null
                forceCampaignCodeCheck: boolean
            }
        }
        chain: {
            additionalInfo: any
            address: any
            coordinates: any
            id: number
            images: any
            logo: {
                data: string
                name: string
            }
            name: string
            organizationIdentifier: string
            stations: any
            www: string | null
        }
        fuels: { 
            id: string
            name: string
        }[]
        identifier: any
        station: any
        vehicleGroups: any
    } | null
    options: {
        isSet: boolean
        embedded: boolean
        theme: Partial<ThemeType>
        themeName: string
    }
}

export const INITIAL_STATE: UIState = {
    fetching: false,
    settings: null,
    chainInfoText: null,
    chainGtmId: null,
    chainGoogleAnalyticsId: null,
    options: {
        isSet: false,
        embedded: false,
        theme: null,
        themeName: null,
    }
}

const commonFetching = (state: UIState): UIState => ({
    ...state,
    fetching: true,
})
const commonFailure = (state: UIState): UIState => ({
    ...state,
    fetching: false,
})

const getSettings = commonFetching
const getSettingsSuccess = (
    state: UIState,
    { settings, chainGtmId, chainGoogleAnalyticsId }: any,
): UIState => ({
    ...state,
    settings,
    chainGtmId,
    chainGoogleAnalyticsId,
    fetching: false,
})
const getSettingsFailure = commonFailure

const setUiOptions = (state: UIState, { options }: UIState) => ({
    ...state,
    options: state.options.isSet ? state.options : { ...options, isSet: true }
})

const saveChainInfoText = (state: UIState, { chainInfoText }: UIState) => ({
    ...state,
    chainInfoText,
})

type UIReducerTypes = ReducerTypes<UIState, ActionTypes>

export const reducerTypes: UIReducerTypes = {
    [Types.GET_SETTINGS]: getSettings,
    [Types.GET_SETTINGS_BY_TOKEN]: getSettings,
    [Types.GET_SETTINGS_SUCCESS]: getSettingsSuccess,
    [Types.GET_SETTINGS_FAILURE]: getSettingsFailure,
    [Types.SET_UI_OPTIONS]: setUiOptions,
    [Types.SAVE_CHAIN_INFO_TEXT]: saveChainInfoText,
}

export const reducer = createReducer<UIState, Action<UIReducerTypes>>(
    INITIAL_STATE,
    reducerTypes,
)
