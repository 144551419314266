import { createReducer, createActions } from 'reduxsauce'
import { CommonReducer, Reducer } from '../Utils/reduxHelpers'
import {
    InspectStation,
    InspectService,
    InspectDates,
    UserInformation, PriceObject,
} from '../interfaces'
import { Action } from 'redux'
import { DateTime } from 'luxon'
import { ReducerTypes } from './utils'
import { CallbackArray } from '../Utils/types'

type ActionTypes = {
    GET_BEST_PRICES: string
    GET_BEST_PRICES_SUCCESS: string
    GET_BEST_PRICES_FAILURE: string
    GET_REGISTER_INFO: string
    GET_REGISTER_INFO_SUCCESS: string
    GET_REGISTER_INFO_FAILURE: string
    SET_REGISTER_INFO: string
    GET_FILTERED_STATIONS: string
    GET_FILTERED_STATIONS_SUCCESS: string
    GET_FILTERED_STATIONS_FAILURE: string
    GET_STATIONS: string
    GET_STATIONS_SUCCESS: string
    GET_STATIONS_FAILURE: string
    GET_GEOCODES: string
    GET_GEOCODES_SUCCESS: string
    GET_GEOCODES_FAILURE: string
    GET_SERVICES: string
    GET_SERVICES_SUCCESS: string
    GET_SERVICES_FAILURE: string
    GET_DATES: string
    GET_DATES_SUCCESS: string
    GET_DATES_FAILURE: string
    RESET_DATE_TIMES: string
    GET_DATE_TIMES: string
    GET_DATE_TIMES_SUCCESS: string
    GET_DATE_TIMES_FAILURE: string
    SET_CAMPAIGN_CODE: string
    SET_INSPECT_DATE: string
    SET_INFORMATION: string
    SET_INFORMATION_SUCCESS: string
    SET_INFORMATION_FAILURE: string
    DISCARD_RESERVATION: string
    DISCARD_RESERVATION_SUCCESS: string
    DISCARD_RESERVATION_FAILURE: string
    POST_RESERVATION: string
    POST_RESERVATION_SUCCESS: string
    POST_RESERVATION_FAILURE: string
    POST_FINAL_RESERVATION: string
    POST_FINAL_RESERVATION_FAILURE: string
    SET_STATION: string
    RESET: string
    SHOULD_CONTACT_STATION: string
}

const actions = {
    getBestPrices: ['chainId', 'service', 'campaign', 'dateStart', 'dateEnd', 'callbacks'],
    getBestPricesSuccess: ['bestPrices', 'service'],
    getBestPricesFailure: null,
    getRegisterInfo: ['registerNumber', 'vehicleType', 'vehicleClass', 'vehicleGroup', 'fuel', 'searchType', 'stationId', 'callbacks'],
    getRegisterInfoSuccess: ['registerInfo'],
    getRegisterInfoFailure: ['contactStation'],
    setRegisterInfo: ['registerInfo', 'callbacks'],
    getStations: ['chainId', 'stationId', 'callbacks'],
    getStationsSuccess: ['stations'],
    getStationsFailure: null,
    getFilteredStations: ['chainId', 'coordinates', 'search', 'callbacks'],
    getFilteredStationsSuccess: ['filteredStations'],
    getFilteredStationsFailure: null,
    getGeocodes: ['chainId', 'location', 'callbacks', 'forwardFilter'],
    getGeocodesSuccess: ['coordinates'],
    getGeocodesFailure: null,
    getServices: ['chainId', 'station', 'campaign', 'callbacks'],
    getServicesSuccess: ['services', 'station'],
    getServicesFailure: null,
    getDates: ['chainId', 'service', 'dateStart', 'dateEnd', 'callbacks'],
    getDatesSuccess: ['dates', 'service'],
    getDatesFailure: null,
    resetDateTimes: null,
    getDateTimes: ['chainId', 'campaign', 'dateStart', 'dateEnd', 'callbacks'],
    getDateTimesSuccess: ['dateTimes'],
    getDateTimesFailure: null,
    setCampaignCode: ['campaignCode', 'callbacks'],
    setInspectDate: ['inspectDate', 'campaignCode', 'chainId', 'callbacks'],
    setInformation: ['userInformation', 'callbacks'],
    setInformationSuccess: ['userInformation'],
    setInformationFailure: null,
    discardReservation: ['chainId', 'callbacks'],
    discardReservationSuccess: null,
    discardReservationFailure: null,
    postReservation: ['dateTime', 'chainId', 'campaignCode', 'callbacks'],
    postReservationSuccess: ['reservation'],
    postReservationFailure: null,
    postFinalReservation: ['callbacks'],
    postFinalReservationFailure: null,
    setStation: ['station'],
    reset: null,
    shouldContactStation: null,
}

type AnyCreators = {
    [key in keyof typeof actions]: (...params: any[]) => Action<any>
}

// Override AnyCreators with strong types
interface Creators extends AnyCreators {
    resetDateTimes: () => Action<any>
    getDateTimes: (
        chainId: number | string,
        campaign: string | null,
        dateStart: DateTime,
        dateEnd: DateTime | null,
        callbacks?: CallbackArray,
    ) => Action<any>
    getStations: (
        chainId: number | string,
        stationId?: number | string,
        callbacks?: CallbackArray,
    ) => Action<any>
    getGeocodes: (
        chainId: number | string,
        location: string,
        callbacks?: CallbackArray,
        forwardFilter?: boolean,
    ) => Action<any>
    getFilteredStations: (
        chainId: number | string,
        coordinates: any,
        search?: any,
        callbacks?: CallbackArray,
    ) => Action<any>
    setRegisterInfo: (
        registerInfo: any,
        callbacks?: CallbackArray,
    ) => Action<any>
}

const { Types, Creators } = createActions<ActionTypes, Creators>(actions)

export const ReservationTypes = Types
export default Creators

export interface ReservationState {
    shouldContactStation: boolean | null
    filteredStations: InspectStation[] | null
    reservation: any
    coordinates: any
    dateTimes: any
    campaignCode: string | null
    bestPrices: any
    inspectDate: DateTime | null
    fetching: {
        finalReservation: boolean
        filteredStations: boolean
        reservation: boolean
        bestPrices: boolean
        dates: boolean
        services: boolean
        stations: boolean
        registerInfo: boolean
        coordinates: boolean
        dateTimes: boolean
        userInformation: boolean
    }
    station: InspectStation | null
    stations: InspectStation[] | null
    service: InspectService | null
    services: InspectService[] | null
    date: DateTime | null
    dates: InspectDates | null
    userInformation: UserInformation | null
    registerInfo: {
        registration: string
        additionalInfo: any
        atjInfoBase64: string
        id: any
        color: string
        engineType: {
            id: number
            name: string
        }
        manufacturer: {
            id: number
            name: string
        }
        manufacturingNumber: string
        nextInspectionBefore: string
        registrationNumber: string
        vehicleClass: {
            id: number
            name: string
        }
        vehicleGroup: {
            id: number
            name: string
        }
        vehicleGroupOverride: any
        afterInspectionPrice?: PriceObject|null
        afterInspectionProductId?: number|null
    } | null
}

export const INITIAL_STATE: ReservationState = {
    shouldContactStation: null,
    reservation: null,
    filteredStations: null,
    inspectDate: null,
    date: null,
    dates: null,
    campaignCode: null,
    bestPrices: null,
    dateTimes: null,
    service: null,
    services: null,
    station: null,
    stations: null,
    fetching: {
        filteredStations: false,
        finalReservation: false,
        reservation: false,
        bestPrices: false,
        dates: false,
        services: false,
        stations: false,
        registerInfo: false,
        coordinates: false,
        dateTimes: false,
        userInformation: false,
    },
    registerInfo: null,
    coordinates: null,
    userInformation: null,
}

type CommonFetchingType = keyof ReservationState['fetching']

const reset = () => INITIAL_STATE

const commonFetching = (type: CommonFetchingType): CommonReducer<any> => (
    state: any,
) => ({
    ...state,
    fetching: {
        ...state.fetching,
        [type]: true,
    },
})
const commonFailure = (type: CommonFetchingType): CommonReducer<any> => (
    state: any,
) => ({
    ...state,
    fetching: {
        ...state.fetching,
        [type]: false,
    },
})

/* -------------- Best prices reducers -------------- */
const BEST_PRICES = 'bestPrices'

export const getBestPrices: CommonReducer<ReservationState> = commonFetching(
    BEST_PRICES,
)
export const getBestPricesSuccess: Reducer<ReservationState> = (
    state,
    { bestPrices, service },
) => ({
    ...state,
    inspectDate: null,
    bestPrices,
    service,
    fetching: { ...state.fetching, [BEST_PRICES]: false },
})
export const getBestPricesFailure: CommonReducer<ReservationState> = commonFailure(
    BEST_PRICES,
)

/* -------------- Register info reducers -------------- */
const REGISTER_INFO = 'registerInfo'

export const getRegisterInfo: CommonReducer<ReservationState> = commonFetching(
    REGISTER_INFO,
)
export const getRegisterInfoSuccess: Reducer<ReservationState> = (
    state,
    { registerInfo },
) => ({
    ...state,
    registerInfo,
    service: null,
    reservation: null,
    inspectDate: null,
    station: null,
    shouldContactStation: null,
    fetching: { ...state.fetching, [REGISTER_INFO]: false },
})
export const getRegisterInfoFailure: CommonReducer<ReservationState> = commonFailure(
    REGISTER_INFO,
)

/* -------------- Stations reducers -------------- */
const STATIONS = 'stations'

export const getStations: CommonReducer<ReservationState> = commonFetching(
    STATIONS,
)
export const getStationsSuccess: Reducer<ReservationState> = (
    state,
    { stations },
) => ({
    ...state,
    stations,
    fetching: { ...state.fetching, [STATIONS]: false },
})
export const getStationsFailure: CommonReducer<ReservationState> = commonFailure(
    STATIONS,
)

/* ------------- Filtered station reducers -------------- */
const FILTERED_STATIONS = 'filteredStations'

export const getFilteredStations: CommonReducer<ReservationState> = commonFetching(
    FILTERED_STATIONS,
)
export const getFilteredStationsSuccess: Reducer<ReservationState> = (
    state,
    { filteredStations },
) => ({
    ...state,
    filteredStations,
    fetching: { ...state.fetching, [FILTERED_STATIONS]: false },
})
export const getFilteredStationsFailure: CommonReducer<ReservationState> = commonFailure(
    FILTERED_STATIONS,
)

/* -------------- Geocode reducers -------------- */
const COORDINATES = 'coordinates'

export const getGeocodes: CommonReducer<ReservationState> = commonFetching(
    COORDINATES,
)
export const getGeocodesSuccess: Reducer<ReservationState> = (
    state,
    { coordinates },
) => ({
    ...state,
    coordinates,
    fetching: { ...state.fetching, [COORDINATES]: false },
})
export const getGeocodesFailure: CommonReducer<ReservationState> = commonFailure(
    COORDINATES,
)

/* -------------- Service reducers -------------- */
const SERVICES = 'services'

export const getServices: CommonReducer<ReservationState> = commonFetching(
    SERVICES,
)
export const getServicesSuccess: Reducer<ReservationState> = (
    state,
    { services, station },
) => ({
    ...state,
    services,
    service: null,
    reservation: null,
    inspectDate: null,
    station,
    fetching: { ...state.fetching, [SERVICES]: false },
})
export const getServicesFailure: CommonReducer<ReservationState> = commonFailure(
    SERVICES,
)

/* -------------- Dates reducers -------------- */
const DATES = 'dates'

export const getDates: CommonReducer<ReservationState> = commonFetching(DATES)
export const getDatesSuccess: Reducer<ReservationState> = (
    state,
    { dates, service },
) => ({
    ...state,
    reservation: null,
    inspectDate: null,
    dates,
    fetching: { ...state.fetching, [DATES]: false },
    service,
})
export const getDatesFailure: CommonReducer<ReservationState> = commonFailure(
    DATES,
)

/* -------------- Date times reducers -------------- */
const DATETIMES = 'dateTimes'

export const resetDateTimes: Reducer<ReservationState> = (state) => ({
    ...state,
    fetching: { ...state.fetching, [DATETIMES]: true },
})
export const getDateTimes: Reducer<ReservationState> = (state, { date }) => ({
    ...state,
    date,
    fetching: { ...state.fetching, [DATETIMES]: true },
})
export const getDateTimesSuccess: Reducer<ReservationState> = (
    state,
    { dateTimes },
) => ({
    ...state,
    dateTimes,
    fetching: { ...state.fetching, [DATETIMES]: false },
})
export const getDateTimesFailure: CommonReducer<ReservationState> = commonFailure(
    DATETIMES,
)

/* -------------- Campaign code reducers -------------- */
export const setCampaignCode: Reducer<ReservationState> = (
    state,
    { campaignCode },
) => ({
    ...state,
    campaignCode,
})

/* -------------- Inspect date reducers -------------- */
export const setInspectDate: Reducer<ReservationState> = (
    state,
    { inspectDate },
) => ({
    ...state,
    inspectDate,
})

/* -------------- Register info reducer ------------- */
export const setRegisterInfo: Reducer<ReservationState> = (
    state,
    { registerInfo },
) => {
    return {
        ...state,
        registerInfo,
    }
}

/* -------------- Information reducers -------------- */
const INFORMATION = 'userInformation'
export const setInformation: CommonReducer<ReservationState> = commonFetching(
    INFORMATION,
)
export const setInformationSuccess: Reducer<ReservationState> = (
    state,
    { userInformation },
) => ({
    ...state,
    userInformation: {...userInformation, submitSeed: Math.random()},
    fetching: { ...state.fetching, [INFORMATION]: false },
})
export const setInformationFailure: CommonReducer<ReservationState> = commonFailure(
    INFORMATION,
)

export const setStation: Reducer<ReservationState> = (state, { station }) => ({
    ...state,
    station,
    shouldContactStation: null,
})

/* -------------- Reservation reducers -------------- */
const RESERVATION = 'reservation'
export const postReservation: CommonReducer<ReservationState> = commonFetching(
    RESERVATION,
)
export const postReservationSuccess: Reducer<ReservationState> = (
    state,
    { reservation },
) => ({
    ...state,
    reservation,
    fetching: { ...state.fetching, [RESERVATION]: false },
})
export const postReservationFailure: CommonReducer<ReservationState> = commonFailure(
    RESERVATION,
)

export const discardReservation: CommonReducer<ReservationState> = commonFetching(
    RESERVATION,
)
export const discardReservationSuccess: Reducer<ReservationState> = (
    state,
) => ({
    ...state,
    reservation: null,
    fetching: { ...state.fetching, [RESERVATION]: false },
})
export const discardReservationFailure: CommonReducer<ReservationState> = commonFailure(
    RESERVATION,
)

const FINAL_RESERVATION = 'finalReservation'
export const postFinalReservation: CommonReducer<ReservationState> = commonFetching(
    FINAL_RESERVATION,
)
export const postFinalReservationFailure: CommonReducer<ReservationState> = commonFailure(
    FINAL_RESERVATION,
)

export const shouldContactStation: Reducer<ReservationState> = (state) => ({ ...state, shouldContactStation: true })

type ReservationReducerTypes = ReducerTypes<ReservationState, ActionTypes>

export const reducerTypes: ReservationReducerTypes = {
    [Types.GET_BEST_PRICES]: getBestPrices,
    [Types.GET_BEST_PRICES_SUCCESS]: getBestPricesSuccess,
    [Types.GET_BEST_PRICES_FAILURE]: getBestPricesFailure,
    [Types.GET_REGISTER_INFO]: getRegisterInfo,
    [Types.GET_REGISTER_INFO_SUCCESS]: getRegisterInfoSuccess,
    [Types.GET_REGISTER_INFO_FAILURE]: getRegisterInfoFailure,
    [Types.GET_FILTERED_STATIONS]: getFilteredStations,
    [Types.GET_FILTERED_STATIONS_SUCCESS]: getFilteredStationsSuccess,
    [Types.GET_FILTERED_STATIONS_FAILURE]: getFilteredStationsFailure,
    [Types.GET_STATIONS]: getStations,
    [Types.GET_STATIONS_SUCCESS]: getStationsSuccess,
    [Types.GET_STATIONS_FAILURE]: getStationsFailure,
    [Types.GET_GEOCODES]: getGeocodes,
    [Types.GET_GEOCODES_SUCCESS]: getGeocodesSuccess,
    [Types.GET_GEOCODES_FAILURE]: getGeocodesFailure,
    [Types.GET_SERVICES]: getServices,
    [Types.GET_SERVICES_SUCCESS]: getServicesSuccess,
    [Types.GET_SERVICES_FAILURE]: getServicesFailure,
    [Types.GET_DATES]: getDates,
    [Types.GET_DATES_SUCCESS]: getDatesSuccess,
    [Types.GET_DATES_FAILURE]: getDatesFailure,
    [Types.RESET_DATE_TIMES]: resetDateTimes,
    [Types.GET_DATE_TIMES]: getDateTimes,
    [Types.GET_DATE_TIMES_SUCCESS]: getDateTimesSuccess,
    [Types.GET_DATE_TIMES_FAILURE]: getDateTimesFailure,
    [Types.SET_CAMPAIGN_CODE]: setCampaignCode,
    [Types.SET_INSPECT_DATE]: setInspectDate,
    [Types.SET_INFORMATION]: setInformation,
    [Types.SET_INFORMATION_SUCCESS]: setInformationSuccess,
    [Types.SET_INFORMATION_FAILURE]: setInformationFailure,
    [Types.DISCARD_RESERVATION]: discardReservation,
    [Types.DISCARD_RESERVATION_SUCCESS]: discardReservationSuccess,
    [Types.DISCARD_RESERVATION_FAILURE]: discardReservationFailure,
    [Types.POST_RESERVATION]: postReservation,
    [Types.POST_RESERVATION_SUCCESS]: postReservationSuccess,
    [Types.POST_RESERVATION_FAILURE]: postReservationFailure,
    [Types.SET_REGISTER_INFO]: setRegisterInfo,
    [Types.POST_FINAL_RESERVATION]: postFinalReservation,
    [Types.POST_FINAL_RESERVATION_FAILURE]: postFinalReservationFailure,
    [Types.SET_STATION]: setStation,
    [Types.RESET]: reset,
    [Types.SHOULD_CONTACT_STATION]: shouldContactStation
}

export const reducer = createReducer<
    ReservationState,
    Action<ReservationReducerTypes>
>(INITIAL_STATE, reducerTypes)
