/* eslint-disable @typescript-eslint/no-var-requires */

import { Action, combineReducers, Store } from 'redux'
import { reducer as errorReducer } from './errorRedux'
import { reducer as reservationReducer } from './reservationRedux'
import { reducer as uiReducer } from './uiRedux'
import { reducer as checkInReducer } from './checkInRedux'
import { reducer as paymentReducer } from './paymentRedux'
import configureStore from './store'
import rootSaga from '../Sagas/'

/* ------------- Assemble The Reducers ------------- */
export const reducers = combineReducers({
    error: errorReducer,
    reservation: reservationReducer,
    ui: uiReducer,
    checkIn: checkInReducer,
    payment: paymentReducer,
})

export type RootState = ReturnType<typeof reducers>

export default (): { store: Store<any, Action<any>> } => {
    // eslint-disable-next-line prefer-const
    let { store, sagasManager, sagaMiddleware } = configureStore(
        reducers,
        rootSaga,
    )
    if (module.hot) {
        module.hot.accept(() => {
            const nextRootReducer = require('./').reducers
            store.replaceReducer(nextRootReducer)

            const newYieldedSagas = require('../Sagas').default
            sagasManager.cancel()
            ;(sagasManager as any).done.then(() => {
                sagasManager = sagaMiddleware.run(newYieldedSagas)
            })
        })
    }

    return {
        store,
    }
}
