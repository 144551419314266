import { all, call, put } from 'redux-saga/effects'
import { ApiType } from '../Services/api'
import UIActions from '../Redux/uiRedux'
import ErrorActions from '../Redux/errorRedux'
import ReservationActions from '../Redux/reservationRedux'
import { getGeneralApiProblem } from '../Utils/reduxHelpers'
import { handleCallbacks } from '../Utils/helpers'
import {ThemeType, themes} from '../Utils/themes'

interface ActionType {
    type: string
}

interface AnyAction extends ActionType {
    [key: string]: any
}

export function* getSettings(api: ApiType, action: AnyAction): unknown {
    const { chainId, stationId, history, embedded, callbacks } = action
    const [chainResponse, stationResponse] = action.token ? yield all([
        yield call(api.getChainSettingsByToken, action.token, embedded),
        yield call(api.getStationSettingsByToken, action.token, embedded),
    ]) : yield all([
        yield call(api.getSettings, chainId, null, embedded),
        stationId ? yield call(api.getSettings, chainId, stationId, embedded) : undefined,
    ])
    const response = stationResponse ?? chainResponse
    if (chainResponse.ok && response.ok) {
        const { data } = response
        const { station } = data
        // Check that theme exists and fall back to default if not
        if (data?.booking?.options?.theme && !themes[data.booking.options.theme]) {
            data.booking.options.theme = null
        }
        /* Save chain reservation info to keep both infos in state */
        if (!station && data.booking?.options.reservationInfo) {
            yield put(UIActions.saveChainInfoText(data.booking?.options.reservationInfo))
        }
        const theme: Partial<ThemeType> = {}
        if (data.booking?.options?.embedBackgroundColor) {
            theme.backgroundColor = data.booking.options.embedBackgroundColor
        }
        yield put(UIActions.setUiOptions({
            embedded: data.booking?.type === 2,
            themeName: data.booking.options.theme,
            theme,
        }))
        yield put(UIActions.getSettingsSuccess(data, chainResponse?.data?.booking?.options?.googleAnalyticsId, stationResponse?.data?.booking?.options?.googleTagManagerId ?? chainResponse?.data?.booking?.options?.googleTagManagerId))
        if (station) yield put(ReservationActions.setStation(station))
        if (callbacks) yield handleCallbacks(callbacks)
    } else if (response.problem) {
        if (response.status === 403 || response.status === 404) {
            history.push('/error')
        }
        const problem = getGeneralApiProblem(response.problem, response.status)
        yield put(ErrorActions.setError(problem))
        yield put(UIActions.getSettingsFailure())
    }
}
